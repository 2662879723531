import {NavOption} from '@models/nav-option';
import {LEAGUE_IDS} from '@models/constants/leagues';

export class BildPostFeedNavOption extends NavOption {
  name= 'Post Feed';
  shouldHide= false;
  activeRootPath = ['', 'bild'];
  link = ['', 'bild'];
}

export class BildHighlightsNavOption extends NavOption {
  name= 'Highlights';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'highlights'];
  link = ['', 'bild', 'highlights'];
}

export class BildScoreboardNavOption extends NavOption {
  name= 'Scoreboard';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'scoreboard'];
  link = ['', 'bild', 'scoreboard'];
}

export class BildFocusGroupsNavOption extends NavOption {
  name= 'Focus Groups';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'focus-groups'];
  link = ['', 'bild', 'focus-groups'];
}

export class BildMetricsNavOption extends NavOption {
  name= 'Metrics';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'metrics'];
  link = ['', 'bild', 'entities', 'leagues', LEAGUE_IDS.NBA, 'metrics', 'team-index'];
}

export class BildForecastNavOption extends NavOption {
  name= 'Metrics';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'metrics'];
  link = ['', 'bild', 'entities', 'leagues', LEAGUE_IDS.NBA, 'metrics', 'forecast', 'overview'];
}

export class BildEventsNavOption extends NavOption {
  name= 'Events';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'events'];
  link = ['', 'bild', 'events', 'calendar'];
}

export class BildTalentPipelineNavOption extends NavOption {
  name= 'Talent Pipeline';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'talent-pipeline'];
  link = ['', 'bild', 'talent-pipeline'];
}

export class BildPlayerExplorerNavOption extends NavOption {
  name = 'Player Explorer';
  shouldHide = false;
  activeRootPath = ['', 'bild', 'player-explorer'];
  link = ['', 'bild', 'player-explorer'];
}

export class BildVideoNavOption extends NavOption {
  name= 'Video';
  shouldHide= false;
  activeRootPath = ['', 'bild', 'video'];
  link = ['', 'bild', 'video'];
}

export class BildScheduleNavOption extends NavOption {
  name = 'Schedule';
  activeRootPath = ['', 'bild', 'schedule'];
  link = ['', 'bild', 'schedule'];
}
