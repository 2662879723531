import * as _ from 'lodash';
import {immerable} from "immer"
import * as moment from 'moment-timezone';

import {Transform, Type, plainToClass} from 'class-transformer';
import {forwardRef} from '@angular/core';

import {Distribution} from '@models/constants/distributions';
import {Entity} from './entities';
import {FocusGroupGrouping} from './focus-group-groupings';
import {forwardRefMoment} from '@helpers/class-transformer.helper';
import {IdName} from './id-names/_id-names';
import {RankedPerson, LOCSDraftModel, LOCSSuperscout} from '@models/ranked-people';
import { PostsSummary } from './posts-summary';
import { PlayerContract } from './player-contract';


export const FocusGroupErrorKeyMap = {
  'viewers': 'Viewers',
  'visibility': 'Visibility',
  'entities': 'Entities',
  'name': 'Name',
  'owner': 'Owner',
  'bodyParameters': 'Focus Group'
};

// TODO These can be removed once person LOCS has user groups for coaches and evaluators
export const FOCUS_GROUP_COACHING_ID = 98;
export const FOCUS_GROUP_EVALUATION_ID = 99;

export class FocusGroupEntitySummary {
  id: number;
  name: string;
  rank: number;
}

export enum FocusGroupViewType {
  MAIN,
  MAIN_POSITIONAL,
  MY_EVALUATION_RANKS,
  MY_EVALUATION_RANKS_POSITIONAL,
  MY_EVALUATION_LOCS,
  MY_EVALUATION_LOCS_PROBABILITIES,
  MY_EVALUATION_TSA,
  MY_EVALUATION_DETAILED_TSA,
  MY_EVALUATION_RESERVE_PRICES
}

export enum FocusGroupMessageTypes {
  WEBSOCKET_CHANNEL_NAME = 'websocket_channel_name',
  FOCUS_GROUP_STATE = 'focus_group_state',
  ACTIVE_USERS_CHANGE = 'active_users_change',
  FOCUS_GROUP_ACTION = 'focus_group_action',
  ENTITY_CONTEXT = 'entity_context',
  METRICS_FILTERS_STATE = 'metrics_filters_state',
  MY_EVALUATION_STATE = 'my_evaluation_state',
  MY_EVALUATION_ACTION = 'updated_eval',
}

export enum FocusGroupActionTypes {
  ADD_ENTITY = 1,
  ADD_GROUPING = 2,
  DELETE_ENTITY = 3,
  DELETE_GROUPING = 4,
  RANK_GROUPING = 5,
  RENAME_GROUPING = 6,
  REORDER_ENTITY = 7,
  REORDER_GROUPING = 8,
  UNRANK_GROUPING = 9,
}

export class FocusGroupAction {
  actionTypeID: FocusGroupActionTypes = null;
  entityActionTargetID: number = null;
  groupingActionTargetID: number = null;
  groupingID: number = null;
  rank: number = null;
  isRanked: boolean = null;
  newRank: number = null;
  newName: string = null;
  name: string = null;

  constructor(type: FocusGroupActionTypes, actionData: any){
    this.actionTypeID = type;
    switch (type) {
      case FocusGroupActionTypes.ADD_ENTITY: {
        this.entityActionTargetID = actionData.entityID ? Number(actionData.entityID) : actionData.entityID;
        this.groupingActionTargetID = actionData.groupingID ? Number(actionData.groupingID) : actionData.groupingID;
        this.newRank = actionData.rank ? Number(actionData.rank) : actionData.rank;
        break;
      }
      case FocusGroupActionTypes.ADD_GROUPING: {
        this.newRank = actionData.rank ? Number(actionData.rank) : actionData.rank;
        this.newName = actionData.name;
        this.isRanked = actionData.isRanked;
        break;
      }
      case FocusGroupActionTypes.DELETE_ENTITY: {
        this.entityActionTargetID = actionData.entityID ? Number(actionData.entityID) : actionData.entityID;
        this.groupingActionTargetID = actionData.groupingID ? Number(actionData.groupingID) : actionData.groupingID;
        this.rank = actionData.rank ? Number(actionData.rank) : actionData.rank;
        break;
      }
      case FocusGroupActionTypes.DELETE_GROUPING: {
        this.groupingActionTargetID = actionData.groupingID ? Number(actionData.groupingID) : actionData.groupingID;
        this.rank = actionData.rank ? Number(actionData.rank) : actionData.rank;
        break;
      }
      case FocusGroupActionTypes.RANK_GROUPING: {
        this.groupingActionTargetID = actionData.groupingID ? Number(actionData.groupingID) : actionData.groupingID;
        break;
      }
      case FocusGroupActionTypes.RENAME_GROUPING: {
        this.groupingActionTargetID = actionData.groupingID ? Number(actionData.groupingID) : actionData.groupingID;
        this.newName = actionData.newName;
        this.name = actionData.oldName;
        break;
      }
      case FocusGroupActionTypes.REORDER_ENTITY: {
        this.entityActionTargetID = actionData.entityID ? Number(actionData.entityID) : actionData.entityID;
        this.groupingActionTargetID = actionData.newGroupingID ? Number(actionData.newGroupingID) : actionData.newGroupingID;
        this.groupingID = actionData.oldGroupingID ? Number(actionData.oldGroupingID) : actionData.oldGroupingID,
        this.newRank = actionData.newRank ? Number(actionData.newRank) : actionData.newRank;
        this.rank = actionData.oldRank ? Number(actionData.oldRank) : actionData.oldRank;
        break;
      }
      case FocusGroupActionTypes.REORDER_GROUPING: {
        this.groupingActionTargetID = actionData.groupingID ? Number(actionData.groupingID) : actionData.groupingID;
        this.newRank = actionData.newRank ? Number(actionData.newRank) : actionData.newRank;
        this.rank = actionData.oldRank ? Number(actionData.oldRank) : actionData.oldRank;
        break;
      }
      case FocusGroupActionTypes.UNRANK_GROUPING: {
        this.groupingActionTargetID = actionData.groupingID ? Number(actionData.groupingID) : actionData.groupingID;
        break;
      }
    }
  }
}

export enum MyEvaluationActionTypes {
  UPDATE_LOCS = 1,
  UPDATE_TSA = 2,
  UPDATE_RESERVE_PRICE = 3,
  UPDATE_RANKS = 4,
  UPDATE_DETAILED_TSA = 5,
  UPDATE_LOCS_PROBABILITIES = 6,
}

export class MyEvaluationAction {
  evalTypeID: number = null;
  batchDate: string = null;
  evalID: number = null;
  evalValues: any = null;

  constructor(type: MyEvaluationActionTypes, actionData: any) {
    this.evalTypeID = type;
    this.batchDate = actionData.batchDate;
    this.evalID = actionData.evalID;
    this.evalValues = actionData.evalValues;
  }
}

export class FocusGroupEvalFilters {
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  date: moment.Moment;
}

export class FocusGroup {
  [immerable] = true;

  id: number;
  name: string;
  description: string;
  batchEvaluations: any[] = [];
  @Type(forwardRef(() => Distribution) as any)
  distribution: Distribution = plainToClass(Distribution, {});
  @Type(forwardRef(() => RankedPerson) as any)
  entities: RankedPerson[] = [];
  @Type(forwardRef(() => Entity) as any)
  owner: Entity = null;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  createDatetime: moment.Moment;
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  editDatetime: moment.Moment;
  isHistorical: boolean = false;
  isTalentPipeline: boolean = false;
  priority: number = 3;
  primaryFunction: IdName;
  primaryFunctionID: number = 5;
  isPro: boolean = true;
  @Type(forwardRef(() => FocusGroupGrouping) as any)
  groupings: FocusGroupGrouping[];
  @Type(forwardRef(() => moment) as any)
  @Transform(forwardRefMoment(moment.ISO_8601))
  versionDates: moment.Moment[];
  evalPostSummaries: PostsSummary[];
  intelPostSummaries: PostsSummary[];
  playerContracts: PlayerContract[];
  metricsFilters: any;
  @Type(forwardRef(() => FocusGroupEvalFilters) as any)
  evalFilters: FocusGroupEvalFilters;
  riserFallerBatchDates: string[];

  get level() {
    return this.isPro ? 'Pro' : 'Amateur';
  }

  get primaryFunctionName(): string {
    return this.primaryFunction.name;
  }

  get isStale() {
    return this.editDatetime.isBefore(moment().subtract(9, 'months'));
  }

  get distinctUsers(): any[] {
    const distributionUsers = this.distribution?.users;
    const userGroupUsers = _.flatMap(this.distribution?.userGroups, 'members');
    const mergedArray = _.concat(userGroupUsers, distributionUsers);
    return _.uniqBy(mergedArray, 'id');
  }

  groupingForEntity(id: number) {
    let entityGrouping: FocusGroupGrouping = null;

    this.groupings.forEach((grouping, i) => {
      if (entityGrouping) {
        return;
      }

      if (grouping.entities.filter(entity => entity.id === id).length > 0) {
        entityGrouping = grouping;
      }
    });

    return entityGrouping;
  }

  get entityCount(): number {
    return _(this.groupings).sumBy(grouping => {
      return grouping.entities.length;
    });
  }

  get entityList() {
    const entityList = [];
    this.groupings.forEach((grouping, i) => {
      grouping.entities.forEach((entity, i) => {
        entityList.push(entity);
      });
    });
    return entityList;
  }

  get filteredEntityList() {
    const entityList = [];
    this.groupings.forEach((grouping, i) => {
      grouping.filteredEntities.forEach((entity, i) => {
        entityList.push(entity);
      });
    });
    return entityList;
  }

  get entityTooltip(): string {
    let entityTooltip = this.playerNames.slice(3, 13).join('\n');
    if (this.entityCount > 13) {
      entityTooltip += '\n + ' + (this.entityCount - 13) + ' Others';
    }
    return entityTooltip;
  }

  get simpleEntityTooltip(): string {
    let entityTooltip = this.simplePlayerNames.slice(3, 13).join('\n');
    if (this.entities?.length > 13) {
      entityTooltip += '\n + ' + (this.entities?.length - 13) + ' Others';
    }
    return entityTooltip;
  }

  get playerNames() {
    return this.entityList.map(el => el.name);
  }

  get simplePlayerNames() {
    return this.entities.map(el => el.name);
  }

  get editDatetimeFormatted(): string {
    const now = moment();
    let formattedDatetime: string = '';

    switch (true) {
      case now.isSame(this.editDatetime, 'day'):
        formattedDatetime = this.editDatetime.format('h:mm A');
        break;
      case now.isSame(this.editDatetime, 'year'):
        formattedDatetime = this.editDatetime.format('MMM D');
        break;
      default:
        formattedDatetime = this.editDatetime.format('MMM D, YYYY');
    }

    return formattedDatetime;
  }

  get distributionLinkLabel(): string {
    switch (this.distribution.id) {
      case 1200:
        return 'Standard Access';
      case 1201:
        return 'Only Me';
      case 1202:
        return 'Limited Access';
      case 1203:
        return 'Limited Access+';
      case 1204:
        return 'Standard Access+';
      default:
        return 'View Distribution';
    }
  }
}

export enum FocusGroupColumnCategories {
  GENERAL = 'General',
  EVALUATION = 'Evaluation',
  AFFILIATIONS = 'Affiliations',
  PHYSICAL = 'Physical',
  FINANCIAL = 'Financial',
  METRICS = 'Metrics',
  PSYCH = 'Psych',
}

export enum FocusGroupColumnCategoryGroups {
  GENERAL = 'General',
  RANKS = 'Ranks',
  LOCS = 'LOCS',
  LOCS_PROBABILITIES = 'LOCS Probabilities',
  TSA = 'TSA',
  RESERVE_PRICES = 'Reserve Prices',
  MODELS_AND_DIAGNOSTICS = 'Models & Diagnostics',
  MEASURES = 'Measures',
  BOX_SCORE = 'Box Score',
  SHOOTING = 'Shooting',
  MODELED_SHOOTING = 'Modeled Shooting',
  PLAYING_TIME = 'Playing Time',
  ANTHRO = 'Anthro',
  PERFORMANCE = 'Performance',
  MEDICAL = 'Medical',
}

export const FocusGroupEntityContextKeysClasses = [
  {key: 'evaluations.ranks', classRef: null},
  {key: 'evaluations.ranksPast', classRef: null},
  {key: 'evaluations.locs', classRef: null},
  {key: 'evaluations.locsPast', classRef: null},
  {key: 'evaluations.locsProbabilities', classRef: null},
  {key: 'evaluations.tsa', classRef: null},
  {key: 'evaluations.reservePrices', classRef: null},
  {key: 'evaluations.draftModel', classRef: LOCSDraftModel},
  {key: 'evaluations.superscout', classRef: LOCSSuperscout},
  {key: 'metrics.measures', classRef: null},
  {key: 'metrics.modeledShooting', classRef: null},
  {key: 'metrics.shots', classRef: null},
  {key: 'metrics.gameMinutes', classRef: null},
  {key: 'metrics.playingTime', classRef: null},
  {key: 'metrics.rotations', classRef: null},
  {key: 'metrics.trends', classRef: null},
  {key: 'physical', classRef: null}
];

export enum FocusGroupColumnOptionIDs {
  TEAM_ID = 1,
  POSITION_ID = 2,
  AGE_ID = 3,
  YOS_ID = 4,
  CAREER_STAGE_ID = 5,
  AGENT_ID = 6,
  AGENCY_ID = 7,
  MY_RANKS_ID = 8,
  EVALUATOR_RANKS_ID = 9,
  AVERAGE_RANKS_ID = 10,
  RANK_RANGES_ID = 94,
  MY_LOCS_ID = 11,
  EVALUATOR_LOCS_ID = 12,
  AVERAGE_LOCS_ID = 13,
  EVALUATOR_LOCS_PROBABILITIES_ID = 100,
  AVERAGE_LOCS_PROBABILITIES_ID = 101,
  MY_TSA_ID = 14,
  EVALUATOR_TSA_ID = 15,
  AVERAGE_TSA_ID = 16,
  MY_RESERVE_PRICES_ID = 17,
  EVALUATOR_RESERVE_PRICES_ID = 18,
  AVERAGE_RESERVE_PRICES_ID = 19,
  FLOW_CHART_RESERVE_PRICES_ID = 110,
  SUPERSCOUT_ID = 20,
  POST_DIAGNOSTICS_ID = 21,
  DRAFT_MODEL_ID = 22,
  MRS_ID = 23,
  XSALARY_ID = 24,
  CONTRACT_ID = 25,
  MSCORE_ID = 26,
  MPAG_ID = 27,
  SA_ID = 28,
  POSS_ID = 29,
  PPA_ID = 30,
  EFG_ID = 31,
  XEFG_ID = 32,
  QEFG_ID = 33,
  USG_ID = 34,
  FTA_100_ID = 35,
  AST_RATE_ID = 36,
  TO_RATE_ID = 37,
  ORB_RATE_ID = 38,
  DRB_RATE_ID = 39,
  STL_RATE_ID = 40,
  BLK_RATE_ID = 41,
  PF_RATE_ID = 42,
  MPG_ID = 43,
  PPG_ID = 44,
  FG_PERCENTAGE_ID = 45,
  TWO_POINTERS_MADE_ID = 46,
  TWO_POINTERS_ATTEMPTED_ID = 47,
  TWO_POINTERS_PERCENTAGE_ID = 48,
  THREE_POINTERS_MADE_ID = 49,
  THREE_POINTERS_ATTEMPTED_ID = 50,
  THREE_POINTERS_PERCENTAGE_ID = 51,
  FREE_THROWS_MADE_ID = 52,
  FREE_THROWS_ATTEMPTED_ID = 53,
  FREE_THROWS_PERCENTAGE_ID = 54,
  DRB_ID = 55,
  ORB_ID = 56,
  TRB_ID = 57,
  AST_ID = 58,
  STL_ID = 59,
  TO_ID = 60,
  BLK_ID = 61,
  PF_ID = 62,
  SHOOTING_ALL_ZONES_ID = 63,
  SHOOTING_DP_ID = 64,
  SHOOTING_THREES_ID = 65,
  SHOOTING_NR2_ID = 66,
  SHOOTING_C3_ID = 67,
  SHOOTING_NC3_ID = 68,
  SHOOTING_BLK_ID = 69,
  SHOOTING_HP_ID = 70,
  SHOOTING_NP2_ID = 71,
  SHOOTING_GAME_STATE_ID = 72,
  MODELED_SHOOTING_ALL_ZONES_ID = 73,
  MODELED_SHOOTING_DP_ID = 74,
  MODELED_SHOOTING_THREES_ID = 75,
  MODELED_SHOOTING_MR2_ID = 76,
  MODELED_SHOOTING_L2_ID = 77,
  MODELED_SHOOTING_FT_ID = 78,
  PLAYING_TIME_POSITION_ID = 79,
  PLAYING_TIME_DEFENSIVE_MATCHUP_ID = 80,
  PLAYING_TIME_ROTATIONS_ID = 81,
  PLAYING_TIME_GAME_MINUTES_ID = 82,
  PSYCH_RISK_FACTORS_ID = 83,
  PSYCH_RECOMMENDATION_ID = 84,
  PSYCH_LAST_EVAL_ID = 85,
  PSYCH_PASS_ID = 86,
  PSYCH_NEO_ID = 87,
  PSYCH_INTEL_ID = 93,
  TREND_ID = 88,
  PAST_GAME_ID = 89,
  LAST_5_ID = 90,
  LAST_10_ID = 91,
  YTD_ID = 92,
  GROUPING_ID = 95,
  METRICS_MEASURES_SPACING_ID = 96,
  METRICS_BOX_SCORE_SPACING_ID = 97,
  METRICS_SHOOTING_SPACING_ID = 98,
  EVENT_METRICS_ID = 99,
  HEIGHT_ID = 102,
  WEIGHT_ID = 103,
  WINGSPAN_ID = 104,
  REACH_ID = 105,
  VERT_JUMP_ID = 106,
  APPR_JUMP_TOUCH_ID = 107,
  LANE_AGILITY_ID = 108,
  THREE_QUARTERS_COURT_SPRINT_ID = 109,
  BIRTHDATE_ID = 110,
}

export enum FocusGroupColumnOptionLabels {
  TEAM_LABEL = 'Team',
  POSITION_LABEL = 'Position',
  AGE_LABEL = 'Age',
  YOS_LABEL = 'Years of Service',
  CAREER_STAGE_LABEL = 'Career Stage',
  AGENT_LABEL = 'Agent',
  AGENCY_LABEL = 'Agency',
  MY_RANKS_LABEL = 'My Ranks',
  EVALUATOR_RANKS_LABEL = 'Evaluator Ranks',
  AVERAGE_RANKS_LABEL = 'Average Ranks',
  RANK_RANGES_LABEL = 'Rank Ranges',
  MY_LOCS_LABEL = 'My LOCS',
  EVALUATOR_LOCS_LABEL = 'Evaluator LOCS',
  AVERAGE_LOCS_LABEL = 'Average LOCS',
  EVALUATOR_LOCS_PROBABILITIES_LABEL = 'Evaluator LOCS Probabilities',
  AVERAGE_LOCS_PROBABILITIES_LABEL = 'Average LOCS Probabilities',
  MY_TSA_LABEL = 'My TSA',
  EVALUATOR_TSA_LABEL = 'Evaluator TSA',
  AVERAGE_TSA_LABEL = 'Average TSA',
  MY_RESERVE_PRICES_LABEL = 'My Reserve Prices',
  EVALUATOR_RESERVE_PRICES_LABEL = 'Evaluator Reserve Prices',
  AVERAGE_RESERVE_PRICES_LABEL = 'Average Reserve Prices',
  FLOW_CHART_RESERVE_PRICES_LABEL = 'Flow Chart Reserve Prices',
  SUPERSCOUT_LABEL = 'Superscout',
  POST_DIAGNOSTICS_LABEL = 'Post Diagnostics',
  EVENT_METICS_LABEL = 'Event Metrics',
  DRAFT_MODEL_LABEL = 'Draft Model',
  MRS_LABEL = 'MRS',
  XSALARY_LABEL = 'Expected Salary',
  CONTRACT_LABEL = 'Contract',
  MSCORE_LABEL = 'M-Score',
  MPAG_LABEL = 'MPAG',
  SA_LABEL = 'SA',
  POSS_LABEL = 'POSS',
  PPA_LABEL = 'PPA',
  EFG_LABEL = 'EFG',
  XEFG_LABEL = 'XEFG',
  QEFG_LABEL = 'QEFG',
  USG_LABEL = 'USG',
  FTA_100_LABEL = 'FTA/100',
  AST_RATE_LABEL = 'AST%',
  TO_RATE_LABEL = 'TO%',
  ORB_RATE_LABEL = 'ORB%',
  DRB_RATE_LABEL = 'DRB%',
  STL_RATE_LABEL = 'STL%',
  BLK_RATE_LABEL = 'BLK%',
  PF_RATE_LABEL = 'PF%',
  MPG_LABEL = 'MPG',
  PPG_LABEL = 'PPG',
  FG_PERCENTAGE_LABEL = 'FG%',
  TWO_POINTERS_MADE_LABEL = '2PM',
  TWO_POINTERS_ATTEMPTED_LABEL = '2PA',
  TWO_POINTERS_PERCENTAGE_LABEL = '2P%',
  THREE_POINTERS_MADE_LABEL = '3PM',
  THREE_POINTERS_ATTEMPTED_LABEL = '3PA',
  THREE_POINTERS_PERCENTAGE_LABEL = '3P%',
  FREE_THROWS_MADE_LABEL = 'FTM',
  FREE_THROWS_ATTEMPTED_LABEL = 'FTA',
  FREE_THROWS_PERCENTAGE_LABEL = 'FT%',
  DRB_LABEL = 'DRB',
  ORB_LABEL = 'ORB',
  TRB_LABEL = 'TRB',
  AST_LABEL = 'AST',
  STL_LABEL = 'STL',
  TO_LABEL = 'TO',
  BLK_LABEL = 'BLK',
  PF_LABEL = 'PF',
  SHOOTING_ALL_ZONES_LABEL = 'All Zones',
  SHOOTING_DP_LABEL = 'DP',
  SHOOTING_THREES_LABEL = '3S',
  SHOOTING_NR2_LABEL = 'NR2S',
  SHOOTING_C3_LABEL = 'C3',
  SHOOTING_NC3_LABEL = 'NC3',
  SHOOTING_BLK_LABEL = 'BLK',
  SHOOTING_HP_LABEL = 'HP',
  SHOOTING_NP2_LABEL = 'NP2',
  SHOOTING_GAME_STATE_LABEL = 'Game State',
  MODELED_SHOOTING_ALL_ZONES_LABEL = 'All Zones',
  MODELED_SHOOTING_DP_LABEL = 'DP',
  MODELED_SHOOTING_THREES_LABEL = '3S',
  MODELED_SHOOTING_MR2_LABEL = 'MR2S',
  MODELED_SHOOTING_L2_LABEL = 'L2S',
  MODELED_SHOOTING_FT_LABEL = 'FT',
  PLAYING_TIME_POSITION_LABEL = 'Position',
  PLAYING_TIME_DEFENSIVE_MATCHUP_LABEL = 'Defensive Matchup',
  PLAYING_TIME_ROTATIONS_LABEL = 'Rotations',
  PLAYING_TIME_GAME_MINUTES_LABEL = 'Game Minutes',
  PSYCH_RISK_FACTORS_LABEL = 'Risk Factors',
  PSYCH_RECOMMENDATION_LABEL = 'Recommendation',
  PSYCH_LAST_EVAL_LABEL = 'Last Eval',
  PSYCH_PASS_LABEL = 'PASS',
  PSYCH_NEO_LABEL = 'NEO',
  PSYCH_INTEL_LABEL = 'Intelligence',
  TREND_LABEL = 'Trend',
  PAST_GAME_LABEL = 'PG',
  LAST_5_LABEL = 'L5',
  LAST_10_LABEL = 'L10',
  YTD_LABEL = 'YTD',
  GROUPING_LABEL = 'Grouping',
  METRICS_MEASURES_SPACING_LABEL = '',
  METRICS_BOX_SCORE_SPACING_LABEL = '',
  METRICS_SHOOTING_SPACING_LABEL = '',
  HEIGHT_LABEL = 'Height',
  WEIGHT_LABEL = 'Weight',
  WINGSPAN_LABEL = 'Wingspan',
  REACH_LABEL = 'Reach',
  VERT_JUMP_LABEL = 'Vert Jump',
  APPR_JUMP_TOUCH_LABEL = 'Appr Jump Tch',
  LANE_AGILITY_LABEL = 'Lane Agility',
  THREE_QUARTERS_COURT_SPRINT_LABEL = '3/4 Court Sprint',
  BIRTHDATE_LABEL = 'Birthdate',
}

export const FocusGroupColumnOptionsData = [
  {
    category: FocusGroupColumnCategories.GENERAL,
    searchEnabled: false,
    groups: [
      {
        group: FocusGroupColumnCategoryGroups.GENERAL,
        options: [
          {id: FocusGroupColumnOptionIDs.POSITION_ID, label: FocusGroupColumnOptionLabels.POSITION_LABEL, checked: true, perms: null},
          {id: FocusGroupColumnOptionIDs.AGE_ID, label: FocusGroupColumnOptionLabels.AGE_LABEL, checked: true, perms: null},
          {id: FocusGroupColumnOptionIDs.YOS_ID, label: FocusGroupColumnOptionLabels.YOS_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.BIRTHDATE_ID, label: FocusGroupColumnOptionLabels.BIRTHDATE_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.CAREER_STAGE_ID, label: FocusGroupColumnOptionLabels.CAREER_STAGE_LABEL, checked: true, perms: null},
          {id: FocusGroupColumnOptionIDs.GROUPING_ID, label: FocusGroupColumnOptionLabels.GROUPING_LABEL, checked: true, perms: null},
        ]
      }
    ]
  },
  {
    category: FocusGroupColumnCategories.EVALUATION,
    searchEnabled: false,
    groups: [
      {
        group: FocusGroupColumnCategoryGroups.RANKS,
        options: [
          // {id: FocusGroupColumnOptionIDs.MY_RANKS_ID, label: FocusGroupColumnOptionLabels.MY_RANKS_LABEL, checked: false, perms: 'viewAllLOCs'},
          {id: FocusGroupColumnOptionIDs.AVERAGE_RANKS_ID, label: FocusGroupColumnOptionLabels.AVERAGE_RANKS_LABEL, checked: false, perms: 'viewAllLOCs'},
          {id: FocusGroupColumnOptionIDs.EVALUATOR_RANKS_ID, label: FocusGroupColumnOptionLabels.EVALUATOR_RANKS_LABEL, checked: false, perms: 'viewAllLOCs'},
          {id: FocusGroupColumnOptionIDs.RANK_RANGES_ID, label: FocusGroupColumnOptionLabels.RANK_RANGES_LABEL, checked: false, perms: 'viewAllLOCs'},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.LOCS,
        options: [
          // {id: FocusGroupColumnOptionIDs.MY_LOCS_ID, label: FocusGroupColumnOptionLabels.MY_LOCS_LABEL, checked: true, perms: 'viewAllLOCs'},
          {id: FocusGroupColumnOptionIDs.AVERAGE_LOCS_ID, label: FocusGroupColumnOptionLabels.AVERAGE_LOCS_LABEL, checked: true, perms: 'viewAllLOCs'},
          {id: FocusGroupColumnOptionIDs.EVALUATOR_LOCS_ID, label: FocusGroupColumnOptionLabels.EVALUATOR_LOCS_LABEL, checked: false, perms: 'viewAllLOCs'},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.LOCS_PROBABILITIES,
        options: [
          {id: FocusGroupColumnOptionIDs.AVERAGE_LOCS_PROBABILITIES_ID, label: FocusGroupColumnOptionLabels.AVERAGE_LOCS_PROBABILITIES_LABEL, checked: false, perms: 'viewAllLOCSProbabilities'},
          {id: FocusGroupColumnOptionIDs.EVALUATOR_LOCS_PROBABILITIES_ID, label: FocusGroupColumnOptionLabels.EVALUATOR_LOCS_PROBABILITIES_LABEL, checked: false, perms: 'viewAllLOCSProbabilities'},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.TSA,
        options: [
          // {id: FocusGroupColumnOptionIDs.MY_TSA_ID, label: FocusGroupColumnOptionLabels.MY_TSA_LABEL, checked: true, perms: 'viewAllLOCs'},
          {id: FocusGroupColumnOptionIDs.AVERAGE_TSA_ID, label: FocusGroupColumnOptionLabels.AVERAGE_TSA_LABEL, checked: false, perms: 'viewAllLOCs'},
          {id: FocusGroupColumnOptionIDs.EVALUATOR_TSA_ID, label: FocusGroupColumnOptionLabels.EVALUATOR_TSA_LABEL, checked: false, perms: 'viewAllLOCs'},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.RESERVE_PRICES,
        options: [
          // {id: FocusGroupColumnOptionIDs.MY_RESERVE_PRICES_ID, label: FocusGroupColumnOptionLabels.MY_RESERVE_PRICES_LABEL, checked: false, perms: 'Admin'},
          {id: FocusGroupColumnOptionIDs.AVERAGE_RESERVE_PRICES_ID, label: FocusGroupColumnOptionLabels.AVERAGE_RESERVE_PRICES_LABEL, checked: false, perms: 'viewAllReservePrices'},
          {id: FocusGroupColumnOptionIDs.EVALUATOR_RESERVE_PRICES_ID, label: FocusGroupColumnOptionLabels.EVALUATOR_RESERVE_PRICES_LABEL, checked: false, perms: 'viewAllReservePrices'},
          {id: FocusGroupColumnOptionIDs.FLOW_CHART_RESERVE_PRICES_ID, label: FocusGroupColumnOptionLabels.FLOW_CHART_RESERVE_PRICES_LABEL, checked: false, perms: 'viewAllFlowChartReservePrices'},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.MODELS_AND_DIAGNOSTICS,
        options: [
          {id: FocusGroupColumnOptionIDs.SUPERSCOUT_ID, label: FocusGroupColumnOptionLabels.SUPERSCOUT_LABEL, checked: false, perms: 'viewAllSuperscout'},
          {id: FocusGroupColumnOptionIDs.DRAFT_MODEL_ID, label: FocusGroupColumnOptionLabels.DRAFT_MODEL_LABEL, checked: false, perms: 'viewAllSuperscout'},
          {id: FocusGroupColumnOptionIDs.POST_DIAGNOSTICS_ID, label: FocusGroupColumnOptionLabels.POST_DIAGNOSTICS_LABEL, checked: false, perms: 'viewAllPosts'},
          // TODO: Rework "Event" diagnostics
          // {id: FocusGroupColumnOptionIDs.EVENT_METRICS_ID, label: FocusGroupColumnOptionLabels.EVENT_METICS_LABEL, checked: false, perms: 'viewAllEvents'}
        ]
      }
    ]
  },
  {
    category: FocusGroupColumnCategories.AFFILIATIONS,
    searchEnabled: false,
    groups: [
      {
        group: FocusGroupColumnCategoryGroups.GENERAL,
        options: [
          {id: FocusGroupColumnOptionIDs.TEAM_ID, label: FocusGroupColumnOptionLabels.TEAM_LABEL, checked: true, perms: null},
          {id: FocusGroupColumnOptionIDs.AGENT_ID, label: FocusGroupColumnOptionLabels.AGENT_LABEL, checked: false, perms: 'viewAllAffiliations'},
          {id: FocusGroupColumnOptionIDs.AGENCY_ID, label: FocusGroupColumnOptionLabels.AGENCY_LABEL, checked: false, perms: 'viewAllAffiliations'}
        ]
      }
    ]
  },
  {
    category: FocusGroupColumnCategories.PHYSICAL,
    searchEnabled: false,
    groups: [
      {
        group: FocusGroupColumnCategoryGroups.ANTHRO,
        options: [
          {id: FocusGroupColumnOptionIDs.HEIGHT_ID, label: FocusGroupColumnOptionLabels.HEIGHT_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.WEIGHT_ID, label: FocusGroupColumnOptionLabels.WEIGHT_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.WINGSPAN_ID, label: FocusGroupColumnOptionLabels.WINGSPAN_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.REACH_ID, label: FocusGroupColumnOptionLabels.REACH_LABEL, checked: false, perms: null},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.PERFORMANCE,
        options: [
          {id: FocusGroupColumnOptionIDs.VERT_JUMP_ID, label: FocusGroupColumnOptionLabels.VERT_JUMP_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.APPR_JUMP_TOUCH_ID, label: FocusGroupColumnOptionLabels.APPR_JUMP_TOUCH_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.LANE_AGILITY_ID, label: FocusGroupColumnOptionLabels.LANE_AGILITY_LABEL, checked: false, perms: null},
          {id: FocusGroupColumnOptionIDs.THREE_QUARTERS_COURT_SPRINT_ID, label: FocusGroupColumnOptionLabels.THREE_QUARTERS_COURT_SPRINT_LABEL, checked: false, perms: null},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.MEDICAL,
        options: [
          {id: FocusGroupColumnOptionIDs.MRS_ID, label: FocusGroupColumnOptionLabels.MRS_LABEL, checked: false, perms: null},
        ]
      }
    ]
  },
  {
    category: FocusGroupColumnCategories.FINANCIAL,
    searchEnabled: false,
    groups: [
      {
        group: FocusGroupColumnCategoryGroups.GENERAL,
        options: [
          {id: FocusGroupColumnOptionIDs.XSALARY_ID, label: FocusGroupColumnOptionLabels.XSALARY_LABEL, checked: false, perms: 'viewAllPCMS'},
          {id: FocusGroupColumnOptionIDs.CONTRACT_ID, label: FocusGroupColumnOptionLabels.CONTRACT_LABEL, checked: false, perms: 'viewAllPCMS'}
        ]
      }
    ]
  },
  {
    category: FocusGroupColumnCategories.METRICS,
    searchEnabled: true,
    groups: [
      {
        group: FocusGroupColumnCategoryGroups.MEASURES,
        options: [
          {id: FocusGroupColumnOptionIDs.MSCORE_ID, label: FocusGroupColumnOptionLabels.MSCORE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.MPAG_ID, label: FocusGroupColumnOptionLabels.MPAG_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SA_ID, label: FocusGroupColumnOptionLabels.SA_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.POSS_ID, label: FocusGroupColumnOptionLabels.POSS_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.PPA_ID, label: FocusGroupColumnOptionLabels.PPA_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.EFG_ID, label: FocusGroupColumnOptionLabels.EFG_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.XEFG_ID, label: FocusGroupColumnOptionLabels.XEFG_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.QEFG_ID, label: FocusGroupColumnOptionLabels.QEFG_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.USG_ID, label: FocusGroupColumnOptionLabels.USG_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.FTA_100_ID, label: FocusGroupColumnOptionLabels.FTA_100_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.AST_RATE_ID, label: FocusGroupColumnOptionLabels.AST_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.TO_RATE_ID, label: FocusGroupColumnOptionLabels.TO_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.ORB_RATE_ID, label: FocusGroupColumnOptionLabels.ORB_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.DRB_RATE_ID, label: FocusGroupColumnOptionLabels.DRB_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.STL_RATE_ID, label: FocusGroupColumnOptionLabels.STL_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.BLK_RATE_ID, label: FocusGroupColumnOptionLabels.BLK_RATE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.PF_RATE_ID, label: FocusGroupColumnOptionLabels.PF_RATE_LABEL, checked: false, perms: 'viewAllMetrics'}
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.BOX_SCORE,
        options: [
          {id: FocusGroupColumnOptionIDs.MPG_ID, label: FocusGroupColumnOptionLabels.MPG_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.PPG_ID, label: FocusGroupColumnOptionLabels.PPG_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.FG_PERCENTAGE_ID, label: FocusGroupColumnOptionLabels.FG_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.TWO_POINTERS_MADE_ID, label: FocusGroupColumnOptionLabels.TWO_POINTERS_MADE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.TWO_POINTERS_ATTEMPTED_ID, label: FocusGroupColumnOptionLabels.TWO_POINTERS_ATTEMPTED_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.TWO_POINTERS_PERCENTAGE_ID, label: FocusGroupColumnOptionLabels.TWO_POINTERS_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.THREE_POINTERS_MADE_ID, label: FocusGroupColumnOptionLabels.THREE_POINTERS_MADE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.THREE_POINTERS_ATTEMPTED_ID, label: FocusGroupColumnOptionLabels.THREE_POINTERS_ATTEMPTED_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.THREE_POINTERS_PERCENTAGE_ID, label: FocusGroupColumnOptionLabels.THREE_POINTERS_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.FREE_THROWS_MADE_ID, label: FocusGroupColumnOptionLabels.FREE_THROWS_MADE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.FREE_THROWS_ATTEMPTED_ID, label: FocusGroupColumnOptionLabels.FREE_THROWS_ATTEMPTED_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.FREE_THROWS_PERCENTAGE_ID, label: FocusGroupColumnOptionLabels.FREE_THROWS_PERCENTAGE_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.DRB_ID, label: FocusGroupColumnOptionLabels.DRB_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.ORB_ID, label: FocusGroupColumnOptionLabels.ORB_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.TRB_ID, label: FocusGroupColumnOptionLabels.TRB_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.AST_ID, label: FocusGroupColumnOptionLabels.AST_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.STL_ID, label: FocusGroupColumnOptionLabels.STL_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.TO_ID, label: FocusGroupColumnOptionLabels.TO_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.BLK_ID, label: FocusGroupColumnOptionLabels.BLK_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.PF_ID, label: FocusGroupColumnOptionLabels.PF_LABEL, checked: false, perms: 'viewAllMetrics'}
        ],
      },
      {
        group: FocusGroupColumnCategoryGroups.SHOOTING,
        options: [
          {id: FocusGroupColumnOptionIDs.SHOOTING_ALL_ZONES_ID, label: FocusGroupColumnOptionLabels.SHOOTING_ALL_ZONES_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_DP_ID, label: FocusGroupColumnOptionLabels.SHOOTING_DP_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_THREES_ID, label: FocusGroupColumnOptionLabels.SHOOTING_THREES_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_NR2_ID, label: FocusGroupColumnOptionLabels.SHOOTING_NR2_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_C3_ID, label: FocusGroupColumnOptionLabels.SHOOTING_C3_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_NC3_ID, label: FocusGroupColumnOptionLabels.SHOOTING_NC3_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_BLK_ID, label: FocusGroupColumnOptionLabels.SHOOTING_BLK_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_HP_ID, label: FocusGroupColumnOptionLabels.SHOOTING_HP_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.SHOOTING_NP2_ID, label: FocusGroupColumnOptionLabels.SHOOTING_NP2_LABEL, checked: false, perms: 'viewAllMetrics'},
          // {id: FocusGroupColumnOptionIDs.SHOOTING_GAME_STATE_ID, label: FocusGroupColumnOptionLabels.SHOOTING_GAME_STATE_LABEL, checked: false, perms: 'viewAllMetrics'}
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.MODELED_SHOOTING,
        options: [
          {id: FocusGroupColumnOptionIDs.MODELED_SHOOTING_ALL_ZONES_ID, label: FocusGroupColumnOptionLabels.MODELED_SHOOTING_ALL_ZONES_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.MODELED_SHOOTING_DP_ID, label: FocusGroupColumnOptionLabels.MODELED_SHOOTING_DP_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.MODELED_SHOOTING_THREES_ID, label: FocusGroupColumnOptionLabels.MODELED_SHOOTING_THREES_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.MODELED_SHOOTING_MR2_ID, label: FocusGroupColumnOptionLabels.MODELED_SHOOTING_MR2_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.MODELED_SHOOTING_L2_ID, label: FocusGroupColumnOptionLabels.MODELED_SHOOTING_L2_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.MODELED_SHOOTING_FT_ID, label: FocusGroupColumnOptionLabels.MODELED_SHOOTING_FT_LABEL, checked: false, perms: 'viewAllMetrics'},
        ]
      },
      {
        group: FocusGroupColumnCategoryGroups.PLAYING_TIME,
        options: [
          {id: FocusGroupColumnOptionIDs.PLAYING_TIME_POSITION_ID, label: FocusGroupColumnOptionLabels.PLAYING_TIME_POSITION_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.PLAYING_TIME_DEFENSIVE_MATCHUP_ID, label: FocusGroupColumnOptionLabels.PLAYING_TIME_DEFENSIVE_MATCHUP_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.PLAYING_TIME_ROTATIONS_ID, label: FocusGroupColumnOptionLabels.PLAYING_TIME_ROTATIONS_LABEL, checked: false, perms: 'viewAllMetrics'},
          {id: FocusGroupColumnOptionIDs.PLAYING_TIME_GAME_MINUTES_ID, label: FocusGroupColumnOptionLabels.PLAYING_TIME_GAME_MINUTES_LABEL, checked: false, perms: 'viewAllMetrics'},
        ]
      }
    ]
  },
  {
    category: FocusGroupColumnCategories.PSYCH,
    searchEnabled: false,
    groups: [
      {
        group: FocusGroupColumnCategoryGroups.GENERAL,
        options: [
          {id: FocusGroupColumnOptionIDs.PSYCH_RISK_FACTORS_ID, label: FocusGroupColumnOptionLabels.PSYCH_RISK_FACTORS_LABEL, checked: false, perms: 'viewAllPASS'},
          {id: FocusGroupColumnOptionIDs.PSYCH_RECOMMENDATION_ID, label: FocusGroupColumnOptionLabels.PSYCH_RECOMMENDATION_LABEL, checked: false, perms: 'viewAllPASS'},
          {id: FocusGroupColumnOptionIDs.PSYCH_LAST_EVAL_ID, label: FocusGroupColumnOptionLabels.PSYCH_LAST_EVAL_LABEL, checked: false, perms: 'viewAllPASS'},
          {id: FocusGroupColumnOptionIDs.PSYCH_PASS_ID, label: FocusGroupColumnOptionLabels.PSYCH_PASS_LABEL, checked: false, perms: 'viewAllPASS'},
          {id: FocusGroupColumnOptionIDs.PSYCH_NEO_ID, label: FocusGroupColumnOptionLabels.PSYCH_NEO_LABEL, checked: false, perms: 'viewAllNEO'},
          {id: FocusGroupColumnOptionIDs.PSYCH_INTEL_ID, label: FocusGroupColumnOptionLabels.PSYCH_INTEL_LABEL, checked: false, perms: 'viewAllIntelligence'}
        ]
      }
    ]
  }
];

export const ServerMetricsCategoriesFieldIDs = [
  {
    category: 'METRICS_MEASURES',
    fieldIDs: [
      FocusGroupColumnOptionIDs.MSCORE_ID,
      FocusGroupColumnOptionIDs.MPAG_ID,
      FocusGroupColumnOptionIDs.SA_ID,
      FocusGroupColumnOptionIDs.POSS_ID,
      FocusGroupColumnOptionIDs.PPA_ID,
      FocusGroupColumnOptionIDs.EFG_ID,
      FocusGroupColumnOptionIDs.XEFG_ID,
      FocusGroupColumnOptionIDs.QEFG_ID,
      FocusGroupColumnOptionIDs.USG_ID,
      FocusGroupColumnOptionIDs.FTA_100_ID,
      FocusGroupColumnOptionIDs.AST_RATE_ID,
      FocusGroupColumnOptionIDs.TO_RATE_ID,
      FocusGroupColumnOptionIDs.ORB_RATE_ID,
      FocusGroupColumnOptionIDs.DRB_RATE_ID,
      FocusGroupColumnOptionIDs.STL_RATE_ID,
      FocusGroupColumnOptionIDs.BLK_RATE_ID,
      FocusGroupColumnOptionIDs.PF_RATE_ID,
      FocusGroupColumnOptionIDs.MPG_ID,
      FocusGroupColumnOptionIDs.PPG_ID,
      FocusGroupColumnOptionIDs.FG_PERCENTAGE_ID,
      FocusGroupColumnOptionIDs.TWO_POINTERS_MADE_ID,
      FocusGroupColumnOptionIDs.TWO_POINTERS_ATTEMPTED_ID,
      FocusGroupColumnOptionIDs.TWO_POINTERS_PERCENTAGE_ID,
      FocusGroupColumnOptionIDs.THREE_POINTERS_MADE_ID,
      FocusGroupColumnOptionIDs.THREE_POINTERS_ATTEMPTED_ID,
      FocusGroupColumnOptionIDs.THREE_POINTERS_PERCENTAGE_ID,
      FocusGroupColumnOptionIDs.FREE_THROWS_MADE_ID,
      FocusGroupColumnOptionIDs.FREE_THROWS_ATTEMPTED_ID,
      FocusGroupColumnOptionIDs.FREE_THROWS_PERCENTAGE_ID,
      FocusGroupColumnOptionIDs.DRB_ID,
      FocusGroupColumnOptionIDs.ORB_ID,
      FocusGroupColumnOptionIDs.TRB_ID,
      FocusGroupColumnOptionIDs.AST_ID,
      FocusGroupColumnOptionIDs.STL_ID,
      FocusGroupColumnOptionIDs.TO_ID,
      FocusGroupColumnOptionIDs.BLK_ID,
      FocusGroupColumnOptionIDs.PF_ID
    ]
  },
  {
    category: 'METRICS_SHOTS',
    fieldIDs: [
      FocusGroupColumnOptionIDs.SHOOTING_ALL_ZONES_ID,
      FocusGroupColumnOptionIDs.SHOOTING_DP_ID,
      FocusGroupColumnOptionIDs.SHOOTING_THREES_ID,
      FocusGroupColumnOptionIDs.SHOOTING_NR2_ID,
      FocusGroupColumnOptionIDs.SHOOTING_C3_ID,
      FocusGroupColumnOptionIDs.SHOOTING_NC3_ID,
      FocusGroupColumnOptionIDs.SHOOTING_BLK_ID,
      FocusGroupColumnOptionIDs.SHOOTING_HP_ID,
      FocusGroupColumnOptionIDs.SHOOTING_NP2_ID,
      FocusGroupColumnOptionIDs.SHOOTING_GAME_STATE_ID
    ]
  },
  {
    category: 'METRICS_MODELED_SHOOTING',
    fieldIDs: [
      FocusGroupColumnOptionIDs.MODELED_SHOOTING_ALL_ZONES_ID,
      FocusGroupColumnOptionIDs.MODELED_SHOOTING_DP_ID,
      FocusGroupColumnOptionIDs.MODELED_SHOOTING_THREES_ID,
      FocusGroupColumnOptionIDs.MODELED_SHOOTING_MR2_ID,
      FocusGroupColumnOptionIDs.MODELED_SHOOTING_L2_ID,
      FocusGroupColumnOptionIDs.MODELED_SHOOTING_FT_ID
    ]
  },
  {
    category: 'METRICS_PLAYING_TIME',
    fieldIDs: [
      FocusGroupColumnOptionIDs.PLAYING_TIME_POSITION_ID,
      FocusGroupColumnOptionIDs.PLAYING_TIME_DEFENSIVE_MATCHUP_ID
    ]
  },
  {
    category: 'METRICS_GAME_MINUTES',
    fieldIDs: [
      FocusGroupColumnOptionIDs.PLAYING_TIME_GAME_MINUTES_ID
    ]
  },
  {
    category: 'METRICS_ROTATIONS',
    fieldIDs: [
      FocusGroupColumnOptionIDs.PLAYING_TIME_ROTATIONS_ID
    ]
  },
  {
    category: 'METRICS_TRENDS',
    fieldIDs: [
      FocusGroupColumnOptionIDs.TREND_ID,
      FocusGroupColumnOptionIDs.PAST_GAME_ID,
      FocusGroupColumnOptionIDs.LAST_5_ID,
      FocusGroupColumnOptionIDs.LAST_10_ID,
      FocusGroupColumnOptionIDs.YTD_ID,
    ]
  }
];

export const ServerEvalCategoriesFieldIDs = [
  {
    category: 'EVAL_LOCS',
    fieldIDs: [
      FocusGroupColumnOptionIDs.MY_LOCS_ID,
      FocusGroupColumnOptionIDs.EVALUATOR_LOCS_ID,
      FocusGroupColumnOptionIDs.AVERAGE_LOCS_ID,
    ]
  },
  {
    category: 'EVAL_LOCS_PROBABILITIES',
    fieldIDs: [
      FocusGroupColumnOptionIDs.EVALUATOR_LOCS_PROBABILITIES_ID,
      FocusGroupColumnOptionIDs.AVERAGE_LOCS_PROBABILITIES_ID,
    ]
  },
  {
    category: 'EVAL_TSA',
    fieldIDs: [
      FocusGroupColumnOptionIDs.MY_TSA_ID,
      FocusGroupColumnOptionIDs.EVALUATOR_TSA_ID,
      FocusGroupColumnOptionIDs.AVERAGE_TSA_ID,
    ],
  },
  {
    category: 'EVAL_RANKS',
    fieldIDs: [
      FocusGroupColumnOptionIDs.MY_RANKS_ID,
      FocusGroupColumnOptionIDs.EVALUATOR_RANKS_ID,
      FocusGroupColumnOptionIDs.AVERAGE_RANKS_ID,
      FocusGroupColumnOptionIDs.RANK_RANGES_ID,
    ]
  },
  {
    category: 'EVAL_RESERVE_PRICES',
    fieldIDs: [
      FocusGroupColumnOptionIDs.MY_RESERVE_PRICES_ID,
      FocusGroupColumnOptionIDs.EVALUATOR_RESERVE_PRICES_ID,
      FocusGroupColumnOptionIDs.AVERAGE_RESERVE_PRICES_ID,
    ]
  },
  {
    category: 'EVAL_SUPERSCOUT',
    fieldIDs: [
      FocusGroupColumnOptionIDs.SUPERSCOUT_ID
    ]
  },
  {
    category: 'EVAL_DRAFT_MODEL',
    fieldIDs: [
      FocusGroupColumnOptionIDs.DRAFT_MODEL_ID
    ]
  }
];
