import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild, ViewChildren,
  ViewEncapsulation,
  ViewContainerRef,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {AuthService} from '@services/auth.service';
import {AutocompleteService} from '@services/autocomplete.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {EntitiesService} from '@services/entities.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Title} from '@angular/platform-browser';
import {VideoService} from '@services/video.service';
import {VideoMetrics} from '@models/constants/video/video-metric-types';
import * as _ from 'lodash';
import {User} from '@models/users';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as moment from 'moment-timezone';
import {ConnectionPositionPair} from '@angular/cdk/overlay';
import * as actions from "@store/video-store/actions";
import {Actions, ofType} from '@ngrx/effects';
import {Store} from "@ngrx/store";
import {RootStoreState} from "@store/index";
import {VideoHelper} from "@helpers/video.helper";
import {DOMHelper} from "@helpers/dom.helper";
import {TableFilterComponent} from "../table-filter/table-filter.component";

@UntilDestroy()
@Component({
  selector: 'video-clip-table',
  templateUrl: './video-clip-table.component.html',
  styleUrls: ['./video-clip-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  })
export class VideoClipTableComponent implements OnInit, OnDestroy {
  @ViewChild('clipList') clipList;
  @ViewChild('labelContainer') labelContainer;
  @ViewChild('viewport') viewPort: CdkVirtualScrollViewport;
  @ViewChildren(TableFilterComponent) tableFilters: ElementRef[];

  private overlayRef: OverlayRef | null = null;

  readonly nbaIDLookup = {
    'shooter-name': 'nbaShooterID',
    'assister-name': 'nbaAssisterID',
    'potential-assister-name': 'nbaPotentialAssisterID',
    'blocker-name': 'nbaBlockerID',
    'rebounder-name': 'nbaRebounderID'
  };
  readonly synergyIDLookup = {
    'shooter-name': 'synergyShooterID',
    'assister-name': 'synergyAssisterID',
    'blocker-name': 'synergyBlockerID',
    'rebounder-name': 'synergyRebounderID',
    'cutter-name': 'synergyCutterID',
    'ballhandler-name': 'synergyBallHandlerID',
    'screener-name': 'synergyScreenerID',
    'passer-name': 'synergyPasserID'
  };

  @Input() set clipsOnSide(val: boolean) {
    if (val != null) {
      this._clipsOnSide = val;
      this.recalculateViewportSize();
      if (val) {
        this.scrollSpeed = 1;
      } else {
        this.scrollSpeed = 0.25;
      }
      this.cdr.markForCheck();
    }
  };

  get clipsOnSide() {
    return this._clipsOnSide;
  }

  @Input() set page(val: number) {
    if (val) {
      this._page = val;
      this.cdr.markForCheck();
    }
  };

  get page() {
    return this._page;
  }

  @Input() set displayedClips(val) {
    if (val && (!this.displayedClips || !this.videoHelper.isSameClips(val, this.displayedClips))) {
      if (this.displayedClips && this.displayedClips.length > 0 && this.displayedClips.length != val.length) {
        this.setClipIndices(true, val);
      }
      this._displayedClips = val;
      this.tableData = val;
      if(this.isShuffled) {
        this.isSorted = false;
      }
      this.cdr.markForCheck();
    }
  };

  get displayedClips() {
    return this._displayedClips;
  }

  @Input() set unfilteredClips(val) {
    if (val) {
      this._unfilteredClips = val;
      this.setClipIndices(true);
      this.isSorted = this.isShuffled ? false : !!this.sortDirection && typeof this.sortDirection === 'string' && this.sortDirection !== 'False' && this.sortDirection !== '';
      this.initializeColumnData();
      this.updateTableFilters();
      this.updateScrollIndex();
      this.cdr.markForCheck();
    }
  };

  get unfilteredClips() {
    return this._unfilteredClips;
  }

  @Input() set tableClassName(val) {
    if (val) {
      this._tableClassName = val;
      this.cdr.markForCheck();
    }
  };

  get tableClassName() {
    return this._tableClassName;
  }

  @Input() set columns(val) {
    if (val) {
      this._columns = val;
      this.initializeColumnData();
      this.cdr.markForCheck();
    }
  };

  get columns() {
    return this._columns;
  }

  @Input() set currentVideoIndex(val) {
    if (val != null) {
      this._currentVideoIndex = val;
      this.updateScrollIndex();
      this.cdr.markForCheck();
    }
  };

  get currentVideoIndex() {
    return this._currentVideoIndex;
  }

  get clipIndexMenuClass(): string {
    const classList = ['clip-index-menu'];
  
    if (!this.isTableExpanded && !this.isFullScreen) {
      classList.push('add-top-buffer');
    }
  
    if (this.isFullScreen && this.clipsOnSide) {
      classList.push('add-bottom-buffer', 'clips-on-side-menu');
    }
  
    if (this.isFullScreen && !this.clipsOnSide) {
      classList.push('add-left-top-buffer');
    }
  
    if (!this.isFullScreen && this.isTableExpanded) {
      classList.push('expanded-menu');
    } else if ((!this.isFullScreen && !this.isTableExpanded) || (this.isFullScreen && !this.clipsOnSide)) {
      classList.push('minimized-menu');
    }
  
    return classList.join(' ');
  }  

  @Input() activeFilters: any;
  @Input() clipsOnSideCollapsed: boolean;
  @Input() currentClip: any;
  @Input() currentEpv: number = -1.0;
  @Input() filters: any;
  @Input() fullQuarters: boolean;
  @Input() isFullScreen: boolean;
  @Input() isDragEnabled: boolean;
  @Input() isSaving: boolean;
  @Input() isSavingMany: boolean;
  @Input() isSelectedClips: boolean;
  @Input() isShuffled: boolean;
  @Input() isSmallerDialog: boolean;
  @Input() notesVisible: boolean;
  @Input() isPostComposer: boolean;
  @Input() pageSize: number = 100;
  @Input() savedVideosLookup: any;
  @Input() selectedLeague: string;
  @Input() selectedClips: any[];
  @Input() showFooterBar: boolean;
  @Input() showPaginationBar: boolean;
  @Input() showExpansionButton: boolean;
  @Input() sortBy: string;
  @Input() sortDirection: string;
  @Input() isTableExpanded: boolean;
  @Input() user: User;
  @Input() selectedPlaylist: any;

  @Output() onChangeToClip: EventEmitter<number> = new EventEmitter();
  @Output() onClipDrop: EventEmitter<number> = new EventEmitter();
  @Output() onClipNotesUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onFilterUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onSaveClip: EventEmitter<any> = new EventEmitter();
  @Output() onSortUpdate: EventEmitter<any> = new EventEmitter();
  @Output() onToggleAllSelected: EventEmitter<any> = new EventEmitter();
  @Output() onToggleTableExpanded: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateSelectedClips: EventEmitter<any> = new EventEmitter();

  _clipsOnSide: boolean = false;
  _columns: any[];
  _currentVideoIndex: number = 0;
  _displayedClips: any[];
  _page: number;
  _tableClassName: string;
  _unfilteredClips = [];
  filteredTableData = [];
  tableData = [];

  currentTarget: Element;
  originalZIndexValue: number = 0;
  overlayClip: any;
  raisedZIndexValue: number = 102;
  scrollAccelerationFactor: number = 0;
  scrollDirection;
  scrollIndexRaw: number = 0;
  scrollSpeed: number = 0.25;
  scrollUpdated$: Subject<any> = new Subject<any>();
  onNotesChanged: any = _.debounce(this.saveNotes, 500);
  tableFilterVisible: boolean = false;
  clipIndices: number[] = [];
  firstLoad = true;
  clipIndexMap: Map<number, number> = new Map();
  isSorted: boolean = false;
  globalPos: number = null;

  readonly debounceTime: number = 7;

  public positions = [
    new ConnectionPositionPair({
      originX: 'start',
      originY: 'center',
    },
    {
      overlayX: 'end',
      overlayY: 'center',
    },
    0,
    0),
  ];

  get inverseTranslation(): string {
    return `-${this.viewPort?.getOffsetToRenderedContentStart()}px`;
  }

  constructor(
    protected actions$: Actions,
    protected authService: AuthService,
    protected autocompleteService: AutocompleteService,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected domHelper: DOMHelper,
    protected entitiesService: EntitiesService,
    protected matDialog: MatDialog,
    protected snackBar: MatSnackBar,
    protected store$: Store<RootStoreState.State>,
    protected title: Title,
    protected videoHelper: VideoHelper,
    protected videoService: VideoService,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {
  }

  @HostListener('wheel', ['$event'])
  onWheelScroll(wheelEvent) {
    if (this.isSelectedClips || Math.abs(wheelEvent.wheelDeltaY) < Math.abs(wheelEvent.wheelDeltaX)) {
      return;
    }
    wheelEvent.stopImmediatePropagation();
    wheelEvent.stopPropagation();
    wheelEvent.preventDefault();
    if (wheelEvent.wheelDeltaY > 0) {
      this.updateScroll(true);
    } else {
      this.updateScroll(false);
    }
    if (this.overlayClip) {
      this.overlayClip.isOverlayVisible = false;
    }
    this.cdr.markForCheck();
  }

  ngOnInit() {
    this.actions$.pipe(
      ofType<actions.VideoPositionChange>(actions.ActionTypes.VIDEO_POSITION_CHANGE),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.reorderClips(payload);
    });
  }

  updateScroll(isUp: boolean) {
    if (this.scrollUpdated$.observers.length === 0) {
      this.scrollUpdated$
          .pipe(debounceTime(500), untilDestroyed(this))
          .subscribe((isUp) => {
            this.scrollDirection = null;
            this.scrollAccelerationFactor = 0;
          });
      this.scrollUpdated$
          .pipe(debounceTime(this.debounceTime), untilDestroyed(this))
          .subscribe((isUp) => {
            if (this.scrollDirection == isUp && !this.clipsOnSide) {
              this.scrollAccelerationFactor += 0.05;
            } else {
              this.scrollAccelerationFactor = 0;
            }
            this.scrollDirection = isUp;
            if (isUp) {
              this.scrollUp();
            } else {
              this.scrollDown();
            }
          });
    }
    this.scrollUpdated$.next(isUp);
  }

  updateScrollIndex() {
    setTimeout(() => {
      const newScrollIndex = Math.max(0, this.currentVideoIndex - 2);
      this.scrollIndexRaw = newScrollIndex;
      if (this.viewPort) {
        this.viewPort.scrollToIndex(newScrollIndex);
      }
      this.cdr.markForCheck();
    }, 50);
  }

  scrollDown() {
    const maxIndex = this.tableData?.length - 1;
    const scrollSpeed = (1 + this.scrollAccelerationFactor) * this.scrollSpeed;
    if (this.scrollIndexRaw < maxIndex) {
      this.scrollIndexRaw += scrollSpeed;
    }
    const newIndex = Math.min(maxIndex, Math.floor(this.scrollIndexRaw));
    this.viewPort.scrollToIndex(newIndex);
    this.cdr.markForCheck();
  }

  scrollUp() {
    const scrollSpeed = (1 + this.scrollAccelerationFactor) * this.scrollSpeed;
    // ScrollToIndex sets an index as the first index in the table, so scrolling up from bottom requires us to
    // subtract the number of elements visible in table
    if (this.scrollIndexRaw >= this.tableData?.length - 1) {
      const viewportSize = this.viewPort.getViewportSize();
      this.scrollIndexRaw -= viewportSize / 32;
    }
    if (this.scrollIndexRaw > 0) {
      this.scrollIndexRaw -= scrollSpeed;
    }
    const newIndex = Math.max(0, Math.ceil(this.scrollIndexRaw));
    this.viewPort.scrollToIndex(newIndex);
    this.cdr.markForCheck();
  }

  ncaaOnCourtCoverageMessage(clipParams) {
    if (clipParams.league == 'NCAA' && this.filters.metricType == VideoMetrics.CHANCES) {
      if (clipParams.draftExpressPBPCoverage === false) {
        return 'Game has no DraftExpress PBP';
      }
      if (clipParams.hasDraftExpressPBPMap === false) {
        return 'Game has no oncourt data';
      }
      if (clipParams.coveragePct != 100) {
        return 'Oncourt data is complete for ' + clipParams.coveragePct.toFixed(1) + '% of the game';
      }
    }
    return null;
  }

  changeToClip(index) {
    this.onChangeToClip.emit(index);
  }

  displayDate(date) {
    if (date instanceof Date) {
      return this.currentClip.game.date = date.getMonth() + 1 + '/' + date.getDate() + '/' + (date.getFullYear() % 100);
    }
    return date;
  }

  toggleAllSelected($event) {
    this.onToggleAllSelected.emit($event);
  }

  updateSelectedClips(clip) {
    const clipID = clip.id || clip.eagleChanceID || clip.synergyEventID || clip.eagleChanceID || clip.nbaChanceID;
    const isSelected = clip.isSelected;
    this.onUpdateSelectedClips.emit({id: clipID, isSelected: isSelected});
  }

  roundNumber(num) {
    return Math.round(num);
  }

  sortTable(sortColumn?: string) {
    if (this.sortDirection && this.sortBy === sortColumn) {
      this.sortDirection = this.sortDirection === 'desc' ? 'asc' : '';
    } else if (sortColumn) {
      this.sortDirection = 'desc';
    }
    this.isSorted = this.sortDirection !== '';
    this.sortBy = sortColumn;
    this.onSortUpdate.emit({'column': sortColumn, 'direction': this.sortDirection});
    this.cdr.markForCheck();
  }

  determineSortClass(column) {
    return this.sortBy === column ? this.sortDirection : '';
  }

  recalculateViewportSize(timeout = 30) {
    setTimeout(() => {
      this.viewPort?.checkViewportSize();
      this.cdr.markForCheck();
    }, timeout);
  }

  saveNotes(clip) {
    this.onClipNotesUpdate.emit(clip);
  }

  updateTableFilters() {
    if (this.tableFilters) {
      this.tableFilters.forEach((tableFilter: any) => {
        tableFilter.initializeForm();
        tableFilter.setupForm(true);
      });
      this.cdr.markForCheck();
    }
  }

  setClipIndices(firstLoad = false, val: any = []) {
    const valIds = val.map((clip: any) => clip.id);
    const clips = firstLoad && !val.length ? this.unfilteredClips : val.length ? this.unfilteredClips.filter(clip => valIds.includes(clip.id)) : this.displayedClips;
    clips.forEach((clip, i) => {
      this.clipIndexMap.set(clip.id, i + 1);
    });
    this.clipIndices = Array.from({ length: clips.length }, (_, i) => i + 1);
  }

  getClipIndex(clipId: number): number{
    return this.clipIndexMap.get(clipId);
  }

  initializeColumnData() {
    if (this.columns && this.unfilteredClips) {
      this.columns.forEach((column) => {
        if (column.isFilterable) {
          const columnValueMap = {};
          const key = this.videoHelper.getDataAccessor(column.matColumnDef);
          const sortedClips = _.sortBy(this.unfilteredClips, (c) => _.get(c, this.videoHelper.getRowSpecificAccessor(c, key)));
          let columnValues = [];
          let checkboxImages = [];
          if (['offTeam', 'defTeam'].includes(key)) {
            const baseUrl = 'https://docs-public.imgix.net/';
            columnValues = _.uniq(_.map(sortedClips, (clip) => {
              return _.get(clip, this.videoHelper.getRowSpecificAccessor(clip, key));
            }));
            checkboxImages = _.uniq(_.map(sortedClips, (clip) => {
              return (clip.homeHasPossession && key === 'offTeam') || (!clip.homeHasPossession && key !== 'offTeam') ? baseUrl + clip.homeTeamImage : baseUrl + clip.awayTeamImage;
            }));
          } else if (key.includes('-name')) {
            const nbaID = this.nbaIDLookup[key];
            const synergyID = this.synergyIDLookup[key];
            sortedClips.forEach((clip) => {
              const value = _.get(clip, this.videoHelper.getRowSpecificAccessor(clip, key));
              let imageValue;
              if (key == 'assister-pot-assister-name') {
                imageValue = clip.nbaEntityMap?.[clip[this.nbaIDLookup['assister-name']]]?.imageUrl 
                || clip.nbaEntityMap?.[clip[this.nbaIDLookup['potential-assister-name']]]?.imageUrl
                || clip.synergyEntityMap?.[clip[this.synergyIDLookup['assister-name']]]?.imageUrl 
                || null;      
              } else {
                imageValue = clip[nbaID] ? clip.nbaEntityMap?.[clip[nbaID]]?.imageUrl
                : clip[synergyID] ? clip.synergyEntityMap?.[clip[synergyID]]?.imageUrl
                : null;
              }
              if (value && !columnValueMap[value]) {
                columnValues.push(value);
                checkboxImages.push(imageValue);
                columnValueMap[value] = true;
              }
            });
          } else if (key === 'period') {
            columnValues = _.uniq(_.map(sortedClips, (clip) => String(clip.period)));
          } else if (key === 'isMake') {
            columnValues = _.uniq(_.map(sortedClips, (clip) => (clip.isMake ? 'Make' : 'Miss'))).sort();
          } else if (key === 'actionName') {
            const actionNames = _.uniqBy(sortedClips, clip => JSON.stringify(clip.actionName));
            columnValues = actionNames.map(clip => this.videoHelper.actionDescriptionHelper(clip.actionName)).filter(Boolean);
          } else if (key === 'tags') {
            columnValues = _.sortBy(_.uniq(_.flatMap(sortedClips, clip => this.videoHelper.getTagDataArray(clip))));
          } else {
            columnValues = _.uniq(_.map(sortedClips, key));
          }

          // Remove nullish values
          column.checkboxImages = _.filter(checkboxImages);
          column.values = _.filter(columnValues);
        }
      });
    }
  }

  getTagCount(clip: any): string {
    const tags = clip?.tags?.filter(
      tag => tag.author?.userID === this.user.id
    );
    if (!tags?.length) return '';
    const suffix = tags.length == 1 ? 'Tag' : 'Tags'
    return `${tags.length} ${suffix}`;
  }

  getTagDataArrayIndexed(clip: any): any[] {
    if (!clip.tags?.length) return [];
    const tags = clip.tags.filter(
      tag => tag.author?.userID === this.user.id
    );
    const tagArray: any[] = [];
    tags.some(tag => {
      if (tag?.metadata?.length > 2) {
        tag.metadata.forEach((metadataEntry) => {
          const tagEntry: any = {};
          if (tag?.definition?.name) {
            tagEntry.tagDefinition = tag.definition.name;
          }
          if (metadataEntry?.entity) {
            tagEntry.taggedPlayer = metadataEntry.entity;
          }
          if (metadataEntry?.definition?.name) {
            tagEntry.tagType = metadataEntry.definition.name;
          }
          if (metadataEntry?.option?.name) {
            tagEntry.tagFeedback = metadataEntry.option.name;
          }
          tagArray.push(tagEntry);
        });
      } else {
        const tagEntry: any = {};
        const tagEntry2: any = {};
        if (tag?.definition?.name) {
          tagEntry.tagDefinition = tag.definition.name;
        }
        if (tag?.metadata[1]?.entity) {
          tagEntry.taggedPlayer = tag.metadata[1].entity;
        }
        if (tag?.metadata?.[0]?.definition?.name && tag?.metadata[0]?.entity) {
          tagEntry.tagType = tag.metadata[0].definition.name;
          tagEntry.taggedPlayer = tag.metadata[0].entity;
          if (tag?.metadata[1]?.entity) {
            tagEntry2.tagDefinition = tag.definition.name;
            tagEntry2.tagType = tag.metadata[1].definition.name;
            tagEntry2.taggedPlayer = tag.metadata[1].entity;
          }
        } else if (tag?.metadata[0]?.option?.name) {
          tagEntry.tagType = tag.metadata[0].definition.name;
          tagEntry.tagFeedback = tag.metadata[0].option.name;
        }
        if (Object.keys(tagEntry2).length > 0) {
          tagArray.push(tagEntry2);
        }
        tagArray.push(tagEntry);
        return false;
      }
    });
    return tagArray;
  }


  formatPlayerName(playerName: string): string {
    if (!playerName) return '\u2014';
    const nameParts = playerName.split(' ');
    if (nameParts.length < 2) return playerName;
    const isSuffix = (part: string) => part.length <= 3 || /^\d+$/.test(part) || part.endsWith('.');
    const hasSuffix = isSuffix(nameParts.slice(-1)[0]);
    const lastName = hasSuffix && nameParts.length > 2 ? nameParts.slice(-2, -1)[0] : nameParts.slice(-1)[0];

    const suffix = hasSuffix && lastName !== nameParts.slice(-1)[0] ? ` ${nameParts.slice(-1)[0]}` : '';
    const firstName = nameParts[0];
    return `${firstName.charAt(0)}. ${lastName}${suffix}`;
  }

  showTooltip(event: MouseEvent, tooltip: any) {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(event.target as HTMLElement)
      .withPositions([{
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      }]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: false,
    });

    const tooltipPortal = new TemplatePortal(tooltip, this.viewContainerRef);
    this.overlayRef.attach(tooltipPortal);
  }

  hideTooltip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  filterData(values, key) {
    this.onFilterUpdate.emit({values: values, key: key});
  }


  handleBlur(isVisible, event) {
    event.stopPropagation();
    event.preventDefault();
    if (isVisible) {
      this.tableFilterVisible = isVisible;
    } else if (!event.currentTarget.contains(event.relatedTarget)) {
      this.tableFilterVisible = isVisible;
    }
    this.cdr.markForCheck();
  }

  visibilityUpdate(isVisible) {
    this.tableFilterVisible = isVisible;
    this.cdr.markForCheck();
  }

  resetZIndex(target) {
    // Reset z-index of parent on modal close
    setTimeout(() => {
      if (target?.offsetParent) {
        target.offsetParent.style.zIndex = this.originalZIndexValue;
        this.cdr.markForCheck();
      }
    });
  }

  isClipSaved(clip) {
    return this.savedVideosLookup && (
      clip.synergyEventID && this.savedVideosLookup?.synergyEventIDs?.includes(clip.synergyEventID) ||
      clip.nbaChanceID && this.savedVideosLookup?.nbaChanceIDs?.includes(clip.nbaChanceID) ||
      clip.eagleChanceID && this.savedVideosLookup?.eagleChanceIDs?.includes(clip.eagleChanceID)
    );
  }

  changeToClipAndSave(index) {
    this.onSaveClip.emit(index);
  }

  editRowLabel(clip, $event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.overlayClip = clip;
    clip.isOverlayVisible = true;
    setTimeout(() => {
      if (this.labelContainer) {
        this.labelContainer.nativeElement.focus();
      }
    }, 50);
  }

  updateClipLabel(clip, label) {
    clip.label = label;
    clip.isOverlayVisible = false;
    this.store$.dispatch(new actions.SaveAction({'videoSaved': clip, 'chainID': this.videoService.generateChainID()}));
    this.cdr.markForCheck();
  }

  dropRow($event) {
    this.onClipDrop.emit($event);
  }

  getActionDescription(actionName) {
    return this.videoHelper.actionDescriptionHelper(actionName);
  }

  isSavedPlaylist(): boolean {
    return !!this.selectedPlaylist;
  }

  changeClipIndex(clip: any, position: number) {
    this.globalPos = ((this.getClipIndex(clip.id) - 1) === this.currentVideoIndex) ? (position - 1) : null;
    let clipChangeData = {
      id: clip.id,
      playlistID: this.selectedPlaylist.id,
      newPosition: position,
    };
    this.store$.dispatch(new actions.SaveAction({'videoChangePosition': clipChangeData, 'chainID': this.videoService.generateChainID()}));
    this.cdr.markForCheck();
  }

  reorderClips(newClipMap: { [id: number]: number }) {
    const currentPositions = this.displayedClips.reduce((map: { [id: number]: number }, clip, index) => {
      map[clip.id] = index;
      return map;
    }, {});
    const allClipsWithNewPositions = this.displayedClips.map(clip => {
      const newPosition = newClipMap[clip.id];
      return {
        clip,
        position: newPosition !== undefined ? newPosition - 1 : currentPositions[clip.id],
      };
    });
    this.displayedClips = _.map(_.sortBy(allClipsWithNewPositions, 'position'), 'clip');
    this.setClipIndices();
    if (this.globalPos) {
      this.currentVideoIndex = this.globalPos;
    }
    this.cdr.markForCheck();
  }

  handleButtonClick(event: Event) {
    if (!this.isCustomSortDisabled()) {
      event.stopPropagation();
    }
  }

  isCustomSortDisabled() {
    return this.isSorted || this.isShuffled || !_.isEmpty(this.activeFilters)
  }

  ngOnDestroy() {
  }
}
