import {
  abstractActionItems, abstractEventItems,
  assistActionItems,
  blockActionItems,
  chartingActionItems,
  cutsActionItems,
  defenseItems,
  offenseItems,
  drivesActionItems,
  foulActionItems,
  freeThrowActionItems, handoffsActionItems, isloationActionItems, picksActionItems, postUpActionItems,
  reboundActionItems,
  shotActionItems, spotUpActionItems,
  stealActionItems,
  touchesActionItems, transitionActionItems,
  turnoverActionItems,
} from '@models/constants/video/video-action-items';
import {VideoSubject} from '@models/constants/video/video-subjects';

export enum VideoMetrics {
  CHANCES = 'chances',
  POINTS = 'points',
  SHOTS = 'shots',
  FIELD_GOAL_ATTEMPTS = 'fga',
  FIELD_GOAL_MAKES = 'fgm',
  TWO_POINT_MAKES = '2pm',
  TWO_POINT_ATTEMPTS = '2pa',
  THREE_POINT_MAKES = '3pm',
  THREE_POINT_ATTEMPTS = '3pa',
  FREE_THROW_MAKES = 'ftm',
  FREE_THROW_ATTEMPTS = 'fta',
  ASSISTS = 'assists',
  TOTAL_REBOUNDS = 'trb',
  OFFENSIVE_REBOUNDS = 'orb',
  DEFENSIVE_REBOUNDS = 'drb',
  STEALS = 'steals',
  BLOCKS = 'blocks',
  TURNOVERS = 'turnovers',
  DEFENSIVE_FOULS = 'defensive_fouls',
  FOULS = 'fouls',
  FOULS_DRAWN = 'fouls_drawn',
  FT_DRAWN = 'ft_drawn',
  CHARTING = 'charting',
  TOUCHES = 'touches',
  USAGE = 'usage',
  PLAYCALLS = 'playcalls',
  PLAYCALLS_SHOTS = 'playcalls_shots',
  PLAYCALLS_FT_DRAWN = 'playcalls_ft_drawn',
  PLAYCALLS_TURNOVERS = 'playcalls_turnovers',
  PLAYCALLS_ORBS = 'playcalls_orb',
  PLAYCALLS_ASSISTS = 'playcalls_assists',
  PLAYCALLS_TWO_POINT_MAKES = 'playcalls_2pm',
  PLAYCALLS_TWO_POINT_ATTEMPTS = 'playcalls_2pa',
  PLAYCALLS_THREE_POINT_MAKES = 'playcalls_3pm',
  PLAYCALLS_THREE_POINT_ATTEMPTS = 'playcalls_3pa',
  PLAYCALLS_FIELD_GOAL_MAKES = 'playcalls_fgm',
  PLAYCALLS_FIELD_GOAL_ATTEMPTS = 'playcalls_fga',
  PLAYCALLS_FREE_THROW_MAKES = 'playcalls_ftm',
  PLAYCALLS_FREE_THROW_ATTEMPTS = 'playcalls_fta',
  POTENTIAL_ASSIST = 'assist_opp',
  DP_SHOTS = 'dp_shots',
  BLK_SHOTS = 'blk_shots',
  HP_SHOTS = 'hp_shots',
  NP2_SHOTS = 'np2_shots',
  MR2_SHOTS = 'mr2_shots',
  L2_SHOTS = 'l2_shots',
  SHOOTING_FOUL_DRAWN = 'shooting_foul_drawn',
  CONTESTS = 'contests',
  DP_CONTESTS = 'dp_contests',
  PG_MINUTES = 'pg_minutes',
  SG_MINUTES = 'sg_minutes',
  SF_MINUTES = 'sf_minutes',
  PF_MINUTES = 'pf_minutes',
  C_MINUTES = 'c_minutes',
  DEFENSIVE_MINUTES = 'defensive_minutes',
  OFFENSIVE_MINUTES = 'offensive_minutes',
  CATCH_AND_SHOOT_OPP = 'catch_and_shoot_opp',
  DUNKS = 'dunks',
  ASSISTS_ASSIST_OPS_TURNOVERS = 'assists_and_assist_ops_and_turnovers'
}

export const FieldToVideoMetric = {
  'minutesPerGame': VideoMetrics.CHANCES,
  'pointsPerGame': VideoMetrics.POINTS,
  'assistsPerGame': VideoMetrics.ASSISTS,
  'totalReboundsPerGame': VideoMetrics.TOTAL_REBOUNDS,
  'turnoversPerGame': VideoMetrics.TURNOVERS,
  'stealsPerGame': VideoMetrics.STEALS,
  'blocksPerGame': VideoMetrics.BLOCKS,
  'personalFoulsPerGame': VideoMetrics.FOULS,
  'fieldGoalPct': VideoMetrics.FIELD_GOAL_ATTEMPTS,
  'fieldGoal2Pct': VideoMetrics.TWO_POINT_ATTEMPTS,
  'fieldGoal3Pct': VideoMetrics.THREE_POINT_ATTEMPTS,
  'fg3AttemptedPerGame': VideoMetrics.THREE_POINT_ATTEMPTS,
  'coefBull3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'coefNow3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'efg3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'xefg3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'qefg3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'qfg3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'fg3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'qfgRes3': VideoMetrics.THREE_POINT_ATTEMPTS,
  'threePointAttemptPct': VideoMetrics.THREE_POINT_ATTEMPTS,
  'freeThrowPct': VideoMetrics.FREE_THROW_ATTEMPTS,
  'ftAttemptedPerGame': VideoMetrics.FREE_THROW_ATTEMPTS,
  'xftp': VideoMetrics.FREE_THROW_ATTEMPTS,
  'minutesPerAvailableGame': VideoMetrics.CHANCES,
  'pointsPerAttempt': VideoMetrics.SHOTS,
  'qPPA': VideoMetrics.SHOTS,
  'xPPA': VideoMetrics.SHOTS,
  'effectiveFieldGoalPct': VideoMetrics.FIELD_GOAL_ATTEMPTS,
  'xEffectiveFieldGoalPct': VideoMetrics.FIELD_GOAL_ATTEMPTS,
  'qEffectiveFieldGoalPct': VideoMetrics.FIELD_GOAL_ATTEMPTS,
  'usage': VideoMetrics.USAGE,
  'freeThrowAttemptPct': VideoMetrics.FT_DRAWN,
  'assistPct': VideoMetrics.ASSISTS,
  'turnoverPct': VideoMetrics.TURNOVERS,
  'offensiveReboundPct': VideoMetrics.OFFENSIVE_REBOUNDS,
  'offensiveReboundsPerGame': VideoMetrics.OFFENSIVE_REBOUNDS,
  'defensiveReboundPct': VideoMetrics.DEFENSIVE_REBOUNDS,
  'defensiveReboundsPerGame': VideoMetrics.DEFENSIVE_REBOUNDS,
  'stealPct': VideoMetrics.STEALS,
  'blockPct': VideoMetrics.BLOCKS,
  'foulRate': VideoMetrics.DEFENSIVE_FOULS,
  'shotAttemptUsage': VideoMetrics.SHOTS,
  'potentialAssistPct': VideoMetrics.POTENTIAL_ASSIST,
  'potentialAssistPPA': VideoMetrics.POTENTIAL_ASSIST,
  'potentialAssistqPPA': VideoMetrics.POTENTIAL_ASSIST,
  'potentialAssistxPPA': VideoMetrics.POTENTIAL_ASSIST,
  'potentialAssistsPerGame': VideoMetrics.POTENTIAL_ASSIST,
  'coefNowDP': VideoMetrics.DP_SHOTS,
  'coefBullDP': VideoMetrics.DP_SHOTS,
  'dpPPA': VideoMetrics.DP_SHOTS,
  'dpqPPA': VideoMetrics.DP_SHOTS,
  'dpxPPA': VideoMetrics.DP_SHOTS,
  'efgDP': VideoMetrics.DP_SHOTS,
  'qefgDP': VideoMetrics.DP_SHOTS,
  'qefgResDP': VideoMetrics.DP_SHOTS,
  'coefNowM2': VideoMetrics.MR2_SHOTS,
  'coefBullM2': VideoMetrics.MR2_SHOTS,
  'efgM2': VideoMetrics.MR2_SHOTS,
  'qefgM2': VideoMetrics.MR2_SHOTS,
  'qefgResM2': VideoMetrics.MR2_SHOTS,
  'coefNowL2': VideoMetrics.L2_SHOTS,
  'coefBullL2': VideoMetrics.L2_SHOTS,
  'efgL2': VideoMetrics.L2_SHOTS,
  'qefgL2': VideoMetrics.L2_SHOTS,
  'qefgResL2': VideoMetrics.L2_SHOTS,
  'shootingFoulsDrawnPct': VideoMetrics.SHOOTING_FOUL_DRAWN,
  'shootingFoulsDrawnPerGame': VideoMetrics.SHOOTING_FOUL_DRAWN,
  'shotsContestedPct': VideoMetrics.CONTESTS,
  'shotsContestedPerGame': VideoMetrics.CONTESTS,
  'dpShotsContestedPct': VideoMetrics.DP_CONTESTS,
  'dpShotsContestedPerGame': VideoMetrics.DP_CONTESTS,
  'dpContestPPA': VideoMetrics.DP_CONTESTS,
  'dpContestqPPA': VideoMetrics.DP_CONTESTS,
  'dpContestxPPA': VideoMetrics.DP_CONTESTS,
  'minutes_observed_defending_average': VideoMetrics.DEFENSIVE_MINUTES,
  'minutes_playing_pg_percent': VideoMetrics.PG_MINUTES,
  'minutes_playing_sg_percent': VideoMetrics.SG_MINUTES,
  'minutes_playing_sf_percent': VideoMetrics.SF_MINUTES,
  'minutes_playing_pf_percent': VideoMetrics.PF_MINUTES,
  'minutes_playing_c_percent': VideoMetrics.C_MINUTES,
  'takePct': VideoMetrics.CATCH_AND_SHOOT_OPP,
  'efficiencyNet': VideoMetrics.CHANCES,
  'efficiencyNetDelta': VideoMetrics.CHANCES,
  'efficiencyOffense': VideoMetrics.OFFENSIVE_MINUTES,
  'efficiencyDefense': VideoMetrics.DEFENSIVE_MINUTES,
  'xEfficiencyOffense': VideoMetrics.OFFENSIVE_MINUTES,
  'xEfficiencyDefense': VideoMetrics.DEFENSIVE_MINUTES,
  'xEfficiencyNet': VideoMetrics.CHANCES,
  'xEfficiencyNetDelta': VideoMetrics.CHANCES,
  'opponentLocNet': VideoMetrics.CHANCES,
  'dunksPer100': VideoMetrics.DUNKS,
};

export const FieldToVideoSubject = {
  'minutesPerGame': VideoSubject.CHANCES,
  'pointsPerGame': VideoSubject.POINTS,
  'assistsPerGame': VideoSubject.ASSISTS,
  'totalReboundsPerGame': VideoSubject.REBOUNDS,
  'turnoversPerGame': VideoSubject.TURNOVERS,
  'stealsPerGame': VideoSubject.STEALS,
  'blocksPerGame': VideoSubject.BLOCKS,
  'personalFoulsPerGame': VideoSubject.FOULS,
  'fieldGoalPct': VideoSubject.SHOTS,
  'fieldGoal2Pct': VideoSubject.SHOTS,
  'fieldGoal3Pct': VideoSubject.SHOTS,
  'threePointAttemptPct': VideoMetrics.THREE_POINT_ATTEMPTS,
  'freeThrowPct': VideoSubject.FREE_THROWS,
  'minutesPerAvailableGame': VideoSubject.CHANCES,
  'pointsPerAttempt': VideoSubject.POINTS,
  'effectiveFieldGoalPct': VideoSubject.SHOTS,
  'xEffectiveFieldGoalPct': VideoSubject.SHOTS,
  'qEffectiveFieldGoalPct': VideoSubject.SHOTS,
  'usage': VideoSubject.USAGE,
  'freeThrowAttemptPct': VideoSubject.FREE_THROWS,
  'assistPct': VideoSubject.ASSISTS,
  'turnoverPct': VideoSubject.TURNOVERS,
  'offensiveReboundPct': VideoSubject.REBOUNDS,
  'defensiveReboundPct': VideoSubject.REBOUNDS,
  'stealPct': VideoSubject.STEALS,
  'blockPct': VideoSubject.BLOCKS,
  'foulRate': VideoSubject.FOULS,
};

export const subjectToPrimaryFilterName = {
  [VideoSubject.TOUCHES]: 'nbaid',
  [VideoSubject.POINTS]: 'subject_player_id',
  [VideoSubject.CHANCES]: 'on_court_player_ids',
  [VideoSubject.POSSESSIONS_ASSISTS]: 'subject_player_id',
  [VideoSubject.BLOCKS_STEALS]: 'subject_player_id',
  [VideoSubject.ASSISTS_TURNOVERS]: 'subject_player_id',
  [VideoSubject.ASSISTS_ASSIST_OPS_TURNOVERS]: 'subject_player_id',
  [VideoSubject.SHOTS]: 'shooter_id',
  [VideoSubject.ASSISTS]: 'assister_id',
  [VideoSubject.REBOUNDS]: 'rebounder_id',
  [VideoSubject.STEALS]: 'stealer_id',
  [VideoSubject.BLOCKS]: 'blocker_id',
  [VideoSubject.TURNOVERS]: 'turnoverer_id',
  [VideoSubject.FOULS]: 'fouler_id',
  [VideoSubject.FREE_THROWS]: 'nbapersonid',
  [VideoSubject.CHARTING]: 'nba_person_id',
  [VideoSubject.CUTS]: 'synergy_subject_player_id',
  [VideoSubject.DRIVES]: 'synergy_subject_player_id',
  [VideoSubject.PICKS]: 'synergy_subject_player_id',
  [VideoSubject.POST_UPS]: 'synergy_subject_player_id',
  [VideoSubject.SPOT_UPS]: 'synergy_subject_player_id',
  [VideoSubject.TRANSITION]: 'synergy_subject_player_id',
  [VideoSubject.TRANSITION_OFFENSE]: 'subject_player_id',
  [VideoSubject.USAGE]: 'subject_player_id',
};

export const PlaycallFieldToVideoMetric = {
  'playCount': VideoMetrics.PLAYCALLS,
  'efficiencyOffense': VideoMetrics.PLAYCALLS,
  'xEfficiencyOffense': VideoMetrics.PLAYCALLS,
  'effectiveFieldGoalPctOffense': VideoMetrics.PLAYCALLS_FIELD_GOAL_ATTEMPTS,
  'qEffectiveFieldGoalPctOffense': VideoMetrics.PLAYCALLS_FIELD_GOAL_ATTEMPTS,
  'xEffectiveFieldGoalPctOffense': VideoMetrics.PLAYCALLS_FIELD_GOAL_ATTEMPTS,
  'freeThrowAttemptPctOffense': VideoMetrics.PLAYCALLS_FT_DRAWN,
  'turnoverPctOffense': VideoMetrics.PLAYCALLS_TURNOVERS,
  'offensiveReboundPct': VideoMetrics.PLAYCALLS_ORBS,
  'assistPctOffense': VideoMetrics.PLAYCALLS_ASSISTS,
  'fieldGoalPctOffense': VideoMetrics.PLAYCALLS_FIELD_GOAL_ATTEMPTS,
  'fgMade': VideoMetrics.PLAYCALLS_FIELD_GOAL_MAKES,
  'fgAttempted': VideoMetrics.PLAYCALLS_FIELD_GOAL_ATTEMPTS,
  'fg2Made': VideoMetrics.PLAYCALLS_TWO_POINT_MAKES,
  'fg2Attempted': VideoMetrics.PLAYCALLS_TWO_POINT_ATTEMPTS,
  'fieldGoal2PctOffense': VideoMetrics.PLAYCALLS_TWO_POINT_ATTEMPTS,
  'fg3Made': VideoMetrics.PLAYCALLS_THREE_POINT_MAKES,
  'fg3Attempted': VideoMetrics.PLAYCALLS_THREE_POINT_ATTEMPTS,
  'fieldGoal3PctOffense': VideoMetrics.PLAYCALLS_THREE_POINT_ATTEMPTS,
  'ftPctOffense': VideoMetrics.PLAYCALLS_FREE_THROW_ATTEMPTS,
  'ftMade': VideoMetrics.PLAYCALLS_FREE_THROW_MAKES,
  'ftAttempted': VideoMetrics.PLAYCALLS_FREE_THROW_ATTEMPTS,
  'shots': VideoMetrics.PLAYCALLS_SHOTS,
};

const allLeagues = ['NBA', 'GLG', 'NCAA', 'OTHER'];
const amateurLeagues = ['NCAA', 'OTHER'];
const proLeagues = ['NBA', 'GLG'];
const NBAOnly = ['NBA'];


export const videoEventFilterOptions = [
  // {name: 'Games (Periods)', value: VideoSubject.PERIODS, leagues: allLeagues, actionItems: [], actionContextItems: []},
  {name: 'Minutes', value: VideoSubject.CHANCES, leagues: allLeagues, actionItems: [], actionContextItems: []},
  {name: 'Touches', value: VideoMetrics.TOUCHES, leagues: proLeagues, actionItems: touchesActionItems, actionContextItems: []},
  {name: 'Points', value: VideoMetrics.POINTS, leagues: allLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Usage', value: VideoMetrics.USAGE, leagues: allLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Shots', value: VideoSubject.SHOTS, leagues: allLeagues, actionItems: shotActionItems, actionContextItems: []},
  {name: 'Assists', value: VideoSubject.ASSISTS, leagues: allLeagues, actionItems: assistActionItems, actionContextItems: []},
  {name: 'Rebounds', value: VideoSubject.REBOUNDS, leagues: allLeagues, actionItems: reboundActionItems, actionContextItems: []},
  {name: 'Steals', value: VideoSubject.STEALS, leagues: allLeagues, actionItems: stealActionItems, actionContextItems: []},
  {name: 'Blocks', value: VideoSubject.BLOCKS, leagues: allLeagues, actionItems: blockActionItems, actionContextItems: []},
  {name: 'Turnovers', value: VideoSubject.TURNOVERS, leagues: allLeagues, actionItems: turnoverActionItems, actionContextItems: []},
  {name: 'Fouls', value: VideoSubject.FOULS, leagues: allLeagues, actionItems: foulActionItems, actionContextItems: []},
  // {name: 'Free Throws', value: VideoSubject.FREE_THROWS, leagues: allLeagues, actionItems: freeThrowActionItems, actionContextItems: []},
  {name: 'Charting', value: VideoMetrics.CHARTING, leagues: NBAOnly, actionItems: chartingActionItems, actionContextItems: []},
  {name: 'Potential Assist', value: VideoSubject.ASSIST_OPP, leagues: proLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Possessions + Assists', value: VideoSubject.POSSESSIONS_ASSISTS, leagues: allLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Assists + Pot. Assists + Turnovers', value: VideoSubject.ASSISTS_ASSIST_OPS_TURNOVERS, leagues: proLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Blocks + Steals', value: VideoSubject.BLOCKS_STEALS, leagues: allLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Assists + Turnovers', value: VideoSubject.ASSISTS_TURNOVERS, leagues: allLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Transition Offense', value: VideoSubject.TRANSITION_OFFENSE, leagues: proLeagues, actionItems: abstractEventItems, actionContextItems: []},
  {name: 'Defense', value: VideoSubject.DEFENSE, leagues: NBAOnly, actionItems: defenseItems, actionContextItems: []},
  {name: 'Offense', value: VideoSubject.OFFENSE, leagues: NBAOnly, actionItems: offenseItems, actionContextItems: []},
];

export const videoActionFilterOptions = [
  {name: 'Cuts', value: VideoSubject.CUTS, leagues: amateurLeagues, actionItems: cutsActionItems, actionContextItems: []},
  {name: 'Drives', value: VideoSubject.DRIVES, leagues: amateurLeagues, actionItems: drivesActionItems, actionContextItems: []},
  {name: 'Handoffs', value: VideoSubject.HANDOFFS, leagues: amateurLeagues, actionItems: handoffsActionItems, actionContextItems: []},
  {name: 'Isolation', value: VideoSubject.ISOLATION, leagues: amateurLeagues, actionItems: isloationActionItems, actionContextItems: []},
  {name: 'Picks', value: VideoSubject.PICKS, leagues: amateurLeagues, actionItems: picksActionItems, actionContextItems: []},
  {name: 'Post Ups', value: VideoSubject.POST_UPS, leagues: amateurLeagues, actionItems: postUpActionItems, actionContextItems: []},
  {name: 'Spot Ups', value: VideoSubject.SPOT_UPS, leagues: amateurLeagues, actionItems: spotUpActionItems, actionContextItems: []},
  {name: 'Transition', value: VideoSubject.TRANSITION, leagues: amateurLeagues, actionItems: transitionActionItems, actionContextItems: []},
];

// TODO: Add enum for filter names?
// Make sure to update in generate-playlist.ts > resetFilters as well
export const persistingFilters = [
  'nba_on_court_player_ids', 'nba_offensive_player_ids', 'nba_defensive_player_ids', 'synergy_on_court_player_ids',
  'synergy_offensive_player_ids', 'synergy_defensive_player_ids', 'synergy_primary_defensive_player_id',
  'nba_on_court_team_ids', 'synergy_on_court_team_ids', 'synergy_defensive_team_id', 'synergy_offensive_team_ids',
  'nba_defensive_team_ids', 'nba_offensive_team_ids',
  'game_type', 'season', 'period', 'sportradar_game_id', 'nba_game_id', 'league_id', 'starting_seconds_left_in_period',
  'shot_clock', 'game_date', 'nba_chance_id', 'nba_possession_id', 'name',
];

export const defOffPersistingFilters = [
  'season', 'league_id',
];
