import * as moment from 'moment';
import * as _ from 'lodash';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Inject,
  OnDestroy,
  OnInit,
  AfterViewInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime, filter, take, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from 'environments/environment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Title} from '@angular/platform-browser';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EntitiesService} from '@services/entities.service';
import {VideoService} from '@services/video.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatLegacyAutocomplete as MatAutocomplete} from '@angular/material/legacy-autocomplete';
import {FormControl} from '@angular/forms';
import {AutocompleteService} from 'apps/_services/autocomplete.service';
import {User} from '@models/users';
import {AuthService} from '@services/auth.service';
import {League} from '@models/leagues';
import {ConfirmDialogComponent} from '@bild-dialogs/confirm-dialog/confirm-dialog.component';
import {SavedPlaylistDialogComponent} from '@bild-dialogs/saved-playlist/saved-playlist.component';
import {SavedFolderDialogComponent} from '@bild-dialogs/saved-folder/saved-folder.component';
import {RootStoreState} from 'apps/_store';
import {VideoStoreActions, VideoStoreSelectors} from '@store/video-store';
import {Actions, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {subjectToPrimaryFilterName, VideoMetrics,} from '@models/constants/video/video-metric-types';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Clipboard} from '@angular/cdk/clipboard';
import {Location} from '@angular/common';
import * as actions from '@store/video-store/actions';
import {
  VideoActionTypes,
  VideoExploreTypes,
  VideoNavigationTypes,
  VideoSharingTypes,
  VideoTabTypes
} from '@models/video';
import {VideoTables} from "@models/constants/video/video-action-items";
import {actionNames, VideoSubject, videoSubjectToLabel} from '@models/constants/video/video-subjects';
import {ConnectionPositionPair} from "@angular/cdk/overlay";
import {VideoHelper} from "@helpers/video.helper";
import {DOCUMENT} from '@angular/common';
import { BooleanDefenseTags } from '@models/constants/video/video-action-items';

@UntilDestroy()
@Component({
  selector: 'bild-browse-video',
  templateUrl: './browse-video.component.html',
  styleUrls: ['./browse-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BrowseVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('generatePlaylistComponent') generatePlaylistComponent;
  @ViewChild('videoPlayer') videoPlayer;
  @ViewChild('timeDot') timeDot;
  @ViewChild('progressBar') progressBar;
  @ViewChild('videoControls') videoControls;
  @ViewChild('editorsAutocomplete', { static: true }) editorsAutocomplete: MatAutocomplete;
  @ViewChild('editorsInput') editorsInput: ElementRef;
  @ViewChild('viewersAutocomplete', { static: true }) viewersAutocomplete: MatAutocomplete;
  @ViewChild('viewersInput') viewersInput: ElementRef;
  @ViewChild('entitiesAutocomplete', { static: true }) entitiesAutocomplete: MatAutocomplete;
  @ViewChild('entitiesInput') entitiesInput: ElementRef;
  @ViewChild('onCourtEntitiesAutocomplete', { static: true }) onCourtEntitiesAutocomplete: MatAutocomplete;
  @ViewChild('onCourtEntitiesInput') onCourtEntitiesInput: ElementRef;
  @ViewChild('offCourtEntitiesAutocomplete', { static: true }) offCourtEntitiesAutocomplete: MatAutocomplete;
  @ViewChild('offCourtEntitiesInput') offCourtEntitiesInput: ElementRef;
  @ViewChild('offensiveLineupEntitiesAutocomplete', { static: true }) offensiveLineupEntitiesAutocomplete: MatAutocomplete;
  @ViewChild('offensiveLineupEntitiesInput') offensiveLineupEntitiesInput: ElementRef;
  @ViewChild('defensiveLineupEntitiesAutocomplete', { static: true }) defensiveLineupEntitiesAutocomplete: MatAutocomplete;
  @ViewChild('defensiveLineupEntitiesInput') defensiveLineupEntitiesInput: ElementRef;
  @ViewChild('primaryEntityAutocomplete', { static: true }) primaryEntityAutocomplete: MatAutocomplete;
  @ViewChild('primaryEntityInput') primaryEntityInput: ElementRef;
  @ViewChild('secondaryEntityAutocomplete', { static: true }) secondaryEntityAutocomplete: MatAutocomplete;
  @ViewChild('secondaryEntityInput') secondaryEntityInput: ElementRef;
  @ViewChild('teamEntitiesAutocomplete', { static: true }) teamEntitiesAutocomplete: MatAutocomplete;
  @ViewChild('teamEntitiesInput') teamEntitiesInput: ElementRef;
  @ViewChild('videoClipTable') videoClipTable;
  @ViewChild('videoClipTable', {read: ElementRef}) videoClipTableRef;
  @ViewChild('reportButton') reportButton;

  readonly VideoMetrics = VideoMetrics;
  private resizeObserver: ResizeObserver;
  readonly VideoNavigationTypes = VideoNavigationTypes;
  readonly VideoExploreTypes = VideoExploreTypes;
  readonly VideoSharingTypes = VideoSharingTypes;
  readonly chanceViewerIDs = [2, 26, 27, 29, 32, 33, 93, 107, 115, 128, 130, 214];
  readonly BooleanDefenseTags = BooleanDefenseTags;
  readonly requiresDefaultStartPadding = [VideoSubject.BLOCKS_STEALS, VideoSubject.BLOCKS, VideoSubject.STEALS];
  readonly playerOnlyTags = ['Drive', 'Isolation', 'Off-Ball Screen', 'Ball Screen'];

  activeFilters = {};
  filterProperties = {};
  isMobile: boolean;
  clips: any = [];
  filteredPlaylists: any = [];
  unfilteredClips: any[] = [];
  selectedPlaylist: any = null;
  selectedGeneratedPlaylist: any = null;
  displayedClips: any = [];
  displayedClipsLoaded = false;
  selectedClips: any[] = [];
  selectedLeague: string = 'NCAA';
  selectedLeagueToLeagueIDs: any = {'NBA': [1], 'GLG': [2], 'NCAA': [101], 'OTHER': []}
  entityNBA: League = null;
  entityGLG: League = null;
  entityNCAA: League = null;
  isTableExpanded: boolean = false;
  selectedClipAvailabilities: boolean[] = [true, false];
  playlistSearchValue: string = '';
  possessionTeamOptions: any[] = [];
  selectedPossessionTeams: any[] = [];
  channelName: string;
  sessionMetadata: any = null;
  tableWidth: string;
  chainID: any = null;
  selectedPlaylist1;
  selectedPlaylist2;
  selectedPlaylist3;
  selectedPlaylist4;
  selectedPlaylist5;
  selectedPlaylist6;
  sortBy: string;
  sortDirection: string;
  user: User;
  userPlaylists: any[] = null;
  userGeneratedPlaylists: any[] = null;
  savedVideosLookup: any = {};
  bulkSaveDestinationPlaylists: any[] = [];
  currentVideoIndex: number = 0;
  currentVideoID: number;
  currentClip1: any = null;
  currentClip2: any = null;
  initialGeneratedPlaylistFilters;
  initialGeneratedPlaylistId;
  initialPlaylistId;
  initialVideoLoaded: boolean = false;
  isClosing: boolean = false;
  isDragging: boolean = false;
  isHotkeysIntialized: boolean = false;
  isLoading: boolean = false;
  isGeneratingPlaylist: boolean = false;
  isSaving: boolean = false;
  isTagging: boolean = false;
  isSavingMany: boolean = false;
  isEditingPlaylist: boolean = false;
  isEditingFolder: boolean = false;
  savingInProgress = false;
  isVideoPlaying: boolean = false;
  element;
  enteredFullScreen: boolean = false;
  showEpv: boolean = false;
  show2dCourt: boolean = false;
  clipsOnSide: boolean = true;
  clipsOnSideCollapsed: boolean = true;
  notesVisible: boolean = true;
  volume = 0;
  playbackSpeed = 1;
  currentVideoTime1: number = 0;
  currentVideoTime2: number = 0;
  timeDotLeft: number;
  timeDotTop: number;
  wasPlayingBeforeDrag: boolean = false;
  awaitingAutoplayAvailability: boolean = true;
  fullQuarters: boolean = false;
  encounteredError: boolean = false;
  page: number = 1;
  pageSize: number = 100;
  video1;
  video2;
  queryParams;
  awaitingGeneratedPlaylistCreation: boolean = false;
  tagDefinitions: any[] = [];
  selectedTagType: any;
  tagEditing: any;
  tagStartSeconds = 0;
  tagEndSeconds = 100;
  defaultPaddingSet = 12;
  keepTagPanelOpen: boolean = true;
  tagPlayers: boolean = false;
  showTagPanel: boolean = false;
  hideTagPanel: boolean = false;
  previousEntitySelected: boolean = false;
  isAddingToFolder: boolean = false;
  destinationFolders: any[] = [];
  selectedFolder: any = null;
  selectedPlaylists: any[] = [];
  userFolders: any[] = null;
  folderSearchValue: string = '';
  filteredFolders: any = [];
  folderReceived: any;
  folderPath: string;
  capturedClipOrder: any = [];

  filteredEditors: any[];
  folderFilteredEditors: any[];
  editorsControl: FormControl = new FormControl();
  filteredViewers: any[];
  folderFilteredViewers: any[];
  viewersControl: FormControl = new FormControl();
  filteredEntities: any[];
  entitiesControl: FormControl = new FormControl();

  startTrimSeconds = 0;
  endTrimSeconds = 100;
  trimSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 100,
    step: 1,
    noSwitching: true,
    animate: false
  };

  readonly defaultDisplayedFilters: any = {
    actions: [],
    leagueIDs: this.selectedLeagueToLeagueIDs[this.selectedLeague],
    metricType: VideoMetrics.CHANCES,
    minDate: null,
    maxDate: null,
    seasons: [2024],
    periods: [],
    minGameclock: 0,
    minDistance: 0,
    maxDistance: 30,
    maxGameclock: 720,
    minShotclock: 0,
    maxShotclock: 24,
    onCourtEntities: [],
    offCourtEntities: [],
    offensiveLineupEntities: [],
    defensiveLineupEntities: [],
    primaryDefensivePlayer: [],
    teamEntities: [],
    defensiveTeamEntities: [],
    offensiveTeamEntities: [],
    shotZones: [],
    ctgGames: [],
    touchRegions: [],
    touchMinDribbles: 0,
    touchMaxDribbles: 40,
    touchMinDuration: 0,
    touchMaxDuration: 40,
    minQEFG: 0,
    maxQEFG: 1,
    minXEFG: 0,
    maxXEFG: 1,
  }
  readonly playcallIDs = [
    20,  // Mike Wilks
    27,  // Thomas Van de Houten
    32,  // Will Burger
    55,  // Grant Gibbs
    56,  // DA
    58,  // Mark Daigneault
    62,  // Kam Woods
    63,  // Dave Bliss
    91,  // Eric Maynor
    103, // Darnell Foreman
    115, // Zoe Vernon
    120, // Connor Johnson
    121, // DeVon Walker
    128, // Kapil Kashyap
    147, // Chip Engelland
    214, // Nick Viamin
  ]
  displayedFilters: any;
  filtersData: any = {
    subject: VideoSubject.CHANCES,
    filters: [
      {
        'table': VideoTables.CHANCE_DETAILS,
        'name': 'league_id',
        'not': false,
        'args': [1],
      },
    ],
  };
  queryParamFilters: any[] = [
    {name: 'leagueIDs', isMultiple: true},
    {name: 'metricType', isMultiple: false},
    {name: 'seasons', isMultiple: true},
    {name: 'game_type', isMultiple: true},
    {name: 'gameID', isMultiple: false},
    {name: 'playerID', isMultiple: false},
    {name: 'teamID', isMultiple: false},
    {name: 'offTeamID', isMultiple: false},
    {name: 'defTeamID', isMultiple: false},
    {name: 'minDate', isMultiple: false, castTo: moment},
    {name: 'maxDate', isMultiple: false, castTo: moment},
    {name: 'minGame', isMultiple: false},
    {name: 'maxGame', isMultiple: false},
    {name: 'distribution', isMultiple: false},
    {name: 'setup', isMultiple: false},
    {name: 'contestedness', isMultiple: false},
    {name: 'zone', isMultiple: false},
    {name: 'subzone', isMultiple: false},
    {name: 'shotZones', isMultiple: true},
    {name: 'isThree', isMultiple: false},
    {name: 'isMake', isMultiple: false},
    {name: 'foulDrawn', isMultiple: false},
    {name: 'homeAway', isMultiple: false},
    {name: 'chartingCategory', isMultiple: false},
    {name: 'chartingResult', isMultiple: false},
    {name: 'periods', isMultiple: true},
    {name: 'minGameclock', isMultiple: false},
    {name: 'maxGameclock', isMultiple: false},
    {name: 'minShotclock', isMultiple: false},
    {name: 'maxShotclock', isMultiple: false},
    {name: 'onCourtEntityIDs', isMultiple: true},
    {name: 'offCourtEntityIDs', isMultiple: true},
    {name: 'primaryEntityID', isMultiple: false},
    {name: 'secondaryEntityID', isMultiple: false},
    {name: 'teamEntityIDs', isMultiple: true},
    {name: 'ctgGameIDs', isMultiple: true},
    {name: 'touchStartType', isMultiple: false},
    {name: 'touchOutcome', isMultiple: false},
    {name: 'touchRegions', isMultiple: true},
    {name: 'touchPotentialCatchAndShoot', isMultiple: false},
    {name: 'touchMinDribbles', isMultiple: false},
    {name: 'touchMaxDribbles', isMultiple: false},
    {name: 'touchMinDuration', isMultiple: false},
    {name: 'touchMaxDuration', isMultiple: false},
    {name: 'eagleChanceIDs', isMultiple: true},
    {name: 'cuts', isMultiple: false, castTo: (val => val == 'true')},
  ]
  eventTypeOptions: any = [
    {displayName: 'Games (Periods)', value: 'periods', leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Chances', value: VideoSubject.CHANCES, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Touches', value: VideoSubject.TOUCHES, leagues: ['NBA', 'GLG']},
    {displayName: 'Shots', value: VideoSubject.SHOTS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Assists', value: VideoSubject.ASSISTS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Rebounds', value: VideoSubject.REBOUNDS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Steals', value: VideoSubject.STEALS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Blocks', value: VideoSubject.BLOCKS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Turnover', value: VideoSubject.TURNOVERS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Foul', value: VideoSubject.FOULS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Free Throws', value: VideoSubject.FREE_THROWS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Charting', value: VideoMetrics.CHARTING, leagues: ['NBA']},
    {displayName: 'Usage', value: VideoSubject.USAGE, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Points', value: VideoSubject.POINTS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Potential Assist', value: VideoSubject.ASSIST_OPP, leagues: ['NBA', 'GLG']},
    {displayName: 'Possessions + Assists', value: VideoSubject.POSSESSIONS_ASSISTS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Assists + Potential Assists + Turnovers', value: VideoSubject.ASSISTS_ASSIST_OPS_TURNOVERS, leagues: ['NBA', 'GLG']},
    {displayName: 'Blocks + Steals', value: VideoSubject.BLOCKS_STEALS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Assists + Turnovers', value: VideoSubject.ASSISTS_TURNOVERS, leagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {displayName: 'Cuts', value: VideoSubject.CUTS, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Drives', value: VideoSubject.DRIVES, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Handoffs', value: VideoSubject.HANDOFFS, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Isolation', value: VideoSubject.ISOLATION, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Picks', value: VideoSubject.PICKS, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Post Ups', value: VideoSubject.POST_UPS, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Spot Ups', value: VideoSubject.SPOT_UPS, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Transition', value: VideoSubject.TRANSITION, leagues: ['NCAA', 'OTHER']},
    {displayName: 'Transition Offense', value: VideoSubject.TRANSITION_OFFENSE, leagues: ['NBA', 'GLG']},
    {displayName: 'Defense', value: VideoSubject.DEFENSE, leagues: ['NBA']},
    {displayName: 'Offense', value: VideoSubject.OFFENSE, leagues: ['NBA']},
  ];

  defensiveItems = [
    'nbaPlayerIDsDefendingML',
    'nbaPlayerIDsDefendingRO',
    'nbaPlayerIDsDefendingRE',
    'nbaPlayerIDsDefendingKR',
    'nbaPlayerIDsDefendingS',
    'nbaPlayerIDsDefendingTS',
    'nbaPlayerIDsDefendingC',
    'nbaPlayerIDsDefendingF',
    'nbaPlayerIDsExpectedDefendingML',
    'nbaPlayerIDsExpectedDefendingRO',
    'nbaPlayerIDsExpectedDefendingRE',
    'nbaPlayerIDsExpectedDefendingKR',
    'nbaPlayerIDsExpectedDefendingS',
    'nbaPlayerIDsExpectedDefendingTS',
    'nbaPlayerIDsExpectedDefendingC',
    'nbaPlayerIDsExpectedDefendingF',
    'nbaPlayerIDsDefending1',
    'nbaPlayerIDsDefending2',
    'nbaPlayerIDsDefending3',
    'nbaPlayerIDsDefending4',
    'nbaPlayerIDsDefending5',
    'nbaPlayerIDsExpectedDefending1',
    'nbaPlayerIDsExpectedDefending2',
    'nbaPlayerIDsExpectedDefending3',
    'nbaPlayerIDsExpectedDefending4',
    'nbaPlayerIDsExpectedDefending5',
  ]

  subjectToSecondaryFilterName: any = {
    [VideoSubject.SHOTS]: 'assister_id',
    [VideoSubject.ASSISTS]: 'shooter_id',
    [VideoSubject.STEALS]: 'turnoverer_id',
    [VideoSubject.BLOCKS]: 'shooter_id',
    [VideoSubject.TURNOVERS]: 'stealer_id',
    [VideoSubject.FOULS]: 'fouled_id',
  };

  gameclockSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 720,
    step: 1,
    noSwitching: true,
    animate: false,
    translate: (value: number): string => this.gameClockTranslate(value),
  };

  gameClockTranslate(value: number) {
    return Math.floor(value/60) + ':' + ((value % 60) < 10 ? ('0' + (value % 60)) : (value % 60));
  }

  get allEventTypes() {
    return this.eventTypeOptions.map(eventTypeOption => eventTypeOption.value);
  }

  get isShuffled() {
    return this.selectedPlaylist?.isShuffled || this.selectedGeneratedPlaylist?.isShuffled;
  }

  get isSynergyClip() {
    return ['NCAA', 'INTERNATIONAL'].includes(this.currentClip.league);
  }

  get savingDisabled(): boolean {
    return !this.currentClip?.playlists?.length;
  }

  shotclockSliderOptions = {
    showTicks: false,
    floor: 0,
    ceil: 24,
    step: 1,
    noSwitching: true,
    animate: false,
  };

  columns: any[] = [];
  columnsData: any[] = [
    {matColumnDef: 'index', abbrev: '', default: true, optional: false, isSortable: false, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'date', abbrev: 'DATE', default: true, optional: false, isSortable: true, isFilterable: true, showDateFilters: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'offTeam', abbrev: 'OFF', default: true, optional: false, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'defTeam', abbrev: 'DEF', default: true, optional: false, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'period', abbrev: 'QTR', default: true, optional: false, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'startGameclock', abbrev: 'GAMECLOCK', default: true, optional: false, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 720, stepSize: 5, displayAsTime: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'startGameclock', abbrev: 'GAMECLOCK', default: true, optional: false, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 1200, stepSize: 5, displayAsTime: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA', 'OTHER']},
    {matColumnDef: 'description', abbrev: 'DESCRIPTION', default: true, optional: false, isSortable: false, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'shooter-name', abbrev: 'SHOOTER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'assister-pot-assister-name', abbrev: 'AST/POT. AST', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'rebounder-name', abbrev: 'REBOUNDER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'blocker-name', abbrev: 'BLOCKER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'cutter-name', abbrev: 'CUTTER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA']},
    {matColumnDef: 'ballhandler-name', abbrev: 'HANDLER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA']},
    {matColumnDef: 'screener-name', abbrev: 'SCREENER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA']},
    {matColumnDef: 'passer-name', abbrev: 'PASSER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA']},
    {matColumnDef: 'shotClock', abbrev: 'SHOTCLOCK', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 24, stepSize: 1, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'shotClock', abbrev: 'SHOTCLOCK', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 30, stepSize: 1, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA', 'OTHER']},
    {matColumnDef: 'shotZoneIntermediate', abbrev: 'ZONE', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'gameState', abbrev: 'STATE', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'contestednessNumber', abbrev: 'CONTESTEDNESS', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'setup', abbrev: 'SETUP', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'shotDistance', abbrev: 'DIST', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 30, stepSize: 1, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'qefg', abbrev: 'QEFG', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 1, stepSize: .01, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'xefg', abbrev: 'XEFG', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 1, stepSize: .01, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'shotType', abbrev: 'TYPE', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA', 'OTHER']},
    {matColumnDef: 'isMake', abbrev: 'RESULT', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'player-name', abbrev: 'PLAYER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: [VideoSubject.CHARTING], selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'chartingCategory', abbrev: 'CATEGORY', default: true, optional: false, isSortable: true, isFilterable: true, selectedEventTypes: [VideoSubject.CHARTING], selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'chartingResult', abbrev: 'RESULT', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: [VideoSubject.CHARTING], selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'actionName', abbrev: 'ACTION', default: true, optional: false, isSortable: true, isFilterable: true, showCheckboxes: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA']},
    {matColumnDef: 'description', abbrev: 'DESCRIPTION', default: true, optional: false, isSortable: false, isFilterable: true, showSearch: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA', 'OTHER']},
    {matColumnDef: 'emptySpacing', abbrev: '', default: true, optional: false, isSortable: false, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'save', abbrev: '', default: true, optional: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'select', abbrev: '', default: true, optional: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
  ];
  playlistColumnsData: any[] = [
    {matColumnDef: 'index', abbrev: '', default: true, optional: false, isSortable: false, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'date', abbrev: 'DATE', default: true, optional: false, isSortable: true, isFilterable: true, showDateFilters: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'offTeam', abbrev: 'OFF', default: true, optional: false, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'defTeam', abbrev: 'DEF', default: true, optional: false, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'period', abbrev: 'QTR', default: true, optional: false, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'startGameclock', abbrev: 'GAMECLOCK', default: true, optional: false, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 1200, stepSize: 5, displayAsTime: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA', 'OTHER']},
    {matColumnDef: 'shooter-name', abbrev: 'SHOOTER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'assister-pot-assister-name', abbrev: 'AST/POT. AST', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'rebounder-name', abbrev: 'REBOUNDER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'blocker-name', abbrev: 'BLOCKER', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'shotClock', abbrev: 'SHOTCLOCK', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 30, stepSize: 1, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA', 'OTHER']},
    {matColumnDef: 'shotZoneIntermediate', abbrev: 'ZONE', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'gameState', abbrev: 'STATE', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'contestednessNumber', abbrev: 'CONTESTEDNESS', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'setup', abbrev: 'SETUP', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'shotDistance', abbrev: 'DIST', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 30, stepSize: 1, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'qefg', abbrev: 'QEFG', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 1, stepSize: .01, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'xefg', abbrev: 'XEFG', default: true, optional: true, isSortable: true, isFilterable: true, showSlider: true, minValue: 0, maxValue: 1, stepSize: .01, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'shotType', abbrev: 'TYPE', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA', 'OTHER']},
    {matColumnDef: 'isMake', abbrev: 'RESULT', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'actionName', abbrev: 'ACTION', default: true, optional: false, isSortable: true, isFilterable: true, showCheckboxes: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NCAA']},
    {matColumnDef: 'saved', abbrev: 'SAVED', default: true, optional: false, isSortable: true, isFilterable: true, showDateFilters: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'name', abbrev: 'NAME', default: true, optional: false, isSortable: true, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    //{matColumnDef: 'taggedPlayers', abbrev: 'TAGGED PLAYERS', default: true, optional: true, isSortable: true, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'tags', abbrev: 'TAGS', default: true, optional: true, isSortable: false, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'isUploaded', abbrev: '', default: true, optional: true, isSortable: true, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'label', abbrev: 'LABEL', default: true, optional: true, isSortable: true, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG'], checkboxOptions: ['Yellow', 'Red', 'Green', 'Blue']},
    {matColumnDef: 'sharing', abbrev: 'SHARING', default: true, optional: true, isSortable: true, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG']},
    {matColumnDef: 'emptySpacing', default: true, optional: false, isSortable: false, isFilterable: false, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'notes', abbrev: 'NOTES', default: true, optional: true, isSortable: true, isFilterable: true, showSearch: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
    {matColumnDef: 'select', default: true, optional: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA', 'GLG', 'NCAA', 'OTHER']},
  ];
  playcallColumns = [
    {matColumnDef: 'playcall', abbrev: 'PLAYCALL', default: true, optional: false, isSortable: false, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA']},
    {matColumnDef: 'commentary', abbrev: 'COMMENTARY', default: true, optional: false, isSortable: false, isFilterable: true, selectedEventTypes: this.allEventTypes, selectedLeagues: ['NBA']},
  ];
  currentClipMetadataAttributes: any[] = [
    {label: 'Shooter', nbaKey: 'nbaShooterID', synergyKey: 'synergyShooterID'},
    {label: 'Assister', nbaKey: 'nbaAssisterID', synergyKey: 'synergyAssisterID'},
    {label: 'Potential Assister', nbaKey: 'nbaPotentialAssisterID'},
    {label: 'Rebounder', nbaKey: 'nbaRebounderID', synergyKey: 'synergyRebounderID'},
    {label: 'Blocker', nbaKey: 'nbaBlockerID', synergyKey: 'synergyBlockerID'},
    {label: 'Eagle Chance ID', synergyLabel: 'Synergy Event ID', nbaKey: 'eagleChanceID', synergyKey: 'synergyEventID', isChance: true},
  ]

  public positions = [
    new ConnectionPositionPair({
      originX: 'end',
      originY: 'top'},
    {
      overlayX: 'end',
      overlayY: 'bottom'},
    0,
    0),
  ];

  @Output() onCloseDialog: EventEmitter<any> = new EventEmitter();

  @HostListener('window:keydown', ['$event'])
  handleKeyboardDownEvent(event) {
    if (event.key === 'Escape') {
      this.isClosing = true;
      this.updatePlaylistQueryParam(null);
    } else if (event.target.classList?.contains('mat-input-element')) {
      return;
    } else if (['1', '2', '3', '4', '5', '6'].includes(event.key)) {
      this.quickSaveVideoToPlaylist(+event.key);
    } else if (event.key == 'g') {
      if (!this.isSaving) {
        if (this.currentClip?.id && this.currentClip.author.userID == this.user.id) {
          this.editVideo(this.currentClip);
        } else if (!this.currentClip?.id) {
          this.saveVideo();
        }
      }
    } else if (event.key == 'x') {
      this.currentClip.isSelected = !this.currentClip.isSelected;
      this.videoClipTable.updateSelectedClips(this.currentClip);
    } else if (event.key == ' ') {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  constructor(
    protected actions$: Actions,
    protected authService: AuthService,
    protected autocompleteService: AutocompleteService,
    protected breakpointObserver: BreakpointObserver,
    protected cdr: ChangeDetectorRef,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<BrowseVideoComponent>,
    protected entitiesService: EntitiesService,
    protected matDialog: MatDialog,
    private clipboard: Clipboard,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    protected snackBar: MatSnackBar,
    protected store$: Store<RootStoreState.State>,
    protected title: Title,
    protected videoHelper: VideoHelper,
    protected videoService: VideoService,
    private elRef: ElementRef,
    @Inject(DOCUMENT) private document: any,
  ) { }
  get currentVideoNumber() {
    return this.currentVideoIndex % 2 == 0 ? 1 : 2;
  }

  get nextVideoNumber() {
    return this.currentVideoIndex % 2 == 0 ? 2 : 1;
  }

  get video() {
    return this.currentVideoNumber == 1 ? this.videoPlayer.video1 : this.videoPlayer.video2;
  }

  get nextVideo() {
    return this.currentVideoNumber == 1 ? this.videoPlayer.video2 : this.videoPlayer.video1;
  }

  get currentClip() {
    return this.currentVideoNumber == 1 ? this.currentClip1 : this.currentClip2;
  }

  set currentClip(val) {
    if (this.currentVideoNumber == 1) {
      this.currentClip1 = val;
    }
    else {
      this.currentClip2 = val;
    }
  }

  get nextClip() {
    return this.currentVideoNumber == 1 ? this.currentClip2: this.currentClip1;
  }

  set nextClip(val) {
    if (this.currentVideoNumber == 1) {
      this.currentClip2 = val;
    }
    else {
      this.currentClip1 = val;
    }
  }

  get currentVideoTime() {
    return this.currentVideoNumber == 1 ? this.currentVideoTime1: this.currentVideoTime2;
  }

  set currentVideoTime(val) {
    if (this.currentVideoNumber == 1) {
      this.currentVideoTime1 = val;
    }
    else {
      this.currentVideoTime2 = val;
    }
  }

  get nextVideoTime() {
    return this.currentVideoNumber == 1 ? this.currentVideoTime2: this.currentVideoTime1;
  }

  set nextVideoTime(val) {
    if (this.currentVideoNumber == 1) {
      this.currentVideoTime2 = val;
    }
    else {
      this.currentVideoTime1 = val;
    }
  }

  ngOnInit() {
    this.title.setTitle('Explore Video');

    const layoutChanges = this.breakpointObserver.observe([
      Breakpoints.XSmall, Breakpoints.Small
    ]);

    layoutChanges.pipe(untilDestroyed(this)).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.authService.currentUserData.pipe(take(1), untilDestroyed(this)).subscribe((user: User) => {
      this.user = user;
    });

    if (this.playcallIDs.includes(this.user.id)) {
      const tagsIndex = this.playlistColumnsData.findIndex(column => column.matColumnDef === 'tags');
      const resIndex = this.columnsData.findIndex(column => column.matColumnDef === 'chartingResult');
      if (tagsIndex !== -1) {
        this.playlistColumnsData.splice(tagsIndex + 1, 0, ...this.playcallColumns);
      }
      if (resIndex !== -1) {
        this.columnsData.splice(resIndex + 1, 0, ...this.playcallColumns);
      }
    }

    this.displayedFilters = _.cloneDeep(this.defaultDisplayedFilters);

    this.entitiesService.getEntities('League').pipe(untilDestroyed(this), take(1)).subscribe((leagues: League[]) => {
      this.entityNBA = leagues.find(league => league.id == 41);
      this.entityGLG = leagues.find(league => league.id == 44);
      this.entityNCAA = leagues.find(league => league.id == 42);
    });

    this.videoService.activeFilterSubject.pipe(untilDestroyed(this)).subscribe((activeFilter) => {
      if (activeFilter) {
        this.modifyGeneratedPlaylistFilters();
      }
    });

    if (this.displayedFilters.minDistance == null) {
      this.displayedFilters.minDistance = 0;
      this.displayedFilters.maxDistance = 30;
    }

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
      if (!_.isEqual(queryParams, this.queryParams)) {
        this.queryParams = queryParams;
        this.setupSelectedPlaylists();
      }
    });

    this.initializeFiltersFromQueryParams();

    this.editorsControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterEditors(q);
          this.filterFolderEditors(q)
        }
      );

    this.viewersControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterViewers(q);
          this.filterFolderViewers(q);
        }
      );

    this.entitiesControl.valueChanges.pipe(
      debounceTime(environment.typingDebounceTime),
      untilDestroyed(this), )
      .subscribe(
        q => {
          this.filterEntities(q);
        }
      );

    this.store$.pipe(
      select(VideoStoreSelectors.selectChannel),
      untilDestroyed(this)
    ).subscribe(channel => {
      this.channelName = channel.channelName;
      this.cdr.markForCheck();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectSession),
      filter(session => session?.isLoaded),
      untilDestroyed(this),
      take(1),
    ).subscribe(session => {
      this.sessionMetadata = _.cloneDeep(session);
      this.setupSelectedPlaylists();
      this.cdr.markForCheck();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectPlaylists),
      filter(playlists => playlists?.isLoaded),
      untilDestroyed(this),
      take(1),
    ).subscribe(playlists => {
      this.userPlaylists = _.cloneDeep(playlists.playlists);
      this.filterPlaylistsByName();
      this.setupSelectedPlaylists();
      this.cdr.markForCheck();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectFolders),
      filter(folders => folders?.isLoaded),
      untilDestroyed(this),
      take(1),
    ).subscribe(folders => {
      this.userFolders = _.cloneDeep(folders.folders);
      this.filterFoldersByName();
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.SaveFolder>(actions.ActionTypes.SAVE_FOLDER),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let existingFolderIndex = this.userFolders.findIndex(folder => folder.id == payload.id);
      if (existingFolderIndex != -1) {
        this.userFolders[existingFolderIndex] = {...this.userFolders[existingFolderIndex], ...payload};
        this.updateFolders(_.cloneDeep(this.userFolders));
      } else {
        this.userFolders.unshift(payload);
        this.updateFolders(_.cloneDeep(this.userFolders));
      }
      this.cdr.markForCheck();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectGeneratedPlaylists),
      filter(generatedPlaylists => generatedPlaylists?.isLoaded),
      untilDestroyed(this),
      take(1),
    ).subscribe(generatedPlaylists => {
      this.userGeneratedPlaylists = _.cloneDeep(generatedPlaylists.generatedPlaylists);
      this.userGeneratedPlaylists.forEach((gp) => {
        this.initializeGeneratedFilters(gp);
      });
      this.setupSelectedPlaylists();
      this.cdr.markForCheck();
    });

    this.store$.pipe(
      select(VideoStoreSelectors.selectSavedVideosLookup),
      filter(savedVideosLookup => savedVideosLookup?.isLoaded),
      untilDestroyed(this),
    ).subscribe(savedVideosLookup => {
      this.savedVideosLookup = savedVideosLookup;
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.SaveAction>(actions.ActionTypes.SAVE_ACTION),
      untilDestroyed(this),
    ).subscribe(({payload}) => {
      this.cdr.markForCheck();
      if (payload[VideoActionTypes.NAVIGATION_CHANGE]) {
        if (!this.sessionMetadata) this.sessionMetadata = {};
        this.sessionMetadata.currentNavigation = payload[VideoActionTypes.NAVIGATION_CHANGE];
        if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.GENERATE) {
          this.isGeneratingPlaylist = true;
          this.displayedClipsLoaded = false;
          setTimeout(() => {
            this.generatePlaylistComponent.resetFilters(true);
          }, 50);
        } else {
          this.isGeneratingPlaylist = false;
          if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.EXPLORE) {
            if (this.sessionMetadata.currentExploreType == VideoExploreTypes.PLAYLIST && !this.awaitingGeneratedPlaylistCreation) {
              let playlist = this.userPlaylists.find(userPlaylist => userPlaylist.id == this.sessionMetadata.explorePlaylistID);
              this.clickPlaylist(playlist);
            } else if (!this.initialGeneratedPlaylistFilters && !this.awaitingGeneratedPlaylistCreation) {
              let generatedPlaylist = this.userGeneratedPlaylists.find(userGeneratedPlaylist => userGeneratedPlaylist.id == this.sessionMetadata.generatedPlaylistID);
              this.selectedGeneratedPlaylist = _.cloneDeep(generatedPlaylist);
              this.initializeSessionState();
              this.initializeGeneratedFilters(generatedPlaylist);
              if (!this.clips?.length && this.selectedGeneratedPlaylist?.clips?.length) {
                this.clips = this.selectedGeneratedPlaylist.clips;
                this.unfilteredClips = _.cloneDeep(this.clips);
              }
              this.updateDisplayedClips(!this.selectedGeneratedPlaylist?.clips);
              if (!this.selectedGeneratedPlaylist?.clips) {
                this.chainID = this.videoService.generateChainID();
                this.store$.dispatch(new actions.SaveAction({'generatedPlaylistOpened': {'id': this.selectedGeneratedPlaylist.id}, 'chainID': this.chainID}));
              }
            }
          } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_1) {
            this.initializePlaylist(1);
          } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_2) {
            this.initializePlaylist(2);
          } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_3) {
            this.initializePlaylist(3);
          } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_4) {
            this.initializePlaylist(4);
          } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_5) {
            this.initializePlaylist(5);
          } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_6) {
            this.initializePlaylist(6);
          }
        }
        this.cdr.markForCheck();
      }
      if (payload[VideoActionTypes.EXPLORE_TYPE_CHANGE]) {
        if (!this.sessionMetadata) this.sessionMetadata = {};
        this.sessionMetadata.currentExploreType = payload[VideoActionTypes.EXPLORE_TYPE_CHANGE];
        if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.EXPLORE) {
          if (this.sessionMetadata.currentExploreType == VideoExploreTypes.PLAYLIST) {
            let playlist = this.userPlaylists.find(userPlaylist => userPlaylist.id == this.sessionMetadata.explorePlaylistID);
            this.clickPlaylist(playlist);
          }
          else {
            let generatedPlaylist = this.userGeneratedPlaylists.find(userGeneratedPlaylist => userGeneratedPlaylist.id == this.sessionMetadata.generatedPlaylistID);
            this.selectedGeneratedPlaylist = _.cloneDeep(generatedPlaylist);
            this.updateDisplayedClips();
          }
        }
        this.cdr.markForCheck();
      }
      if (payload[VideoActionTypes.EXPLORE_PLAYLIST_CHANGE]) {
        this.currentVideoIndex = 0;
        this.sessionMetadata.exploreClipIndex = 0;
        this.store$.dispatch(new actions.SaveAction({'clipIndexChange': {'currentNavigation': VideoNavigationTypes.EXPLORE, 'currentIndex': 0}, 'chainID': this.chainID}));
        this.sessionMetadata.explorePlaylistID = payload[VideoActionTypes.EXPLORE_PLAYLIST_CHANGE]['id'];
        if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.EXPLORE) {
          if (this.sessionMetadata.currentExploreType == VideoExploreTypes.PLAYLIST) {
            let playlist = this.userPlaylists.find(userPlaylist => userPlaylist.id == this.sessionMetadata.explorePlaylistID);
            this.clickPlaylist(playlist);
          }
        }
        this.cdr.markForCheck();
      }
      if (payload[VideoActionTypes.EXPLORE_GENERATED_PLAYLIST_CHANGE]) {
        this.currentVideoIndex = 0;
        this.sessionMetadata.exploreClipIndex = 0;
        this.store$.dispatch(new actions.SaveAction({'clipIndexChange': {'currentNavigation': VideoNavigationTypes.EXPLORE, 'currentIndex': 0}, 'chainID': this.chainID}));
        this.sessionMetadata.generatedPlaylistID = payload[VideoActionTypes.EXPLORE_GENERATED_PLAYLIST_CHANGE]['id'];
        if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.EXPLORE) {
          if (this.sessionMetadata.currentExploreType == VideoExploreTypes.GENERATED_PLAYLIST) {
            let generatedPlaylist = this.userGeneratedPlaylists.find(userGeneratedPlaylist => userGeneratedPlaylist.id == this.sessionMetadata.generatedPlaylistID);
            this.selectedGeneratedPlaylist = _.cloneDeep(generatedPlaylist);
            this.selectedGeneratedPlaylist.isShuffled = false;
            this.updateDisplayedClips(!this.selectedGeneratedPlaylist.clips);
          }
        }
        this.cdr.markForCheck();
      }
      if (payload[VideoActionTypes.CLIP_INDEX_CHANGE]) {
        this.sessionMetadata[`${payload[VideoActionTypes.CLIP_INDEX_CHANGE]['currentNavigation'].toLowerCase().replace('_', '')}ClipIndex`] = payload[VideoActionTypes.CLIP_INDEX_CHANGE]['currentIndex'];
      }
      if (payload[VideoActionTypes.NOTES_SHOWN_CHANGE]) {
        this.sessionMetadata[`${payload[VideoActionTypes.NOTES_SHOWN_CHANGE]['currentNavigation'].toLowerCase().replace('_', '')}IsNotesShown`] = payload[VideoActionTypes.NOTES_SHOWN_CHANGE]['notesShown'];
      }
      if (payload[VideoActionTypes.IS_SHUFFLED_CHANGE]) {
        this.sessionMetadata[`${payload[VideoActionTypes.IS_SHUFFLED_CHANGE]['currentNavigation'].toLowerCase().replace('_', '')}IsShuffled`] = payload[VideoActionTypes.IS_SHUFFLED_CHANGE]['isShuffled'];
      }
      if (payload[VideoActionTypes.SORT_COLUMN_CHANGE]) {
        this.sessionMetadata[`${payload[VideoActionTypes.SORT_COLUMN_CHANGE]['currentNavigation'].toLowerCase().replace('_', '')}SortColumn`] = payload[VideoActionTypes.SORT_COLUMN_CHANGE]['sortColumn'];
      }
      if (payload[VideoActionTypes.SORT_DIRECTION_CHANGE]) {
        this.sessionMetadata[`${payload[VideoActionTypes.SORT_DIRECTION_CHANGE]['currentNavigation'].toLowerCase().replace('_', '')}SortDirection`] = payload[VideoActionTypes.SORT_DIRECTION_CHANGE]['sortDirection'];
      }
    });

    this.actions$.pipe(
      ofType<actions.UpdatePlaylist>(actions.ActionTypes.UPDATE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload}) => {
      const playlist = this.userPlaylists.find((userPlaylist) => userPlaylist.id == payload.id);
      if (playlist) {
        playlist.clips = _.cloneDeep(payload.clips);
        if (this.selectedPlaylist?.id && this.selectedPlaylist.id == payload.id) {
          this.selectedPlaylist = playlist;
          this.clips = this.selectedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.resetTableState();
          this.initializeSessionState();
          this.updateDisplayedClips();
        }
      }
    });

    this.actions$.pipe(
      ofType<actions.SavePlaylist>(actions.ActionTypes.SAVE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      let existingPlaylistIndex = this.userPlaylists.findIndex(userPlaylist => userPlaylist.id == payload.id);
      if (existingPlaylistIndex != -1) {
        this.userPlaylists[existingPlaylistIndex] = {...this.userPlaylists[existingPlaylistIndex], ...payload};
        ['', '1', '2', '3', '4', '5', '6'].forEach((index) => {
          if (payload.id === this['selectedPlaylist' + index]?.id) {
            this['selectedPlaylist' + index] = _.cloneDeep(this.userPlaylists[existingPlaylistIndex]);
          }
        });
      }
      else {
        this.userPlaylists.unshift(_.cloneDeep(payload));
        if (this.chainID == chainID && !this.isSaving && !this.isSavingMany) {
          this.clickPlaylist(payload);
        }
      }
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.DeletePlaylist>(actions.ActionTypes.DELETE_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.userPlaylists = this.userPlaylists.filter((playlist) => playlist.id != payload);
      if (this.selectedPlaylist?.id == payload) {
        if ([this.selectedPlaylist1?.id, this.selectedPlaylist2?.id, this.selectedPlaylist3?.id, this.selectedPlaylist4?.id,
          this.selectedPlaylist5?.id, this.selectedPlaylist6?.id].includes(payload)) {
          this.switchToExploreView();
        } else {
          this.clickPlaylist(this.userPlaylists[0]);
          this.videoService.selectedPlaylistSubject.next(this.userPlaylists[0]);
          this.store$.dispatch(new actions.SaveAction({'explorePlaylistChange': {id: this.selectedPlaylist?.id, 'chainID': this.videoService.generateChainID()}}));
        }
      }
      [1, 2, 3, 4, 5, 6].forEach((hotkeyPlaylistIndex) => {
        if (this['selectedPlaylist' + hotkeyPlaylistIndex]?.id == payload) {
          this['selectedPlaylist' + hotkeyPlaylistIndex] = null;
          this.sessionMetadata[`playlist${hotkeyPlaylistIndex}ID`] = null;
          this.store$.dispatch(new actions.SaveAction({[`playlist${hotkeyPlaylistIndex}Change`]: {'id': null}}));
        }
      });
    });

    this.actions$.pipe(
      ofType<actions.SaveVideo>(actions.ActionTypes.SAVE_VIDEO),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.userPlaylists.forEach(playlist => {
        if (playlist.clips) {
          if (playlist.clips.find(clip => clip.id == payload.id)) {
            if (!payload.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
              playlist.clips = playlist.clips.filter(clip => clip.id != payload.id);
            } else {
              playlist.clips[playlist.clips.findIndex(clip => clip.id == payload.id)] = payload;
            }
          } else if (payload.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
            playlist.clips.push(payload);
          }
        }
        if (playlist.id === this.selectedPlaylist?.id) {
          this.clips = this.selectedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        }
      });
      this.cdr.markForCheck();
    });

    this.actions$.pipe(
      ofType<actions.DeletePlaylist>(actions.ActionTypes.DELETE_VIDEO),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.userPlaylists.forEach(playlist => {
        if (playlist.clips) {
          playlist.clips = playlist.clips.filter(clip => clip.id != payload.id);
        }
      });
      if (this.clips.find(clip => clip.id == payload.id)) {
        this.clips = this.clips.filter(clip => clip.id != payload.id);
        this.unfilteredClips = _.cloneDeep(this.clips);
        this.updateDisplayedClips();
      }
    });

    this.actions$.pipe(
      ofType<actions.BulkSaveVideos>(actions.ActionTypes.BULK_SAVE_VIDEOS),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.userPlaylists.forEach(playlist => {
        let playlistUpdated = false;
        payload.forEach(savedVideo => {
          if (playlist.clips) {
            if (playlist.clips.find(clip => clip.id == savedVideo.id)) {
              if (!savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
                playlist.clips = playlist.clips.filter(clip => clip.id != savedVideo.id);
                playlistUpdated = true;
              }
              else {
                playlist.clips[playlist.clips.findIndex(clip => clip.id == savedVideo.id)] = savedVideo;
                playlistUpdated = true;
              }
            }
            else if (savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
              playlist.clips.push(savedVideo);
              playlistUpdated = true;
            }
          }
        });
        if (playlist.id === this.selectedPlaylist?.id && playlistUpdated) {
          this.clips = this.selectedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        }
      });
    });

    this.actions$.pipe(
      ofType<actions.BulkRemoveVideos>(actions.ActionTypes.BULK_REMOVE_VIDEOS),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.userPlaylists.forEach(playlist => {
        payload.forEach(savedVideo => {
          if (playlist.clips) {
            if (playlist.clips.find(clip => clip.id == savedVideo.id)) {
              if (!savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
                playlist.clips = playlist.clips.filter(clip => clip.id != savedVideo.id);
              }
              else {
                playlist.clips[playlist.clips.findIndex(clip => clip.id == savedVideo.id)] = savedVideo;
              }
            }
            else if (savedVideo.playlists.find(clipPlaylist => clipPlaylist.id == playlist.id)) {
              playlist.clips.push(savedVideo);
            }
          }
        });
      });
    });

    this.actions$.pipe(
      ofType<actions.BulkDeleteVideos>(actions.ActionTypes.BULK_DELETE_VIDEOS),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.userPlaylists.forEach(playlist => {
        payload.forEach(savedVideo => {
          if (playlist.clips) {
            if (playlist.clips.find(clip => clip.id == savedVideo.id)) {
              playlist.clips = playlist.clips.filter(clip => clip.id != savedVideo.id);
            }
          }
        });
      });
    });

    this.actions$.pipe(
      ofType<actions.SaveVideoNotes>(actions.ActionTypes.SAVE_VIDEO_NOTES),
      untilDestroyed(this),
    ).subscribe(({payload, channelName}) => {
      this.userPlaylists.forEach(playlist => {
        if (playlist.clips) {
          if (playlist.clips.find(clip => clip.id == payload.id)) {
            playlist.clips[playlist.clips.findIndex(clip => clip.id == payload.id)].notes = _.cloneDeep(payload.notes);
          }
        }
        if (playlist.id === this.selectedPlaylist?.id && channelName != this.channelName) {
          this.clips = this.selectedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        }
      });
    });

    this.actions$.pipe(
      ofType<actions.SaveTag>(actions.ActionTypes.SAVE_TAG),
      untilDestroyed(this),
    ).subscribe(({payload}) => {
      this.userPlaylists.forEach(playlist => {
        if (playlist.clips) {
          if (playlist.clips.find(clip => ((clip.eagleChanceID && clip.eagleChanceID == payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == payload.synergyEventID)))) {
            let tagClip = playlist.clips[playlist.clips.findIndex(clip => ((clip.eagleChanceID && clip.eagleChanceID == payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == payload.synergyEventID)))];
            if (tagClip.tags.find(tag => tag.id == payload.id)) {
              tagClip.tags[tagClip.tags.findIndex(tag => tag.id == payload.id)] = payload;
            }
            else {
              tagClip.tags.push(payload);
            }
          }
        }
        if (playlist.id === this.selectedPlaylist?.id) {
          this.clips = this.selectedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        }
      });
      this.userGeneratedPlaylists.forEach(generatedPlaylist => {
        if (generatedPlaylist.clips) {
          if (generatedPlaylist.clips.find(clip => ((clip.eagleChanceID && clip.eagleChanceID == payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == payload.synergyEventID)))) {
            let tagClip = generatedPlaylist.clips[generatedPlaylist.clips.findIndex(clip => ((clip.eagleChanceID && clip.eagleChanceID == payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == payload.synergyEventID)))];
            if (tagClip.tags.find(tag => tag.id == payload.id)) {
              tagClip.tags[tagClip.tags.findIndex(tag => tag.id == payload.id)] = payload;
            }
            else {
              tagClip.tags.push(payload);
            }
          }
        }
        if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
          if (this.selectedGeneratedPlaylist.clips) {
            if (this.selectedGeneratedPlaylist.clips.find(clip => ((clip.eagleChanceID && clip.eagleChanceID == payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == payload.synergyEventID)))) {
              let tagClip = this.selectedGeneratedPlaylist.clips[this.selectedGeneratedPlaylist.clips.findIndex(clip => ((clip.eagleChanceID && clip.eagleChanceID == payload.eagleChanceID) || (clip.synergyEventID && clip.synergyEventID == payload.synergyEventID)))];
              if (tagClip.tags.find(tag => tag.id == payload.id)) {
                tagClip.tags[tagClip.tags.findIndex(tag => tag.id == payload.id)] = payload;
              }
              else {
                tagClip.tags.push(payload);
              }
            }
          }
          this.clips = this.selectedGeneratedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        }
      });
    });

    this.actions$.pipe(
      ofType<actions.DeleteTag>(actions.ActionTypes.DELETE_TAG),
      untilDestroyed(this),
    ).subscribe(({payload}) => {
      this.userPlaylists.forEach(playlist => {
        if (playlist.clips) {
          playlist.clips.forEach(clip => {
            if (clip.tags) {
              clip.tags = clip.tags.filter(tag => tag.id != payload.id);
            }
          });
        }
        if (playlist.id === this.selectedPlaylist?.id) {
          this.clips = this.selectedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        }
      });
      this.userGeneratedPlaylists.forEach(generatedPlaylist => {
        if (generatedPlaylist.clips) {
          generatedPlaylist.clips.forEach(clip => {
            if (clip.tags) {
              clip.tags = clip.tags.filter(tag => tag.id != payload.id);
            }
          });
        }
        if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
          if (this.selectedGeneratedPlaylist.clips) {
            this.selectedGeneratedPlaylist.clips.forEach(clip => {
              if (clip.tags) {
                clip.tags = clip.tags.filter(tag => tag.id != payload.id);
              }
            });
          }
          this.clips = this.selectedGeneratedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        }
      });
    });

    this.actions$.pipe(
      ofType<actions.CreateGeneratedPlaylist>(actions.ActionTypes.CREATE_GENERATED_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      const isInitialLoad = !this.selectedGeneratedPlaylist && !this.selectedPlaylist;
      let createdGeneratedPlaylist = _.cloneDeep(payload);
      if (!this.userGeneratedPlaylists) {
        this.userGeneratedPlaylists = [];
      }
      this.userGeneratedPlaylists.unshift(createdGeneratedPlaylist);
      if (!this.isSaving && !this.isSavingMany) {
        this.clickGeneratedPlaylist(createdGeneratedPlaylist);
      }
      this.initialGeneratedPlaylistFilters = null;
      this.awaitingGeneratedPlaylistCreation = false;
      if (this.sessionMetadata.currentExploreType !== VideoExploreTypes.GENERATED_PLAYLIST) {
        this.store$.dispatch(new actions.SaveAction({'exploreTypeChange': VideoExploreTypes.GENERATED_PLAYLIST, 'chainID': this.videoService.generateChainID()}));
      }
      this.store$.dispatch(new actions.SaveAction({'exploreGeneratedPlaylistChange': {id: createdGeneratedPlaylist.id, 'chainID': this.videoService.generateChainID()}}));
      if (this.sessionMetadata.currentNavigation != VideoNavigationTypes.EXPLORE || isInitialLoad) {
        this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE, 'chainID': this.videoService.generateChainID()}));
      }
    });

    this.actions$.pipe(
      ofType<actions.UpdateGeneratedPlaylist>(actions.ActionTypes.UPDATE_GENERATED_PLAYLIST),
      untilDestroyed(this),
    ).subscribe(({ payload }) => {
      const { id, clips } = payload;
      let generatedPlaylist;
      if (this.userGeneratedPlaylists?.length > 0) {
        generatedPlaylist = this.userGeneratedPlaylists.find(userGeneratedPlaylist => userGeneratedPlaylist.id === id);
        if (generatedPlaylist) {
          generatedPlaylist.clips = _.cloneDeep(clips);
        }
      } else {
        // This occurs when we haven't yet loaded the generated playlist metadata
        this.selectedGeneratedPlaylist = payload;
        this.clickGeneratedPlaylist(this.selectedGeneratedPlaylist);
        this.initializeGeneratedFilters(this.selectedGeneratedPlaylist);
      }
      if (this.selectedGeneratedPlaylist?.id == payload.id) {
        this.selectedGeneratedPlaylist = _.cloneDeep(generatedPlaylist);
        if (this.selectedGeneratedPlaylist) {
          this.clips = this.selectedGeneratedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.initializeSessionState();
          this.updateDisplayedClips();
        }
      }
    });

    this.videoService.videoDialogOpenedSubject.pipe(
      untilDestroyed(this),
    ).subscribe((videoDialogOpened) => {
      if (videoDialogOpened == false) {
        this.isClosing = true;
        this.updatePlaylistQueryParam(null);
      }
    });

    this.videoService.videoTagLookupSubject.pipe(
      untilDestroyed(this)
    ).subscribe((tagDefinitions) => {
        this.tagDefinitions = tagDefinitions;
    });

    this.videoService.folderSource.pipe(
      untilDestroyed(this)
    ).subscribe((folderReceived) => {
        this.folderReceived = folderReceived;
        this.editFolder();
    });

    this.videoService.folderPath.pipe(
      untilDestroyed(this)
    ).subscribe((folderNameReceived) => {
        this.folderPath = folderNameReceived;
    });

    this.actions$.pipe(
      ofType<actions.VideoPositionChange>(actions.ActionTypes.VIDEO_POSITION_CHANGE),
      untilDestroyed(this),
    ).subscribe(({payload, chainID}) => {
      this.reorderClips(payload);
    });
  }

  ngAfterViewInit(): void {
    const playlistDetailContainer = this.elRef.nativeElement.querySelector('.playlist-detail-container');
    const tagPanel = this.elRef.nativeElement.querySelector('.tag-panel.expandable-panel');
    if (playlistDetailContainer && tagPanel) {
      this.resizeObserver = new ResizeObserver(() => {
        tagPanel.style.width = `${playlistDetailContainer.offsetWidth - 20}px`;
      });
      this.resizeObserver.observe(playlistDetailContainer);
    }
  }

  initializeFiltersFromQueryParams() {
    this.queryParamFilters.forEach(queryParamFilter => {
      if (queryParamFilter.isMultiple) {
        if (this.route.snapshot.queryParamMap.getAll(queryParamFilter.name).length > 0) {
          this.displayedFilters[queryParamFilter.name] = this.route.snapshot.queryParamMap.getAll(queryParamFilter.name);
        }
      } else {
        if (this.route.snapshot.queryParamMap.get(queryParamFilter.name)) {
          if (queryParamFilter.castTo) {
            this.displayedFilters[queryParamFilter.name] = queryParamFilter.castTo(this.route.snapshot.queryParamMap.get(queryParamFilter.name));
          } else {
            this.displayedFilters[queryParamFilter.name] = this.route.snapshot.queryParamMap.get(queryParamFilter.name);
          }
        }
      }
    });

    if (this.displayedFilters.eagleChanceIDs) {
      this.displayedFilters.eagleChanceIDsString = this.displayedFilters.eagleChanceIDs.join(',');
    }

    this.initializeSelectedLeague();

    this.gameclockSliderOptions.ceil = ['NCAA', 'OTHER'].includes(this.selectedLeague) ? 1200 : 720;
    this.shotclockSliderOptions.ceil = ['NCAA', 'OTHER'].includes(this.selectedLeague) ? 30 : 24;

    this.updateDisplayedColumns();
    this.cdr.markForCheck();
  }

  initializeSelectedLeague() {
    if (this.displayedFilters?.leagueIDs?.includes('1') || this.displayedFilters?.leagueIDs?.includes(1)) {
      this.selectedLeague = 'NBA';
    } else if (this.displayedFilters?.leagueIDs?.includes('2')|| this.displayedFilters?.leagueIDs?.includes(2)) {
      this.selectedLeague = 'GLG';
    } else if (this.displayedFilters?.leagueIDs?.includes('101')|| this.displayedFilters?.leagueIDs?.includes(101)){
      this.selectedLeague = 'NCAA';
    } else {
      this.selectedLeague = 'OTHER';
    }
  }

  initializeHotkeyPlaylists() {
    const indices = [1, 2, 3, 4, 5, 6];

    indices.forEach((index) => {
      this[`selectedPlaylist${index}`] = this.sessionMetadata[`playlist${index}ID`] ? this.userPlaylists.find((userPlaylist) => userPlaylist.id == this.sessionMetadata[`playlist${index}ID`]) : null;
    });
    this.isHotkeysIntialized = true;
    this.cdr.markForCheck();
  }

  getPlaylistFromQueryParam() {
    return this.userPlaylists.find((userPlaylist) => userPlaylist.id == (this.route.snapshot.queryParams?.playlistID || this.initialPlaylistId));
  }

  setupPlaylistFromQueryParam() {
    const playlist = this.getPlaylistFromQueryParam();
    this.clickPlaylist(playlist);
    this.initialPlaylistId = null;
    this.videoService.selectedPlaylistSubject.next(playlist);
    this.switchToPlaylistView();
  }

  setupGeneratedFiltersFromQueryParams() {
    const filtersData = this.initialGeneratedPlaylistFilters || this.route.snapshot.queryParams?.generatedPlaylistFilters;
    const filters = {filtersData: JSON.parse(filtersData)};
    this.updateGeneratedPlaylist(filters);
  }

  handleInitialExploreState() {
    if (this.sessionMetadata.currentExploreType == VideoExploreTypes.GENERATED_PLAYLIST) {
      const exploreGeneratedPlaylist = this.userGeneratedPlaylists.find((userGeneratedPlaylist) => userGeneratedPlaylist.id == this.sessionMetadata.generatedPlaylistID);
      this.clickGeneratedPlaylist(exploreGeneratedPlaylist);
    } else {
      const explorePlaylist = this.userPlaylists.find((userPlaylist) => userPlaylist.id == this.sessionMetadata.explorePlaylistID);
      this.clickPlaylist(explorePlaylist);
      this.videoService.selectedPlaylistSubject.next(explorePlaylist);
    }
  }

  setupSelectedPlaylists() {
    if (this.sessionMetadata && this.userPlaylists && this.userGeneratedPlaylists) {
      this.initializeHotkeyPlaylists();

      if (this.videoService.selectedTabSubject.value === VideoTabTypes.GENERATE_PLAYLIST && !this.selectedGeneratedPlaylist && !this.selectedPlaylist) {
        this.isGeneratingPlaylist = true;
        this.displayedClipsLoaded = false;
      } else if ((this.initialPlaylistId || (this.route.snapshot.queryParams?.playlistID && this.route.snapshot.queryParams?.playlistID != this.selectedPlaylist?.id)) && this.getPlaylistFromQueryParam() != undefined) {
        this.setupPlaylistFromQueryParam();
      } else if (this.initialGeneratedPlaylistFilters || this.route.snapshot.queryParams?.generatedPlaylistFilters) {
        this.setupGeneratedFiltersFromQueryParams();
      } else if (this.route.snapshot.queryParams?.generatedPlaylistID && this.route.snapshot.queryParams?.generatedPlaylistID != this.selectedGeneratedPlaylist?.id) {
        this.switchToExploreView(this.route.snapshot.queryParams?.generatedPlaylistID);
        this.isLoading = true;
      } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.EXPLORE) {
        this.handleInitialExploreState();
      } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_1) {
        this.clickPlaylist(this.selectedPlaylist1);
        this.videoService.selectedPlaylistSubject.next(this.selectedPlaylist1);
      } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_2) {
        this.clickPlaylist(this.selectedPlaylist2);
        this.videoService.selectedPlaylistSubject.next(this.selectedPlaylist2);
      } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_3) {
        this.clickPlaylist(this.selectedPlaylist3);
        this.videoService.selectedPlaylistSubject.next(this.selectedPlaylist3);
      } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_4) {
        this.clickPlaylist(this.selectedPlaylist4);
        this.videoService.selectedPlaylistSubject.next(this.selectedPlaylist4);
      } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_5) {
        this.clickPlaylist(this.selectedPlaylist5);
        this.videoService.selectedPlaylistSubject.next(this.selectedPlaylist5);
      } else if (this.sessionMetadata.currentNavigation == VideoNavigationTypes.PLAYLIST_6) {
        this.clickPlaylist(this.selectedPlaylist6);
        this.videoService.selectedPlaylistSubject.next(this.selectedPlaylist6);
      }
    } else if (this.route.snapshot.queryParams?.playlistID) {
      this.initialPlaylistId = this.route.snapshot.queryParams.playlistID;
    } else if (this.route.snapshot.queryParams?.generatedPlaylistID) {
      this.initialGeneratedPlaylistId = this.route.snapshot.queryParams.generatedPlaylistID;
    } else if (this.route.snapshot.queryParams?.generatedPlaylistFilters) {
      this.initialGeneratedPlaylistFilters = this.route.snapshot.queryParams.generatedPlaylistFilters;
    } else if (this.sessionMetadata && !this.sessionMetadata.currentNavigation) {
      if (this.userPlaylists?.length) {
        let playlist = this.userPlaylists[0];
        this.clickPlaylist(playlist);
        this.sessionMetadata.currentNavigation = VideoNavigationTypes.EXPLORE;
        this.videoService.selectedPlaylistSubject.next(playlist);
        this.videoService.selectedTabSubject.next(VideoTabTypes.BROWSE_VIDEO);
        this.store$.dispatch(new actions.SaveAction({'exploreTypeChange': VideoExploreTypes.PLAYLIST}));
        this.store$.dispatch(new actions.SaveAction({'explorePlaylistChange': {id: playlist?.id}}));
        this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE}));
      } else if (this.userGeneratedPlaylists?.length) {
        let gp = this.userGeneratedPlaylists[0];
        this.clickGeneratedPlaylist(gp);
        this.sessionMetadata.currentNavigation = VideoNavigationTypes.EXPLORE;
        this.videoService.selectedTabSubject.next(VideoTabTypes.BROWSE_VIDEO);
        if (this.sessionMetadata.currentExploreType !== VideoExploreTypes.GENERATED_PLAYLIST) {
          this.store$.dispatch(new actions.SaveAction({'exploreTypeChange': VideoExploreTypes.GENERATED_PLAYLIST}));
        }
        this.store$.dispatch(new actions.SaveAction({'exploreGeneratedPlaylistChange': {id: gp.id}}));
        if (this.sessionMetadata.currentNavigation != VideoNavigationTypes.EXPLORE) {
          this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE}));
        }
      } else {
        this.isGeneratingPlaylist = true;
        this.displayedClipsLoaded = false;
        this.videoService.selectedTabSubject.next(VideoTabTypes.GENERATE_PLAYLIST);
        this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.GENERATE}));
      }
    }
  }

  switchToPlaylistView() {
    let playlistIndex;
    const indices = [1, 2, 3, 4, 5, 6];

    indices.forEach((i) => {
      if (this.sessionMetadata[`playlist${i}ID`] == this.selectedPlaylist?.id) {
        playlistIndex = i;
      }
      this.cdr.markForCheck();
    });
    if (playlistIndex) {
      this.store$.dispatch(new actions.SaveAction({'navigationChange': `PLAYLIST_${playlistIndex}`}));
    }
  }

  switchToExploreView(playlistID = null) {
    if (this.videoService.selectedTabSubject.value !== VideoTabTypes.GENERATE_PLAYLIST || this.selectedGeneratedPlaylist || this.selectedPlaylist) {
      this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE}));
    }
    if (this.sessionMetadata && this.userPlaylists) {
      if (this.sessionMetadata.currentExploreType == VideoExploreTypes.GENERATED_PLAYLIST) {
        let exploreGeneratedPlaylist = this.userGeneratedPlaylists.find(userGeneratedPlaylist => userGeneratedPlaylist.id == (playlistID || this.sessionMetadata.generatedPlaylistID));
        this.clickGeneratedPlaylist(exploreGeneratedPlaylist);
      } else if (!this.initialGeneratedPlaylistFilters && !this.initialGeneratedPlaylistId && !this.route.snapshot.queryParams?.generatedPlaylistID) {
        let explorePlaylist = this.userPlaylists.find(userPlaylist => userPlaylist.id == this.sessionMetadata.explorePlaylistID);
        this.clickPlaylist(explorePlaylist);
        this.videoService.selectedPlaylistSubject.next(explorePlaylist);
      }
    }
  }

  selectPlaylistHotkey(index) {
    this.videoService.selectedPlaylistSubject.next(this['selectedPlaylist' + index]);
    this.store$.dispatch(new actions.SaveAction({'navigationChange': `PLAYLIST_${index}`}));
    this.blurPlaylistHokeyMenu({}, 0);
  }

  updateDisplayedColumns() {
    if (this.selectedPlaylist) {
      this.columns = this.playlistColumnsData.filter((columnData) => columnData.default);
    } else {
      this.initializeSelectedLeague();
      this.columns = this.columnsData.filter((columnData) => columnData.selectedEventTypes.includes(this.filtersData.subject) && columnData.selectedLeagues.includes(this.selectedLeague) && columnData.default);
    }
    this.cdr.markForCheck();
  }

  filterPlaylistsByName() {
    if (this.playlistSearchValue === '') {
      this.filteredPlaylists = _.cloneDeep(this.userPlaylists);
    } else {
      const searchValue = this.playlistSearchValue.toLowerCase();
      this.filteredPlaylists = _.filter(this.userPlaylists, (playlist) => {
        return playlist.name.toLowerCase().includes(searchValue);
      });
    }
  }

  filterFoldersByName() {
    if (this.folderSearchValue === '') {
      this.filteredFolders = _.cloneDeep(this.userFolders);
    } else {
      const searchValue = this.folderSearchValue.toLowerCase();
      this.filteredFolders = _.filter(this.userFolders, (folder) => {
        return folder.name.toLowerCase().includes(searchValue);
      });
    }
  }

  updateTableSortOrder(sortData) {
    const sortBy = sortData['column'];
    const sortDirection = sortData['direction'] || '';
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
    this.updateDisplayedClips();
    this.store$.dispatch(new actions.SaveAction({'sortDirectionChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'sortDirection': sortDirection}, 'chainID': this.chainID}));
    this.store$.dispatch(new actions.SaveAction({'sortColumnChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'sortColumn': sortBy}, 'chainID': this.chainID}));
  }

  changeToClip(videoIndex, shouldCollapseTable = true) {
    if (this.displayedClips[videoIndex] && this.currentClip == this.displayedClips[videoIndex]) {
      return;
    }
    this.encounteredError = false;
    if (this.videoPlayer?.isVideoPlaying) {
      this.video.nativeElement.load();
    }
    const defaultPadding = this.getDefaultPadding();
    if (this.displayedClips[videoIndex]) {
      this.currentVideoIndex = videoIndex;
      this.currentVideoID = this.displayedClips[videoIndex].id;
      this.store$.dispatch(new actions.SaveAction({'clipIndexChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'currentIndex': this.currentVideoIndex}, 'chainID': this.chainID}));
      let videoClipParams = this.displayedClips[videoIndex];
      this.currentClip = videoClipParams;
      if (this.currentClip.url && !this.currentClip.clipUrl) {
        this.currentClip.clipUrl = this.currentClip.url;
        videoClipParams.clipUrl = this.currentClip.url;
        this.isLoading = false;
        this.preloadNextClip();
        this.cdr.markForCheck();
      } else if (this.currentClip.clipUrl == null) {
        this.currentClip.clipUrl = '';
        this.videoService.getCTGVideo(this.currentClip['league'], this.currentClip['gameID'], this.currentClip['period'], this.currentClip['endPadding'], this.currentClip['startPadding'] + defaultPadding, this.currentClip['startTimestamp'], this.currentClip['startGameclock']).pipe(untilDestroyed(this)).subscribe(
          videoSourceData => {
            if (videoSourceData) {
              if (videoSourceData?.length) {
                videoSourceData = videoSourceData[0];
              }
              videoClipParams.clipUrl = videoSourceData['clip_url'];
              this.isLoading = false;
              this.preloadNextClip();
              this.cdr.markForCheck();
            }
          },
          error => {
            console.log(error);
            this.encounteredError = true;
          }
        );
        this.getClipZelusData(this.currentClip);
      } else {
        this.preloadNextClip();
        if (this.video.nativeElement.readyState != 0) {
          this.onVideoCanPlay(this.currentVideoNumber);
        }
      }
    }
    if (this.isTableExpanded && shouldCollapseTable) {
      this.toggleClipTableExpanded();
    }
    if (this.currentClip && this.selectedTagType) {
      const tag = this.currentClip.tags.find(tag =>
        tag.definition?.name === this.selectedTagType?.name &&
        tag.author?.userID === this.user.id &&
        ((this.tagPlayers && this.confirmIsPlayerTag(tag)) || (!this.tagPlayers && !this.confirmIsPlayerTag(tag)))
      );
      if (tag) {
        this.editTag(tag);
      } else {
        this.tagEditing = false;
        this.previousEntitySelected = false;
        this.selectedTagType?.metadataDefinitions?.forEach((metadataDefinition) => {
          metadataDefinition.value = null;
        });
      }
    } else if (this.currentClip) {
      this.tagEditing = false;
      // Don't carry over values from last tag
      this.selectedTagType?.metadataDefinitions?.forEach((metadataDefinition) => {
        metadataDefinition.value = null;
      });
    }
  }

  changeToClipAndSave(videoIndex) {
    this.changeToClip(videoIndex);
    this.saveVideo();
  }

  getClipZelusData(videoClipParams) {
    if ((this.showEpv || this.show2dCourt) && ['NBA', 'NCAA'].includes(videoClipParams['league']) && videoClipParams['startTimestamp']) {
      this.videoService.getZelusData(videoClipParams['league'], videoClipParams['gameID'], videoClipParams['endPadding'], videoClipParams['startPadding'], videoClipParams['startTimestamp']).pipe(untilDestroyed(this)).subscribe(
          (videoSourceData) => {
            videoClipParams.zelusData = videoSourceData['zelus_data'];
            videoClipParams.trackingMetadata = videoSourceData['tracking_metadata'];
            this.cdr.detectChanges();
          },
          (error) => {
            console.log(error);
            this.encounteredError = true;
          },
      );
    }
  }

  autoScrollClipList() {
    this.page = Math.floor(this.currentVideoIndex / this.pageSize) + 1;
    if (this.videoClipTable) {
      this.videoClipTable.clipList._elementRef.nativeElement.scrollTo({
        top: (this.currentVideoIndex % this.pageSize) * 32,
        behavior: 'smooth'
      });
    }
  }

  getNextAvailableVideoIndex(startIndex, direction) {
    if (direction == 'next') {
      for (let i = startIndex + 1; i < this.displayedClips.length; i++) {
        if (this.displayedClips[i].clipAvailable || this.displayedClips[i].url) {
          return i;
        }
      }
    }
    else {
      for (let i = startIndex - 1; i >= 0; i--) {
        if (this.displayedClips[i].clipAvailable || this.displayedClips[i].url) {
          return i;
        }
      }
    }
  }

  onSavingUpdate(isSaving: boolean) {
    if (isSaving) {
      this.saveVideo();
    } else {
      this.cancelSaveVideo();
    }
  }

  reloadClipUrl() {
    const defaultPadding = this.getDefaultPadding();
    if (this.currentClip.url) {
      this.currentClip.clipUrl = this.currentClip.url;
      this.cdr.markForCheck();
    }
    else {
      this.videoService.getCTGVideo(this.currentClip['league'], this.currentClip['gameID'], this.currentClip['period'], this.currentClip['endPadding'], this.currentClip['startPadding'] + defaultPadding, this.currentClip['startTimestamp'], this.currentClip['startGameclock']).pipe(untilDestroyed(this)).subscribe(
        videoSourceData => {
          this.currentClip.clipUrl = videoSourceData['clip_url'];
          this.cdr.markForCheck();
        },
        error => {
          console.log(error);
          this.encounteredError = true;
        }
      );
      this.getClipZelusData(this.currentClip);
    }
  }

  preloadNextClip() {
    let videoIndex = this.currentVideoIndex + 1;
    const defaultPadding = this.getDefaultPadding();
    if (this.displayedClips[videoIndex] && !this.displayedClips[videoIndex].clipUrl) {
      let videoClipParams = this.displayedClips[videoIndex];
      if (videoClipParams.url) {
        videoClipParams.clipUrl = videoClipParams.url;
        this.nextClip = videoClipParams;
        this.cdr.markForCheck();
      }
      else {
        videoClipParams.clipUrl = '';
        this.videoService.getCTGVideo(videoClipParams['league'], videoClipParams['gameID'], videoClipParams['period'], videoClipParams['endPadding'], videoClipParams['startPadding'] + defaultPadding, videoClipParams['startTimestamp'], videoClipParams['startGameclock'], this.currentClip['clipUrl']).pipe(untilDestroyed(this)).subscribe(
          videoSourceData => {
            videoClipParams.clipUrl = videoSourceData['clip_url'];
            videoClipParams.smoothingTrim = videoSourceData['smoothing_trim'];
            this.nextClip = videoClipParams;
            this.cdr.markForCheck();
          },
          error => {
            console.log(error);
            this.encounteredError = true;
          }
        );
        this.getClipZelusData(videoClipParams);
      }
    }
    else {
      this.nextClip = this.displayedClips[videoIndex];
      if (this.nextVideo.nativeElement.readyState != 0) {
        this.onVideoCanPlay(this.nextVideoNumber);
      }
    }
  }

  getDefaultPadding() {
    return this.requiresDefaultStartPadding.includes(this.selectedGeneratedPlaylist?.filtersData.subject) ? this.defaultPaddingSet : 0;
  }

  playVideo() {
    this.video.nativeElement.play();
    this.isVideoPlaying = true;
  }

  pauseVideo() {
    this.video.nativeElement.pause();
    this.isVideoPlaying = false;
  }

  changeVolume(volume) {
    this.volume = volume;
    this.video.nativeElement.volume = this.volume;
  }

  changePlaybackSpeed(playbackSpeed) {
    this.playbackSpeed = playbackSpeed;
    this.video.nativeElement.playbackRate = this.playbackSpeed;
  }

  changeCurrentTime(currentTime) {
    this.currentVideoTime = currentTime;
    this.video.nativeElement.currentTime = this.currentVideoTime;
  }

  changeNextVideoTime(currentTime) {
    this.nextVideoTime = currentTime;
    this.nextVideo.nativeElement.currentTime = this.nextVideoTime;
  }

  initializeTimeDotLocation() {
    this.timeDotTop = this.video.nativeElement.clientHeight;
    this.timeDotLeft = this.video.nativeElement.closest('.video-outer').querySelector('.progress-bar-outer').offsetLeft;
  }

  mouseLeavePlayer() {
    this.endDragging();
  }

  onVideoCanPlay(videoNumber) {
    if (videoNumber == this.currentVideoNumber) {
      this.changePlaybackSpeed(this.playbackSpeed);
      this.changeVolume(this.volume);
      if (this.awaitingAutoplayAvailability && !this.wasPlayingBeforeDrag) {
        if (this.currentClip.smoothingTrim && Math.round(this.video.nativeElement.currentTime) != Math.round(this.currentClip.smoothingTrim)) {
          this.changeCurrentTime(this.currentClip.smoothingTrim);
        }
        this.playVideo();
        this.awaitingAutoplayAvailability = false;
      }
      else if (this.isVideoPlaying && !this.wasPlayingBeforeDrag) {
        this.playVideo();
      }
    }
    else {
      if (this.nextClip?.smoothingTrim && Math.round(this.nextVideo.nativeElement.currentTime) != Math.round(this.nextClip.smoothingTrim)) {
        this.changeNextVideoTime(this.nextClip.smoothingTrim);
      }
    }
    if (!this.initialVideoLoaded) {
      this.initializeTimeDotLocation();
    }
    this.initialVideoLoaded = true;
  }

  updateTimeDotLocation() {
    const outerProgressBarElement = this.video.nativeElement.closest('.video-outer').querySelector('.progress-bar-outer');
    const offsetLeft = outerProgressBarElement.offsetLeft;

    const containerWidth = this.video.nativeElement.clientWidth - 2 * offsetLeft;

    const halfDotWidthOffset = 6;

    this.timeDotLeft = this.currentVideoTime / this.video.nativeElement.duration * containerWidth + offsetLeft - halfDotWidthOffset;
    this.cdr.markForCheck();
  }

  onTimeDotDrag(dragEvent) {
    if (this.isDragging) {
      let progressBarBounds = this.progressBar.nativeElement.getBoundingClientRect();
      let offsetX = dragEvent.clientX - progressBarBounds.left;
      this.changeCurrentTime(this.video.nativeElement.duration * Math.min(1, Math.max(0, (offsetX / progressBarBounds.width))));
      this.updateTimeDotLocation();
    }
  }

  endDragging() {
    this.isDragging = false;
    if (this.wasPlayingBeforeDrag) {
      this.playVideo();
      this.wasPlayingBeforeDrag = false;
    }
  }

  updatePlaylistQueryParam(selectedPlaylist, isGeneratedPlaylist = false) {
    if (this.isClosing) {
      this.router.navigate([], {
        queryParams: {
          'videoPanelOpen': null,
          'playlistID': null,
          'generatedPlaylistID': null,
        },
        queryParamsHandling: 'merge',
      });
      return;
    }
    if (isGeneratedPlaylist && selectedPlaylist?.id) {
      this.router.navigate([], {
        queryParams: {
          'videoPanelOpen': true,
          'generatedPlaylistID': selectedPlaylist.id,
        },
      });
    } else if (selectedPlaylist?.id && !isGeneratedPlaylist) {
      this.router.navigate([], {
        queryParams: {
          'videoPanelOpen': true,
          'playlistID': selectedPlaylist.id,
        },
      });
    }
    this.cdr.markForCheck();
  }

  resetTableState() {
    this.sortBy = null;
    this.sortDirection = null;
    this.resetFilterState();
    this.cdr.markForCheck();
  }

  clickPlaylist(selectedPlaylist, shouldUpdate = true) {
    this.deselectAllClips();
    this.isSavingMany = false;
    this.isSaving = false;
    this.isGeneratingPlaylist = false;
    this.capturedClipOrder = [];
    if (this.selectedPlaylist?.id != selectedPlaylist?.id) {
      this.selectedPlaylist = selectedPlaylist;
      if (this.videoPlayer) {
        this.videoPlayer.shouldAutoPlay = false;
      }
      this.resetTableState();
      this.selectedGeneratedPlaylist = null;
      this.currentClip = null;
      if (shouldUpdate && this.selectedPlaylist) {
        this.clips = [];
        this.displayedClips = [];
        this.displayedClipsLoaded = false;
        if (this.selectedPlaylist?.clips) {
          this.clips = this.selectedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.initializeSessionState();
          this.updateDisplayedClips();
        } else {
          this.chainID = this.videoService.generateChainID();
          this.store$.dispatch(new actions.SaveAction({'playlistOpened': {'id': this.selectedPlaylist.id}, 'chainID': this.chainID}));
        }
      }
    }
    this.updatePlaylistQueryParam(selectedPlaylist);
  }

  addColumnPrefix(columnName: string, selectedLeague: string) {
    if (columnName === subjectToPrimaryFilterName[VideoSubject.TOUCHES] || columnName === subjectToPrimaryFilterName[VideoSubject.FREE_THROWS] ) {
      return columnName;
    }
    const prefix = ['NCAA', 'OTHER'].includes(selectedLeague) ? 'synergy_' : 'nba_';
    if (!columnName?.includes(prefix)) {
      columnName = prefix + columnName;
    }
    return columnName;
  }

  initializeGeneratedFilters(generatedPlaylist) {
    if (generatedPlaylist?.filtersData) {
      generatedPlaylist.filters = _.cloneDeep(this.defaultDisplayedFilters);
      const selectedLeague = this.determineLeagueFromFilters(generatedPlaylist);
      const onOffColumn = this.addColumnPrefix('on_court_player_ids', selectedLeague);
      const offensiveLineupColumn = this.addColumnPrefix('offensive_player_ids', selectedLeague);
      const defensiveLineupColumn = this.addColumnPrefix('defensive_player_ids', selectedLeague);
      const primaryDefensivePlayerColumn = this.addColumnPrefix('primary_defensive_player_id', selectedLeague);
      const primaryFilterColumn = this.addColumnPrefix(subjectToPrimaryFilterName[generatedPlaylist.filtersData.subject], selectedLeague);
      const secondaryFilterColumn = this.addColumnPrefix(this.subjectToSecondaryFilterName[generatedPlaylist.filtersData.subject], selectedLeague);
      const teamColumn = this.addColumnPrefix('on_court_team_ids', selectedLeague);
      const offensiveTeamColumn = this.addColumnPrefix('offensive_team_id', selectedLeague);
      const defensiveTeamColumn = this.addColumnPrefix('defensive_team_id', selectedLeague);
      const distancePrefix = ['NCAA', 'OTHER'].includes(selectedLeague) ? 'synergy_' : 'eagle_';
      this.initializeFilteredEntities(onOffColumn, 'onCourtEntities', selectedLeague, generatedPlaylist, false);
      this.initializeFilteredEntities(onOffColumn, 'offCourtEntities', selectedLeague, generatedPlaylist, true);
      this.initializeFilteredEntities(offensiveLineupColumn, 'offensiveLineupEntities', selectedLeague, generatedPlaylist);
      this.initializeFilteredEntities(defensiveLineupColumn, 'defensiveLineupEntities', selectedLeague, generatedPlaylist);
      this.initializeFilteredEntities(primaryDefensivePlayerColumn, 'primaryDefensivePlayer', selectedLeague, generatedPlaylist);
      this.initializeFilteredEntities(primaryFilterColumn, 'primaryEntity', selectedLeague, generatedPlaylist);
      this.initializeFilteredEntities(secondaryFilterColumn, 'secondaryEntity', selectedLeague, generatedPlaylist);
      this.initializeFilteredEntities(teamColumn, 'teamEntities', selectedLeague, generatedPlaylist, false, true);
      this.initializeFilteredEntities(offensiveTeamColumn, 'offensiveTeamEntities', selectedLeague, generatedPlaylist, false, true);
      this.initializeFilteredEntities(defensiveTeamColumn, 'defensiveTeamEntities', selectedLeague, generatedPlaylist, false, true);
      this.initializeActionEntities(selectedLeague, generatedPlaylist);
      this.initializeIndividualFilter('period', 'periods', generatedPlaylist);
      this.initializeIndividualFilter('league_id', 'leagueID', generatedPlaylist);
      this.initializeIndividualFilter('season', 'seasons', generatedPlaylist);
      this.initializeIndividualFilter('game_type', 'game_type', generatedPlaylist);
      this.initializeIndividualFilter('charting_category_name', 'charting_category_name', generatedPlaylist);
      this.initializeIndividualFilter('charting_result', 'charting_result', generatedPlaylist);
      this.initializeIndividualFilter('starttype', 'starttype', generatedPlaylist);
      this.initializeIndividualFilter('outcomes', 'outcomes', generatedPlaylist);
      this.initializeIndividualFilter('regionsin', 'regionsin', generatedPlaylist);
      this.initializeIndividualFilter('cs_3p_opp', 'potentialcatchandshoot', generatedPlaylist);
      this.initializeIndividualFilter('assist', 'assist', generatedPlaylist);
      this.initializeIndividualFilter('block', 'block', generatedPlaylist);
      this.initializeIndividualFilter('catch_and_shoot', 'catch_and_shoot', generatedPlaylist);
      this.initializeIndividualFilter('shooting_foul', 'shooting_foul', generatedPlaylist);
      this.initializeIndividualFilter('fg_made', 'fg_made', generatedPlaylist);
      this.initializeIndividualFilter('fg_3_attempted', 'fg_3_attempted', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defended_by_1', 'nba_player_ids_defended_by_1', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defended_by_2', 'nba_player_ids_defended_by_2', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defended_by_3', 'nba_player_ids_defended_by_3', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defended_by_4', 'nba_player_ids_defended_by_4', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defended_by_5', 'nba_player_ids_defended_by_5', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_1', 'nba_player_ids_defending_1', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_2', 'nba_player_ids_defending_2', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_3', 'nba_player_ids_defending_3', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_4', 'nba_player_ids_defending_4', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_5', 'nba_player_ids_defending_5', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_1', 'nba_player_ids_expected_defending_1', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_2', 'nba_player_ids_expected_defending_2', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_3', 'nba_player_ids_expected_defending_3', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_4', 'nba_player_ids_expected_defending_4', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_5', 'nba_player_ids_expected_defending_5', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_re_aggregate', 'nba_player_ids_defending_re_aggregate', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_ml', 'nba_player_ids_defending_ml', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_ro', 'nba_player_ids_defending_ro', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_re', 'nba_player_ids_defending_re', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_kr', 'nba_player_ids_defending_kr', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_s', 'nba_player_ids_defending_s', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_ts', 'nba_player_ids_defending_ts', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_c', 'nba_player_ids_defending_c', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_defending_f', 'nba_player_ids_defending_f', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_ml', 'nba_player_ids_crossmatched_defending_ml', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_ro', 'nba_player_ids_crossmatched_defending_ro', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_re', 'nba_player_ids_crossmatched_defending_re', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_kr', 'nba_player_ids_crossmatched_defending_kr', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_s', 'nba_player_ids_crossmatched_defending_s', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_ts', 'nba_player_ids_crossmatched_defending_ts', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_c', 'nba_player_ids_crossmatched_defending_c', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched_defending_f', 'nba_player_ids_crossmatched_defending_f', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_re_aggregate', 'nba_player_ids_expected_defending_re_aggregate', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_ml', 'nba_player_ids_expected_defending_ml', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_re', 'nba_player_ids_expected_defending_re', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_ro', 'nba_player_ids_expected_defending_ro', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_kr', 'nba_player_ids_expected_defending_kr', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_s', 'nba_player_ids_expected_defending_s', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_ts', 'nba_player_ids_expected_defending_ts', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_c', 'nba_player_ids_expected_defending_c', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_expected_defending_f', 'nba_player_ids_expected_defending_f', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_switched', 'nba_player_ids_switched', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_crossmatched', 'nba_player_ids_crossmatched', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_ids_contested_shooter', 'nba_player_ids_contested_shooter', generatedPlaylist);
      this.initializeIndividualFilter('nba_player_id_last_defending_shooter', 'nba_player_id_last_defending_shooter', generatedPlaylist);
      this.initializeIndividualFilter('action_ball_screen_bhdefender_nbapersonids', 'action_ball_screen_bhdefender_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_ball_screen_scdefender_nbapersonids', 'action_ball_screen_scdefender_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_off_ball_screen_ctdefender_nbapersonids', 'action_off_ball_screen_ctdefender_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_off_ball_screen_scdefender_nbapersonids', 'action_off_ball_screen_scdefender_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_isolation_bhdefender_nbapersonids', 'action_isolation_bhdefender_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_drive_bhdefender_nbapersonids', 'action_drive_bhdefender_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_post_bhdefender_nbapersonids', 'action_post_bhdefender_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_post_doubler_nbapersonids', 'action_post_doubler_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_ball_screen_ballhandler_nbapersonids', 'action_ball_screen_ballhandler_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_isolation_ballhandler_nbapersonids', 'action_isolation_ballhandler_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_drive_ballhandler_nbapersonids', 'action_drive_ballhandler_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_post_ballhandler_nbapersonids', 'action_post_ballhandler_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_off_ball_screen_screener_nbapersonids', 'action_off_ball_screen_screener_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_off_ball_screen_cutter_nbapersonids', 'action_off_ball_screen_cutter_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('action_off_ball_screen_toucher_nbapersonids', 'action_off_ball_screen_toucher_nbapersonids', generatedPlaylist);
      this.initializeIndividualFilter('shot_zone_basic', 'shot_zone_basic', generatedPlaylist);
      this.initializeIndividualFilter('shot_distribution', 'shot_distribution', generatedPlaylist);
      this.initializeIndividualFilter('eagle_chance_id', 'eagle_chance_id', generatedPlaylist);
      this.initializeIndividualFilter('nba_chance_id', 'nba_chance_id', generatedPlaylist);
      this.initializeIndividualFilter('nba_possession_id', 'nba_possession_id', generatedPlaylist);
      this.initializeIndividualFilter('synergy_event_id', 'synergy_event_id', generatedPlaylist);
      this.initializeIndividualFilter('shot_contestedness', 'shot_contestedness', generatedPlaylist);
      this.initializeIndividualFilter('shot_setup_advanced', 'shot_setup_advanced', generatedPlaylist);
      this.initializeIndividualFilter('synergy_shot_type', 'synergy_shot_type', generatedPlaylist);
      this.initializeSynergyFilters(generatedPlaylist);
      this.initializeMinMaxFilter('game_date', 'minDate', 'maxDate', generatedPlaylist);
      this.initializeMinMaxFilter('starting_seconds_left_in_period', 'minGameclock', 'maxGameclock', generatedPlaylist);
      this.initializeMinMaxFilter('shot_clock', 'minShotclock', 'maxShotclock', generatedPlaylist);
      this.initializeMinMaxFilter('numdribbles', 'touchMinDribbles', 'touchMaxDribbles', generatedPlaylist);
      this.initializeMinMaxFilter('touchtime', 'touchMinDuration', 'touchMaxDuration', generatedPlaylist);
      this.initializeMinMaxFilter('shot_qefg', 'minQEFG', 'maxQEFG', generatedPlaylist);
      this.initializeMinMaxFilter('shot_xefg', 'minXEFG', 'maxXEFG', generatedPlaylist);
      this.initializeMinMaxFilter(`${distancePrefix}shot_distance`, 'minDistance', 'maxDistance', generatedPlaylist);
      this.initializeGameFilter(generatedPlaylist, selectedLeague);

      if (generatedPlaylist.filters.primaryEntity == null || !generatedPlaylist.filters.primaryEntity.length) {
        const primaryFilterColumn = this.addColumnPrefix('subject_player_id', selectedLeague);
        this.initializeFilteredEntities(primaryFilterColumn, 'primaryEntity', selectedLeague, generatedPlaylist);
      }
    }
  }

  initializeGameFilter(generatedPlaylist, selectedLeague) {
    const gameField = (['NCAA', 'OTHER'].includes(selectedLeague) ? 'synergy_' : 'nba_') + 'game_id';
    const gameFilter = _.find(generatedPlaylist.filtersData.filters, ['name', gameField]);
    if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters.ctgGames = [];
    }
    if (gameFilter) {
      generatedPlaylist.filters.ctgGames = [];
      gameFilter.args?.forEach((gameID) => {
        const lookupKey = ['NCAA', 'OTHER'].includes(selectedLeague) ? 'synergyGameID' : 'nbaGameID';
        const game = _.find(generatedPlaylist.filtersData.gameLookup, [lookupKey, gameID]);
        if (game && generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
          this.displayedFilters.ctgGames.push(game);
        }
        if (game) {
          generatedPlaylist.filters.ctgGames.push(game);
        }
      });
    }
  }

  initializeActionEntities(selectedLeague, generatedPlaylist) {
    const generatedFieldName = this.addColumnPrefix('subject_player_id', selectedLeague);

    const filteredEntities = _.filter(generatedPlaylist.filtersData.filters, (filt) => {
      return filt.name === generatedFieldName && actionNames.includes(filt.group);
    });
    if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters.abstractEntity = [];
    }
    if (filteredEntities.length) {
      const filteredEntityNBAIDs = _.map(filteredEntities, 'args');
      generatedPlaylist.filters.abstractEntity = [];
      filteredEntityNBAIDs.forEach((playerIDs) => {
        const lookupKey = ['NCAA', 'OTHER'].includes(selectedLeague) ? 'synergy_ids' : 'nba_ids';
        const person = _.find(generatedPlaylist.filtersData.personLookup, (personLookup) => _.isEqual(_.sortBy(personLookup[lookupKey]), _.sortBy(playerIDs)));
        if (person && generatedPlaylist.id === this.selectedGeneratedPlaylist?.id && !this.displayedFilters.abstractEntity?.includes(person.entity)) {
          this.displayedFilters.abstractEntity.push(person.entity);
        }
        if (person && !generatedPlaylist.filters.abstractEntity?.includes(person.entity)) {
          generatedPlaylist.filters.abstractEntity.push(person.entity);
        }
      });
    }
  }

  initializeSynergyFilters(generatedPlaylist) {
    const actionFilters = _.filter(generatedPlaylist.filtersData.filters, (filt) => filt.name === 'name');
    generatedPlaylist.filters.actions = _.uniq(_.map(actionFilters, 'group'));

    if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters.actions = generatedPlaylist.filters.actions;
    }

    const includedDescriptorFilters = _.filter(generatedPlaylist.filtersData.filters, (filt) => filt.name === 'descriptors' && !filt.not);
    const excludedDescriptorFilters = _.filter(generatedPlaylist.filtersData.filters, (filt) => filt.name === 'descriptors' && filt.not);

    includedDescriptorFilters.forEach((includedFilter) => {
      includedFilter.args.forEach((arg) => {
        generatedPlaylist.filters[includedFilter.group + arg + includedFilter.name] = 1;
        if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
          this.displayedFilters[includedFilter.group + arg + includedFilter.name] = 1;
        }
      });
    });

    excludedDescriptorFilters.forEach((excludedFilter) => {
      excludedFilter.args.forEach((arg) => {
        generatedPlaylist.filters[excludedFilter.group + arg + excludedFilter.name] = 0;
        if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
          this.displayedFilters[excludedFilter.group + arg + excludedFilter.name] = 0;
        }
      });
    });
  }

  initializeMinMaxFilter(generatedFieldName: string, minName: string, maxName: string, generatedPlaylist: any) {
    if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters[minName] = _.cloneDeep(this.defaultDisplayedFilters[minName]);
      this.displayedFilters[maxName] = _.cloneDeep(this.defaultDisplayedFilters[maxName]);
    }
    const filter = _.find(generatedPlaylist.filtersData.filters, ['name', generatedFieldName]);
    if (filter && generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters[minName] = generatedFieldName == 'game_date' ? moment(filter.args['min']) : filter.args['min'];
      this.displayedFilters[maxName] = generatedFieldName == 'game_date' ? moment(filter.args['max']) : filter.args['max'];
    }
    if (filter) {
      generatedPlaylist.filters[minName] = generatedFieldName == 'game_date' ? moment(filter.args['min']) : filter.args['min'];
      generatedPlaylist.filters[maxName] = generatedFieldName == 'game_date' ? moment(filter.args['max']) : filter.args['max'];
    }
  }

  initializeIndividualFilter(generatedFieldName: string, filterName: string, generatedPlaylist: any) {
    if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters[filterName] = _.cloneDeep(this.defaultDisplayedFilters[filterName]);
    }
    const filter = _.find(generatedPlaylist.filtersData.filters, ['name', generatedFieldName]);
    if (filter && generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters[filterName] = this.BooleanDefenseTags.includes(filterName) ? (filter.not ? 0 : 1) : filter.args;
    }
    if (filter) {
      generatedPlaylist.filters[filterName] = filter.args;
    }
  }

  determineLeagueFromFilters(generatedPlaylist) {
    const filter = _.find(generatedPlaylist.filtersData.filters, ['name', 'league_id']);
    if (!filter) {
      if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
        this.displayedFilters.leagueIDs = [];
      }
      return 'OTHER';
    } else if (_.isEqual(filter.args, this.selectedLeagueToLeagueIDs['NCAA'])) {
      if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
        this.displayedFilters.leagueIDs = this.selectedLeagueToLeagueIDs['NCAA'];
      }
      return 'NCAA';
    } else if (_.isEqual(filter.args, this.selectedLeagueToLeagueIDs['GLG'])) {
      if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
        this.displayedFilters.leagueIDs = this.selectedLeagueToLeagueIDs['GLG'];
      }
      return 'GLG';
    } else {
      if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
        this.displayedFilters.leagueIDs = this.selectedLeagueToLeagueIDs['NBA'];
      }
      return 'NBA';
    }
  }

  initializeFilteredEntities(generatedFieldName: string, entityFilterName: string, selectedLeague: string, generatedPlaylist: any, not = false, isTeam = false) {
    const filteredEntities = _.filter(generatedPlaylist.filtersData.filters, (filt) => {
      return filt.name === generatedFieldName && filt.not == not && !actionNames.includes(filt.group);
    });
    if (generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
      this.displayedFilters[entityFilterName] = [];
    }
    if (filteredEntities.length) {
      let filteredEntityNBAIDs = _.map(filteredEntities, 'args');
      generatedPlaylist.filters[entityFilterName] = [];
      filteredEntityNBAIDs.forEach((entityIDs) => {
        if (typeof entityIDs === 'object') {
          entityIDs.forEach((entityID, index) => {
            if (index == 0 || isTeam) {
              const lookupKey = ['NCAA', 'OTHER'].includes(selectedLeague) ? 'synergy_ids' : 'nba_ids';
              const lookupObject = isTeam ? 'teamLookup' : 'personLookup';
              const entity = _.find(generatedPlaylist.filtersData[lookupObject], (lookupObject) => {
                return _.isEqual(_.sortBy(lookupObject[lookupKey]), _.sortBy(entityIDs)) ||
                  (!isTeam && _.difference(entityIDs, lookupObject[lookupKey]).length === 0) ||
                  (isTeam && lookupObject[lookupKey].includes(entityID));
              });
              if (entity && generatedPlaylist.id === this.selectedGeneratedPlaylist?.id) {
                this.displayedFilters[entityFilterName].push(entity.entity);
              }
              if (entity) {
                generatedPlaylist.filters[entityFilterName].push(entity.entity);
              }
            }
          });
        }
      });
    }
  }

  resetFilterState() {
    this.filterProperties = {};
    this.activeFilters = {};
  }

  clickGeneratedPlaylist(selectedGeneratedPlaylist) {
    this.deselectAllClips();
    this.isSavingMany = false;
    this.isSaving = false;
    this.isGeneratingPlaylist = false;
    this.capturedClipOrder = [];
    const isInitialLoad = !this.selectedGeneratedPlaylist;
    if (this.selectedGeneratedPlaylist?.id != selectedGeneratedPlaylist?.id || this.selectedPlaylist) {
      const changingGP = this.selectedGeneratedPlaylist && this.selectedGeneratedPlaylist?.id != selectedGeneratedPlaylist?.id;
      this.selectedPlaylist = null;
      if (this.videoPlayer) {
        this.videoPlayer.shouldAutoPlay = false;
      }
      this.videoService.selectedPlaylistSubject.next(null);
      this.selectedGeneratedPlaylist = _.cloneDeep(selectedGeneratedPlaylist);
      if (this.selectedGeneratedPlaylist) {
        this.selectedGeneratedPlaylist.isShuffled = false;
      }
      this.resetTableState();
      if (changingGP) {
        this.resetGeneratedPlaylistSession();
      }
      this.initializeSessionState();
      this.currentClip = null;
      if (this.selectedGeneratedPlaylist) {
        this.filtersData = this.selectedGeneratedPlaylist.filtersData;
        this.initializeGeneratedFilters(selectedGeneratedPlaylist);
        this.initializeGeneratedFilters(this.selectedGeneratedPlaylist);
        this.clips = [];
        this.displayedClips = [];
        if (this.selectedGeneratedPlaylist?.clips) {
          this.clips = this.selectedGeneratedPlaylist.clips;
          this.unfilteredClips = _.cloneDeep(this.clips);
          this.updateDisplayedClips();
        } else {
          this.chainID = this.videoService.generateChainID();
          // We want to prevent loading the current clip twice
          if (!isInitialLoad) {
            this.store$.dispatch(new actions.SaveAction({'generatedPlaylistOpened': {'id': this.selectedGeneratedPlaylist.id}, 'chainID': this.chainID}));
          }
        }
      }
    }
    this.updatePlaylistQueryParam(selectedGeneratedPlaylist, true);
  }

  initializeSessionState() {
    if (this.selectedPlaylist) {
      this.selectedPlaylist.isShuffled = this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}IsShuffled`];
    } else if (this.selectedGeneratedPlaylist) {
      this.selectedGeneratedPlaylist.isShuffled = this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}IsShuffled`];
    }
    if (!this.sortBy || !this.sortDirection) {
      this.sortBy = this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}SortColumn`];
      this.sortDirection = this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}SortDirection`];
    }
  }

  resetGeneratedPlaylistSession() {
    this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}IsShuffled`] = false;
    this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}SortColumn`] = false;
    this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}SortDirection`] = false;
    this.store$.dispatch(new actions.SaveAction({'isShuffledChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'isShuffled': false}, 'chainID': this.chainID}));
    this.store$.dispatch(new actions.SaveAction({'sortColumnChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'sortColumn': false}, 'chainID': this.chainID}));
    this.store$.dispatch(new actions.SaveAction({'sortDirectionChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'sortDirection': false}, 'chainID': this.chainID}));
  }

  updateDisplayedClips(preventLoadedState = false) {
    const newClips = _.cloneDeep(this.clips);
    this.updateDisplayedColumns();
    if (!preventLoadedState) {
      this.displayedClipsLoaded = true;
    }
    this.displayedClips = _.cloneDeep(newClips);
    if (this.selectedPlaylist?.isShuffled || this.selectedGeneratedPlaylist?.isShuffled) {
      this.shuffle();
    }
    if (this.sortBy && this.sortDirection) {
      this.displayedClips = this.videoHelper.customSorting(this.displayedClips, this.sortBy, this.sortDirection);
    } else if (this.capturedClipOrder && this.capturedClipOrder.length > 0 && this.selectedPlaylist && !this.selectedPlaylist?.isShuffled) {
      const clipIds = new Set(this.clips.map(c => c.id));
      this.displayedClips = _.cloneDeep(
        this.capturedClipOrder.filter(clip => clipIds.has(clip.id))
      );
    }

    // Added for Style of Play tags
    this.videoHelper.currentUserID = this.user.id;

    this.displayedClips = this.videoHelper.filterClips(this.filterProperties, this.displayedClips);
    const sessionClipIndex = this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}ClipIndex`];
    const videoIndex = (sessionClipIndex != null && sessionClipIndex < this.displayedClips.length) ? sessionClipIndex : this.getNextAvailableVideoIndex(-1, 'next');
    this.changeToClip(videoIndex, false);
    this.cdr.markForCheck();
  }

  initializePlaylist(playlistIndex: number) {
    this.notesVisible = this.sessionMetadata['playlist' + playlistIndex + 'IsNotesShown'];
    const playlist = this.userPlaylists.find((userPlaylist) => userPlaylist.id == this.sessionMetadata['playlist' + playlistIndex + 'ID']);
    this.clickPlaylist(playlist);
  }

  deselectAllClips() {
    this.displayedClips.forEach((clip) => {
      clip.isSelected = false;
    });
    this.updateSelectedClips(true);
  }

  toggleAllSelected(e) {
    this.displayedClips.forEach(clip => {
      clip.isSelected = e.checked;
    });
    this.updateSelectedClips(true);
  }

  updateIndividualClipSelection($event) {
    const clipID = $event.id;
    const clip = _.find(this.displayedClips, (clip) => clip.id == clipID || clip.synergyEventID == clipID || clip.eagleChanceID == clipID || clip.nbaChanceID == clipID);
    clip.isSelected = $event.isSelected;
    this.updateSelectedClips();
    this.cdr.markForCheck();
  }

  updateSelectedClips(togglingAll = false) {
    this.selectedClips = this.displayedClips.filter(clip => clip.isSelected);
    // Trigger an update for the video-clip-table setter
    if (togglingAll) {
      this.displayedClips = _.cloneDeep(this.displayedClips);
    }
  }

  onToggleFullScreen(e) {
    this.enteredFullScreen = !this.enteredFullScreen;
    this.hideTagPanel = this.enteredFullScreen;
    if (this.enteredFullScreen) {
      this.enterFullScreenMode();
    } else {
      this.exitKioskMode();
    }
    this.cdr.markForCheck();
  }

  enterFullScreenMode() {
    this.element = document.documentElement;
    if (this.element.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.element.webkitRequestFullscreen();
    } else if (this.element.requestFullscreen) {
      this.element.requestFullscreen();
    }
  }

  exitKioskMode() {
    if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    }
  }


  onToggleShowEpv(e) {
    this.showEpv = e;
    if (this.showEpv && !this.show2dCourt) {
      this.getClipZelusData(this.currentClip);
    }
  }

  onToggleShow2dCourt(e) {
    this.show2dCourt = e;
    if (this.show2dCourt && !this.showEpv) {
      this.getClipZelusData(this.currentClip);
    }
  }

  onToggleNotesVisibility() {
    this.notesVisible = !this.notesVisible;
    this.store$.dispatch(new actions.SaveAction({'notesShownChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'notesShown': this.notesVisible}, 'chainID': this.chainID}));
  }

  downloadSelectedClips() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Download Clips',
        message: 'An email will be sent to you containing the selected clips and a corresponding XML file. This can take up to 10 minutes.',
        acceptButtonTitle: 'Ok',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        let csvString = '';
        this.selectedClips.forEach((selectedClip, i) => {
          csvString += `${selectedClip.gameID},${selectedClip.period},${selectedClip.startTimestamp || selectedClip.startGameclock},${selectedClip.startPadding},${selectedClip.endPadding}`;
          if (i < this.selectedClips.length - 1) {
            csvString += '\n';
          }
        });
        this.videoService.createCTGEmailDownloadUrl({csvString: csvString}).pipe(untilDestroyed(this)).subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
          }
        );
      }
    });
  }

  downloadCurrentClip() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Download Clip',
        message: 'An email will be sent to you containing the current clip and a corresponding XML file. This can take up to 10 minutes.',
        acceptButtonTitle: 'Ok',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        let csvString = `${this.currentClip.gameID},${this.currentClip.period},${this.currentClip.startTimestamp || this.currentClip.startGameclock},${this.currentClip.startPadding},${this.currentClip.endPadding}`;
        this.videoService.createCTGEmailDownloadUrl({csvString: csvString}).pipe(untilDestroyed(this)).subscribe(
          data => {
            console.log(data);
          },
          error => {
            console.log(error);
          }
        );
      }
    });
  }

  downloadClip(clip: any): void {
    const fileUrl = clip?.clipUrl;
    window.open(fileUrl, '_blank');
  }

  filterEditors(q: string): void {
    this.filteredEditors = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.filteredEditors = entities.filter(entity => (entity.id != this.user?.entity?.id) && (!this.selectedPlaylist?.sharedUsers ||
              this.selectedPlaylist?.sharedUsers.findIndex(sharedUser => sharedUser.isEditor && sharedUser.id == entity.id) == -1));
            this.cdr.markForCheck();
          }
        );
    }
  }

  filterViewers(q: string): void {
    this.filteredViewers = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.filteredViewers = entities.filter(entity => (entity.id != this.user?.entity?.id) && (!this.selectedPlaylist?.sharedUsers ||
              this.selectedPlaylist?.sharedUsers.findIndex(sharedUser => sharedUser.isViewer && sharedUser.id == entity.id) == -1));
            this.cdr.markForCheck();
          }
        );
    }
  }

  filterFolderEditors(q: string): void {
    this.folderFilteredEditors = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.folderFilteredEditors = entities.filter(entity => (entity.id != this.user?.entity?.id) && (!this.folderReceived?.sharedUsers ||
              this.folderReceived?.sharedUsers.findIndex(sharedUser => sharedUser.isEditor && sharedUser.id == entity.id) == -1));
            this.cdr.markForCheck();
          }
        );
    }
  }

  filterFolderViewers(q: string): void {
    this.folderFilteredViewers = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q, null, true).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.folderFilteredViewers = entities.filter(entity => (entity.id != this.user?.entity?.id) && (!this.folderReceived?.sharedUsers ||
              this.folderReceived?.sharedUsers.findIndex(sharedUser => sharedUser.isViewer && sharedUser.id == entity.id) == -1));
            this.cdr.markForCheck();
          }
        );
    }
  }

  filterEntities(q: string): void {
    this.filteredEntities = undefined;
    if (q) {
      this.autocompleteService
        .getEntities(q).pipe(take(1), untilDestroyed(this))
        .subscribe(
          entities => {
            this.filteredEntities = entities.filter(entity => (entity.id != this.user?.entity?.id) &&
              (this.currentClip.additionalTaggedEntities.findIndex(taggedEntity => taggedEntity.id == entity.id) == -1) &&
              (this.currentClip.onCourtEntities.findIndex(onCourtEntity => onCourtEntity.id == entity.id) == -1));
          }
        );
    }
  }

  convertEntityToNBAIDs(entityIDs) {
    return this.videoService.entityNBALookup.filter((mapping) => entityIDs.includes(mapping[0])).map((mapping) => mapping[1]);
  }

  convertEntityToSynergyIDs(entityIDs) {
    return this.videoService.entitySynergyLookup.filter((mapping) => entityIDs.includes(mapping[0])).map((mapping) => mapping[1]);
  }

  get isNCAA() {
    return ['NCAA', 'OTHER'].includes(this.selectedLeague);
  }

  get isCTGVideo() {
    return this.currentClip.videoSource == "CTG";
  }

  addTaggedEntity(event): void {
    let entity = event.option.value;
    this.currentClip.additionalTaggedEntities.push(entity);
    this.entitiesInput.nativeElement.value = '';
  }

  removeTaggedEntity(entity: any): void {
    this.currentClip.additionalTaggedEntities = this.currentClip.additionalTaggedEntities.filter(taggedEntity => taggedEntity.id != entity.id);
  }

  onTrimStartChange(startTrim): void {
    this.currentClip.startTrim = startTrim;
    if (this.currentVideoTime < this.currentClip.startTrim) {
      this.changeCurrentTime(this.currentClip.startTrim);
    }
  }

  onTrimEndChange(endTrim): void {
    this.currentClip.endTrim = this.currentClip.startPadding + this.currentClip.endPadding - endTrim;
    if (this.currentVideoTime > (this.currentClip.startPadding + this.currentClip.endPadding - this.currentClip.endTrim)) {
      this.changeCurrentTime(this.currentClip.startPadding + this.currentClip.endPadding - this.currentClip.endTrim);
      this.pauseVideo();
    }
  }

  toggleClipTableExpanded(): void {
    this.videoPlayer.pauseVideo();
    if (this.isVideoPlaying) {
      this.pauseVideo();
    }
    this.isTableExpanded = !this.isTableExpanded;

    this.videoClipTable.recalculateViewportSize();
    this.videoClipTable.updateScrollIndex();
  }

  saveVideo() {
    this.isSaving = !this.isSaving;
    if (this.isSaving) {
      this.currentClip.name = this.currentClip?.description;
      this.currentClip.playlists = [];
      this.currentClip.sharedUsers = [];
      this.currentClip.additionalTaggedEntities = [];
    }
    this.startTrimSeconds = 0;
    this.endTrimSeconds = this.currentClip.startPadding + this.currentClip.endPadding;
    this.trimSliderOptions.floor = 0;
    this.trimSliderOptions.ceil = this.currentClip.startPadding + this.currentClip.endPadding;
    this.videoPlayer.video.nativeElement.removeAttribute('style');
  }

  editVideo(savedVideoData) {
    this.isSaving = !this.isSaving;
    this.currentClip = Object.assign(Object.assign({}, this.currentClip), savedVideoData);
    this.currentClip.additionalTaggedEntities = [];
    this.currentClip.playersTagged.forEach(taggedPlayer => {
      let isTaggedPlayerOnCourt = false;
      this.currentClip.onCourtEntities.forEach(onCourtEntity => {
        if (taggedPlayer.id == onCourtEntity.id) {
          onCourtEntity.selected = true;
          isTaggedPlayerOnCourt = true;
        }
      })
      if (!isTaggedPlayerOnCourt) {
        this.currentClip.additionalTaggedEntities.push(taggedPlayer);
      }
    });
    this.reloadClipUrl();
    this.startTrimSeconds = this.currentClip.startTrim;
    this.endTrimSeconds = this.currentClip.startPadding + this.currentClip.endPadding - this.currentClip.endTrim;
    this.trimSliderOptions.floor = 0;
    this.trimSliderOptions.ceil = this.currentClip.startPadding + this.currentClip.endPadding;
    this.onTrimStartChange(this.startTrimSeconds);
    this.onTrimEndChange(this.endTrimSeconds);
  }

  cancelSaveVideo() {
    this.isSaving = false;
    this.videoPlayer.video.nativeElement.removeAttribute('style');
    this.videoPlayer.isSaving = false;
    this.currentClip.onCourtEntities?.forEach(onCourtEntity => {
      onCourtEntity.selected = false;
    });
    if (!this.currentClip?.id) {
      this.currentClip.startTrim = 0;
      this.currentClip.endTrim = 0;
    }
    if (this.viewersInput?.nativeElement) {
      this.viewersInput.nativeElement.value = '';
    }
    if (this.entitiesInput?.nativeElement) {
      this.entitiesInput.nativeElement.value = '';
    }
    this.changeToClip(this.currentVideoIndex, false);
  }

  confirmSaveVideo() {
    let savedVideoData = {
      id: this.currentClip.id,
      league: this.currentClip.league,
      nbaGameID: this.currentClip.nbaGameID,
      eagleGameID: this.currentClip.eagleGameID,
      sportradarGameID: this.currentClip.sportradarGameID,
      gameID: this.currentClip.gameID,
      nbaChanceID: this.currentClip.nbaChanceID,
      eagleChanceID: this.currentClip.eagleChanceID,
      synergyEventID: this.currentClip.synergyEventID,
      sportradarPlayByPlayID: this.currentClip.sportradarPlayByPlayID,
      period: this.currentClip.period,
      startGameclock: this.currentClip.startGameclock,
      startTimestamp: this.currentClip.startTimestamp,
      startPadding: this.currentClip.startPadding,
      endPadding: this.currentClip.endPadding,
      startTrim: this.currentClip.startTrim,
      endTrim: this.currentClip.endTrim,
      name: this.currentClip.name || this.currentClip.description,
      commentary: this.currentClip.commentary,
      notes: this.currentClip.notes,
      taggedPlayers: this.currentClip.onCourtEntities?.filter((onCourtEntity) => onCourtEntity.selected).concat(this.currentClip.additionalTaggedEntities).map((player) => player),
      playlists: this.currentClip.playlists,
      sharedUsers: this.currentClip.sharedUsers,
      rawUrl: this.currentClip.rawUrl,
      videoSource: this.currentClip.videoSource,
      label: this.currentClip.label,
    };
    this.currentClip.playersTagged = savedVideoData.taggedPlayers;
    this.displayedClips[this.currentVideoIndex] = this.currentClip;
    this.store$.dispatch(new actions.SaveAction({'videoSaved': savedVideoData, 'chainID': this.videoService.generateChainID()}));
    this.cancelSaveVideo();
  }

  onTagSecondsChange(tagSecondsData) {
    this.tagStartSeconds = tagSecondsData[0];
    this.tagEndSeconds = tagSecondsData[1];
  }

  saveTag() {
    this.isTagging = true;
  }

  deleteTag(tag) {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Delete Tag',
        message: 'Are you sure you want to delete this tag?',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        const deletedTagData = {
          id: tag.id
        };
        this.store$.dispatch(new actions.SaveAction({'tagDeleted': deletedTagData}));
      }
    });
  }

  editTag(tag) {
    this.showTagPanel = true;
    this.tagEditing = tag;
    this.selectedTagType = this.tagDefinitions.find((tagDefinition) => tagDefinition?.id == tag?.definition?.id);
    this.selectedTagType.metadataDefinitions.forEach((metadataDefinition) => {
      if (metadataDefinition.type == 'Person') {
        metadataDefinition.value = tag.metadata.find((metadata) => metadata?.definition?.id == metadataDefinition?.id)?.entity;
      }
      if (metadataDefinition.type == 'Selection') {
        metadataDefinition.value = tag.metadata.find((metadata) => metadata?.definition?.id == metadataDefinition?.id)?.option;
      }
    });
    const isPlayerTag = this.confirmIsPlayerTag(tag);
    this.tagPlayers = isPlayerTag;
    this.previousEntitySelected = isPlayerTag;
  }

  confirmSaveTag() {
    if (this.currentClip.startTimestamp) {
      this.selectedTagType.startTimestamp = this.currentClip.startTimestamp + (1000 * (this.tagStartSeconds - this.currentClip.startPadding));
      this.selectedTagType.endTimestamp = this.currentClip.startTimestamp + (1000 * (this.tagEndSeconds - this.currentClip.startPadding));
    }
    else {
      this.selectedTagType.startSeconds = this.tagStartSeconds;
      this.selectedTagType.endSeconds = this.tagEndSeconds;
    }
    if (!this.tagPlayers) {
      this.selectedTagType.metadataDefinitions.forEach(metadataDefinition => {
        if (metadataDefinition.type === 'Person') {
          metadataDefinition.value = null;
        }
      });
    }
    const savedTagData = {
      id: this.tagEditing?.id,
      tagData: this.selectedTagType,
      videoData: this.currentClip,
    };
    this.store$.dispatch(new actions.SaveAction({'tagSaved': savedTagData}));
    this.selectedTagType.metadataDefinitions.forEach(metadataDefinition => {
      if (metadataDefinition.type === 'Person' || metadataDefinition.type === 'Selection') {
        metadataDefinition.value = null;
      }
    });
    if (!this.keepTagPanelOpen) {
      this.cancelSaveTag();
    }
    this.snackBar.open('Tag Added', 'Close', {
      duration: 1000,
    });
    const tag = this.currentClip.tags.find(tag => tag.id === savedTagData.id);
    this.editTag(tag);
    this.previousEntitySelected = false;
    this.cdr.markForCheck();
  }

  onTagPlayersCheckboxChange(event: any) {
    if (event.checked) {
      this.selectedTagType.metadataDefinitions.forEach(metadataDefinition => {
        if (metadataDefinition.type === 'Selection') {
          metadataDefinition.value = null;
        }
      });
      const tag = this.currentClip.tags.find(tag => tag.definition?.name === this.selectedTagType?.name && this.confirmIsPlayerTag(tag) && tag.author?.userID === this.user.id);
      if (tag) {
        this.editTag(tag)
      } else {
        this.tagEditing = false;
      }
    } else {
      this.selectedTagType.metadataDefinitions.forEach(metadataDefinition => {
        if (metadataDefinition.type === 'Person' || metadataDefinition.type === 'Selection') {
          metadataDefinition.value = null;
        }
      });
      const tag = this.currentClip.tags.find(tag => tag.definition?.name === this.selectedTagType?.name && !this.confirmIsPlayerTag(tag) && tag.author?.userID === this.user.id);
      if (tag) {
        this.editTag(tag)
      } else {
        this.tagEditing = false;
      }
    }
  }

  onTagDefinitionChange() {
    this.selectedTagType.metadataDefinitions.forEach(metadataDefinition => {
      if (metadataDefinition.type === 'Person' || metadataDefinition.type === 'Selection') {
        metadataDefinition.value = null;
      }
    });
    if (this.isNoPlayerTagDefinition(this.selectedTagType) && this.tagPlayers) {
      this.tagPlayers = false;
    }
    const tag = this.currentClip.tags.find(tag => tag.definition?.name === this.selectedTagType?.name && tag.author?.userID === this.user.id);
    if (tag) {
      this.editTag(tag);
    } else {
      this.tagEditing = false;
      this.previousEntitySelected = false;
    }
  }

  isNoPlayerTagDefinition(selectedTagType: any): boolean {
    if (!selectedTagType) return false;
    const hasPersonType = selectedTagType.metadataDefinitions.some(metadataDefinition => {
      if (metadataDefinition.type === 'Person') {
        return true;
      }
      return false;
    });
    return !hasPersonType;
  }

  isPlayerOnlyTagDefinition(selectedTagType: any): boolean {
    if (!selectedTagType) return false;
    const hasSelectionType = selectedTagType.metadataDefinitions.some(metadataDefinition => {
      if (metadataDefinition.type === 'Selection') {
        return true;
      }
      return false;
    });
    return !hasSelectionType;
  }

  toggleEntitySelection(metadataDefinition: any, onCourtEntity: any): void {
    if (metadataDefinition.value?.id === onCourtEntity?.id) {
      metadataDefinition.value = null;
      this.previousEntitySelected = false;
    } else {
      metadataDefinition.value = onCourtEntity;
      this.selectedTagType.metadataDefinitions.forEach(metadataDefinition => {
        if (metadataDefinition.type === 'Selection') {
          metadataDefinition.value = null;
        }
        this.tagEditing = false;
      });
      this.previousEntitySelected = true;
    }
    const entityTag = this.findPlayerTag(metadataDefinition.value, this.selectedTagType?.name);
    if (entityTag) {
      this.editTag(entityTag);
    }
  }

  findPlayerTag(player: any, tagName): string {
    if (!this.currentClip?.tags?.length) {
      return null;
    }
    return this.currentClip.tags.find((tag) =>
      tag.author?.userID === this.user.id &&
      tag.definition.name === tagName &&
      tag.metadata?.some((meta) => meta.entity?.id === player.id)
    );
  }

  confirmIsPlayerTag(tag): boolean {
    return tag.author?.userID === this.user.id && tag.metadata?.some((meta) => meta.entity?.id != null);
  }

  hasPersonMetadata(selectedTagType): boolean {
    return selectedTagType?.metadataDefinitions.some(md => md.type === 'Person');
  }

  cancelSaveTag() {
    this.selectedTagType.metadataDefinitions.forEach(metadataDefinition => {
      if (metadataDefinition.type === 'Person' || metadataDefinition.type === 'Selection') {
        metadataDefinition.value = null;
      }
    });
    this.keepTagPanelOpen = false;
    this.isTagging = false;
    this.tagEditing = null;
  }

  removeVideoFromPlaylist() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Remove Video from Playlist',
        message: 'Are you sure you want to remove this video from this playlist?',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        let bulkRemoveData = {
          videos: [this.currentClip],
          playlist: this.selectedPlaylist,
        };
        this.store$.dispatch(new actions.SaveAction({'videosBulkRemoved': bulkRemoveData}));
        this.selectedPlaylist.clips = this.selectedPlaylist.clips.filter(clip => this.currentClip.id != clip.id);
        this.clips = this.selectedPlaylist.clips;
        this.updateDisplayedClips();
      }
    });
  }

  deleteVideo() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Remove Video from All Playlists',
        message: 'Are you sure you want to remove this video from all playlists? It will no longer be accessible.',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        this.store$.dispatch(new actions.SaveAction({'videoDeleted': this.currentClip, 'chainID': this.videoService.generateChainID()}));
      }
    });
  }

  createPlaylist() {
    const dialogConfig: MatDialogConfig = {
      data: {name: ''}
    };
    const dialogRef = this.dialog.open(SavedPlaylistDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(newName => {
      if (newName) {
        let newPlaylistData = {name: newName, sharedUsers: []};
        this.chainID = this.videoService.generateChainID();
        this.store$.dispatch(new actions.SaveAction({'playlistSaved': newPlaylistData, 'chainID': this.chainID}));
      }
    });
  }

  createFolder() {
    const dialogConfig: MatDialogConfig = {
      data: {name: ''},
    };
    const dialogRef = this.dialog.open(SavedFolderDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe((newName) => {
      if (newName) {
        let newFolderData = {name: newName, sharedUsers: []};
        this.chainID = this.videoService.generateChainID();
        this.store$.dispatch(new actions.SaveAction({'folderSaved': newFolderData, 'chainID': this.chainID}));
      }
    });
  }

  updateFolders(folders) {
    this.userFolders = folders;
    this.filteredFolders = _.cloneDeep(folders);
    this.cdr.markForCheck();
  }

  addPlaylistToFolder() {
    this.isAddingToFolder = true;
    this.destinationFolders = [];
  }

  cancelAddPlaylistToFolder() {
    this.isAddingToFolder = false;
    this.destinationFolders = [];
  }

  confirmAddPlaylistToFolder() {
    let bulkSaveData = {
      playlists: [_.cloneDeep(this.selectedPlaylist)],
      destinationFolders: this.destinationFolders,
    };
    this.store$.dispatch(new actions.SaveAction({'playlistSavedToFolder': bulkSaveData, 'chainID': this.videoService.generateChainID()}));
    this.addVideosToPlaylists(bulkSaveData.playlists, bulkSaveData.destinationFolders);
    this.cancelAddPlaylistToFolder();
  }

  removePlaylistsFromFolders() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Remove Playlists from Folder',
        message: 'Are you sure you want to remove all selected playlists from this Folder?',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        let bulkRemoveData = {
          playlists: [_.cloneDeep(this.selectedPlaylist)],
          folder: this.selectedFolder,
        };
        this.store$.dispatch(new actions.SaveAction({'playlistsRemovedFromFolder': bulkRemoveData}));
        this.selectedFolder.playlists = this.selectedFolder.playlists.filter(playlist => !this.selectedPlaylists.map(selectedPlaylist => selectedPlaylist.id).includes(playlist.id));
      }
    });
  }

  updateDestinationFolders(folder, isChecked) {
    if (isChecked) {
      this.destinationFolders.push(folder);
    }
    else {
      this.destinationFolders = this.destinationFolders.filter(selectedFolder => selectedFolder.id != folder.id);
    }
  }

  isDestinationFolder(folder) {
    return this.destinationFolders.findIndex(destinationFolders => destinationFolders.id == folder.id) != -1;
  }

  canEditFolder(folder) {
    return folder?.id && folder.id != -1 && (folder.author.userID == this.user.id || (folder.editability != VideoSharingTypes.PRIVATE && folder.sharedUsers.findIndex(sharedUser => sharedUser.userID == this.user.id && sharedUser.isEditor) != -1));
  }

  canEditPlaylist(playlist) {
    return playlist.id && playlist.id != -1 && (playlist.author.userID == this.user.id || (playlist.editability != VideoSharingTypes.PRIVATE && playlist.sharedUsers.findIndex(sharedUser => sharedUser.userID == this.user.id && sharedUser.isEditor) != -1));
  }

  editPlaylist() {
    if (this.canEditPlaylist(this.selectedPlaylist)) {
      this.selectedPlaylist = Object.assign({}, this.selectedPlaylist);
      this.isEditingPlaylist = true;
    }
  }

  cancelEditPlaylist() {
    this.selectedPlaylist = this.userPlaylists.find(userPlaylist => userPlaylist.id == this.selectedPlaylist.id);
    this.isEditingPlaylist = false;
  }

  editFolder() {
    if (this.canEditFolder(this.folderReceived)) {
      this.folderReceived = Object.assign({}, this.folderReceived);
      this.isEditingFolder = true;
      this.cdr.detectChanges();
    }
  }

  cancelEditFolder() {
    this.folderReceived = null;
    this.isEditingFolder = false;
  }

  togglePlaylistEditability(editability) {
    this.selectedPlaylist.editability = editability;
    if (this.selectedPlaylist.editability == VideoSharingTypes.LIMITED && this.selectedPlaylist.visibility == VideoSharingTypes.PRIVATE) {
      this.selectedPlaylist.visibility = VideoSharingTypes.LIMITED;
    }
    if (this.selectedPlaylist.editability == VideoSharingTypes.PUBLIC && this.selectedPlaylist.visibility != VideoSharingTypes.PUBLIC) {
      this.selectedPlaylist.visibility = VideoSharingTypes.PUBLIC;
    }
  }

  togglePlaylistVisibility(visibility) {
    this.selectedPlaylist.visibility = visibility;
  }

  toggleFolderEditability(editability) {
    this.folderReceived.editability = editability;
    if (this.folderReceived.editability == VideoSharingTypes.LIMITED && this.folderReceived.visibility == VideoSharingTypes.PRIVATE) {
      this.folderReceived.visibility = VideoSharingTypes.LIMITED;
    }
    if (this.folderReceived.editability == VideoSharingTypes.PUBLIC && this.folderReceived.visibility != VideoSharingTypes.PUBLIC) {
      this.folderReceived.visibility = VideoSharingTypes.PUBLIC;
    }
  }

  toggleFolderVisibility(visibility) {
    this.folderReceived.visibility = visibility;
  }

  addUserEntity(entity, isEditor, isViewer): void {
    let existingUser = this.selectedPlaylist.sharedUsers.find(user => user.id == entity.id);
    if (existingUser) {
      existingUser.isEditor = (isEditor == null) ? existingUser.isEditor : isEditor;
      existingUser.isViewer = (isViewer == null) ? existingUser.isViewer : isViewer;
    }
    else {
      entity.isEditor = Boolean(isEditor);
      entity.isViewer = Boolean(isViewer);
      this.selectedPlaylist.sharedUsers.push(entity);
    }
    if (isEditor) {
      this.editorsInput.nativeElement.value = '';
    }
    else if (isViewer) {
      this.viewersInput.nativeElement.value = '';
    }
  }

  removeUserEntity(entity: any, role): void {
    entity[role] = false;
    if (!entity.isEditor && !entity.isViewer) {
      this.selectedPlaylist.sharedUsers = this.selectedPlaylist.sharedUsers.filter(user => user.id != entity.id);
    }
  }

  addFolderUserEntity(entity, isEditor, isViewer): void {
    let existingUser = this.folderReceived.sharedUsers.find(user => user.id == entity.id);
    if (existingUser) {
      existingUser.isEditor = (isEditor == null) ? existingUser.isEditor : isEditor;
      existingUser.isViewer = (isViewer == null) ? existingUser.isViewer : isViewer;
    } else {
      entity.isEditor = Boolean(isEditor);
      entity.isViewer = Boolean(isViewer);
      this.folderReceived.sharedUsers.push(entity);
    }
    if (isEditor) {
      this.editorsInput.nativeElement.value = '';
    }
    else if (isViewer) {
      this.viewersInput.nativeElement.value = '';
    }
  }

  removeFolderUserEntity(entity: any, role): void {
    entity[role] = false;
    if (!entity.isEditor && !entity.isViewer) {
      this.folderReceived.sharedUsers = this.folderReceived.sharedUsers.filter(user => user.id != entity.id);
    }
  }

  displayUserName(user: any): string {
    return user ? user.name : '';
  }

  confirmSavePlaylist() {
    if (this.selectedPlaylist.editability == VideoSharingTypes.PRIVATE || this.selectedPlaylist.editability == VideoSharingTypes.PUBLIC) {
      this.selectedPlaylist.sharedUsers.forEach(sharedUser => {
        this.removeUserEntity(sharedUser, 'isEditor');
      });
    }
    if (this.selectedPlaylist.visibility == VideoSharingTypes.PRIVATE || this.selectedPlaylist.visibility == VideoSharingTypes.PUBLIC) {
      this.selectedPlaylist.sharedUsers.forEach(sharedUser => {
        this.removeUserEntity(sharedUser, 'isViewer');
      });
    }
    else {
      this.selectedPlaylist.sharedUsers.forEach(sharedUser => {
        this.addUserEntity(sharedUser, null, true);
      });
    }
    let savedPlaylistData = {
      id: this.selectedPlaylist.id,
      name: this.selectedPlaylist.name,
      description: this.selectedPlaylist.description,
      isViewPublic: this.selectedPlaylist.visibility == this.VideoSharingTypes.PUBLIC,
      isEditPublic: this.selectedPlaylist.editability == this.VideoSharingTypes.PUBLIC,
      sharedUsers: this.selectedPlaylist.sharedUsers,
    };
    this.store$.dispatch(new actions.SaveAction({'playlistSaved': savedPlaylistData, 'chainID': this.videoService.generateChainID()}));
    this.userPlaylists[this.userPlaylists.findIndex(userPlaylist => userPlaylist.id == this.selectedPlaylist.id)] = this.selectedPlaylist;
    this.cancelEditPlaylist();
  }

  deletePlaylist() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Delete Playlist',
        message: 'Are you sure you want to delete this playlist?',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        this.store$.dispatch(new actions.SaveAction({'playlistDeleted': this.selectedPlaylist, 'chainID': this.videoService.generateChainID()}));
      }
    });
  }

  confirmSaveFolder() {
    if (this.folderReceived.editability == VideoSharingTypes.PRIVATE || this.folderReceived.editability == VideoSharingTypes.PUBLIC) {
      this.folderReceived.sharedUsers.forEach(sharedUser => {
        this.removeFolderUserEntity(sharedUser, 'isEditor');
      });
    }
    if (this.folderReceived.visibility == VideoSharingTypes.PRIVATE || this.folderReceived.visibility == VideoSharingTypes.PUBLIC) {
      this.folderReceived.sharedUsers.forEach(sharedUser => {
        this.removeFolderUserEntity(sharedUser, 'isViewer');
      });
    }
    else {
      this.folderReceived.sharedUsers.forEach(sharedUser => {
        this.addFolderUserEntity(sharedUser, null, true);
      });
    }
    let savedFolderData = {
      id: this.folderReceived.id,
      name: this.folderReceived.name,
      isViewPublic: this.folderReceived.visibility == this.VideoSharingTypes.PUBLIC,
      isEditPublic: this.folderReceived.editability == this.VideoSharingTypes.PUBLIC,
      sharedUsers: this.folderReceived.sharedUsers,
    };
    this.store$.dispatch(new actions.SaveAction({'folderSaved': savedFolderData, 'chainID': this.videoService.generateChainID()}));
    this.cancelEditFolder();
  }

  bulkSavePlaylistVideosToPlaylists() {
    this.selectedClips = this.displayedClips;
    this.bulkSaveVideosToPlaylists();
  }

  bulkSaveVideosToPlaylists() {
    this.isSavingMany = true;
    this.bulkSaveDestinationPlaylists = this.selectedClips[0].playlists ? this.selectedClips[0].playlists.filter(playlist => this.canEditPlaylist(playlist)) : [];
    this.selectedClips.forEach(selectedClip => {
      selectedClip.name = selectedClip.name ? selectedClip.name : selectedClip.description;
      this.bulkSaveDestinationPlaylists = this.bulkSaveDestinationPlaylists.filter(destinationPlaylist => selectedClip.playlists.findIndex(playlist => playlist.id == destinationPlaylist.id) != -1);
    });
  }

  cancelBulkSaveVideosToPlaylists() {
    this.isSavingMany = false;
    this.bulkSaveDestinationPlaylists = [];
    this.updateSelectedClips(true);
  }

  confirmBulkSaveVideosToPlaylists() {
    let bulkSaveData = {
      videos: this.selectedClips,
      destinationPlaylists: this.bulkSaveDestinationPlaylists,
    };
    this.store$.dispatch(new actions.SaveAction({'videosBulkSaved': bulkSaveData, 'chainID': this.videoService.generateChainID()}));
    this.addVideosToPlaylists(bulkSaveData.videos, bulkSaveData.destinationPlaylists);
    this.deselectAllClips();
    this.cancelBulkSaveVideosToPlaylists();
  }

  updateBulkSaveDestinationPlaylists(playlist, isChecked) {
    if (isChecked) {
      this.bulkSaveDestinationPlaylists.push(playlist);
    }
    else {
      this.bulkSaveDestinationPlaylists = this.bulkSaveDestinationPlaylists.filter(selectedPlaylist => selectedPlaylist.id != playlist.id);
    }
  }

  isBulkSaveDestinationPlaylist(playlist) {
    return this.bulkSaveDestinationPlaylists.findIndex(bulkSaveDestinationPlaylist => bulkSaveDestinationPlaylist.id == playlist.id) != -1;
  }

  addVideosToPlaylists(videos, destinationPlaylists) {
    destinationPlaylists.forEach(destinationPlaylist => {
      let playlist = this.userPlaylists.find(playlist => playlist.id == destinationPlaylist.id);
      if (playlist) {
        videos.forEach(video => {
          if (video.id && playlist.clips && playlist.clips.findIndex(playlistClip => playlistClip.id == video.id) == -1) {
            if (video.playlists.findIndex(videoPlaylist => videoPlaylist.id == destinationPlaylist.id) == -1) {
              video.playlists.push(destinationPlaylist);
            }
            playlist.clips.push(video);
          }
        })
      }
    })
  }

  bulkRemoveVideosFromPlaylist() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Remove Videos from Playlist',
        message: 'Are you sure you want to remove all selected videos from this playlist?',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        let bulkRemoveData = {
          videos: this.selectedClips,
          playlist: this.selectedPlaylist,
        };
        this.store$.dispatch(new actions.SaveAction({'videosBulkRemoved': bulkRemoveData}));
        this.selectedPlaylist.clips = this.selectedPlaylist.clips.filter(clip => !this.selectedClips.map(selectedClip => selectedClip.id).includes(clip.id));
        this.clips = this.selectedPlaylist.clips;
        this.updateDisplayedClips();
        this.clearVideoSelections();
      }
    });
  }

  bulkDeleteVideos() {
    const confirmDialogConfig: MatDialogConfig = {
      data: {
        title: 'Remove Videos from All Playlists',
        message: 'Are you sure you want to remove these videos from all playlists? They will no longer be accessible.',
        acceptButtonTitle: 'Yes',
        cancelButtonTitle: 'Cancel'
      }
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    dialogRef.afterClosed().pipe(untilDestroyed(this), take(1)).subscribe(result => {
      if (result) {
        let bulkDeleteData = {
          videos: this.selectedClips,
        };
        this.store$.dispatch(new actions.SaveAction({'videosBulkDeleted': bulkDeleteData}));
        this.userPlaylists.forEach(playlist => {
          this.selectedClips.forEach(savedVideo => {
            if (playlist.clips) {
              if (playlist.clips.find(clip => clip.id == savedVideo.id)) {
                playlist.clips = playlist.clips.filter(clip => clip.id != savedVideo.id);
              }
            }
          });
        });
        this.selectedPlaylist.clips = this.selectedPlaylist.clips.filter(clip => !this.selectedClips.map(selectedClip => selectedClip.id).includes(clip.id));
        this.clips = this.selectedPlaylist.clips;
        this.updateDisplayedClips();
        this.clearVideoSelections();
      }
    });
  }

  updateVideoNotes(video) {
    this.store$.dispatch(new actions.SaveAction({'videoNotesSaved': video}));
  }

  clearVideoSelections() {
    this.displayedClips.forEach(clip => {
      clip.isSelected = false;
    });
    this.selectedClips = [];
  }

  compareIDs(a, b) {
    return a && b && a.id === b.id;
  }

  compareGameIDs(a, b) {
    return a && b && a.gameID === b.gameID;
  }

  getCurrentNumberOfPlayersSelected() {
    return (this.currentClip?.onCourtEntities?.filter(onCourtEntity => onCourtEntity.selected).length || 0);
  }

  copyLink() {
    let url = window.location.href.split('?')[0];
    url += '?videoPanelOpen=true';
    if (this.selectedPlaylist?.id) {
      url += `&playlistID=${this.selectedPlaylist.id}`;
    } else if (this.selectedGeneratedPlaylist?.id) {
      url += `&generatedPlaylistFilters=${encodeURIComponent(JSON.stringify(this.filtersData))}`;
    }
    this.clipboard.copy(url);
    this.snackBar.open('Playlist link copied', 'Close', {
      duration: 5000,
    });
  }

  copyVideoLink(currentClip: any) {
    let url = currentClip.clipUrl;
    this.clipboard.copy(url);
    this.snackBar.open('Video link copied', 'Close', {
      duration: 5000,
    });
  }

  copyChance(attribute) {
    let chanceID = this.isSynergyClip ? this.currentClip[attribute.synergyKey] : this.currentClip[attribute.nbaKey];
    chanceID = `\'${chanceID}\'`;
    this.clipboard.copy(chanceID);
    this.snackBar.open('Chance ID copied', 'Close', {
      duration: 5000,
    });
  }

  modifyGeneratedPlaylistFilters() {
    this.videoPlayer.pauseVideo();
    if (this.isVideoPlaying) {
      this.pauseVideo();
    }
    this.isGeneratingPlaylist = true;
    this.displayedClipsLoaded = false;
    this.videoService.selectedTabSubject.next(VideoTabTypes.GENERATE_PLAYLIST);
  }

  removeInvalidFilters() {
    const originalLength = this.filtersData.filters.length;
    this.filtersData.filters = _.filter(this.filtersData.filters, (filter) => {
      return filter.args?.length != 0;
    });
    if (originalLength != this.filtersData.filters.length) {
      const leagueIdentifier = ['NBA', 'GLG'].includes(this.selectedLeague) ? 'NBA' : 'Synergy';
      const message = `An invalid filter was removed. Please ensure that all players have a valid ${leagueIdentifier} id.`;
      this.snackBar.open(message, 'Close', {
        duration: 5000,
      });
    }
  }

  updateGeneratedPlaylist(filtersObj) {
    this.deselectAllClips();
    this.isLoading = true;
    this.clickPlaylist(null, false);
    this.displayedClipsLoaded = false;
    this.isGeneratingPlaylist = false;
    if (filtersObj?.id) {
      this.currentClip = null;
      const gp = _.find(this.userGeneratedPlaylists, ['id', filtersObj.id]);
      this.clickGeneratedPlaylist(gp);
      this.videoService.selectedTabSubject.next(VideoTabTypes.BROWSE_VIDEO);
      if (this.sessionMetadata.currentExploreType !== VideoExploreTypes.GENERATED_PLAYLIST) {
        this.store$.dispatch(new actions.SaveAction({'exploreTypeChange': VideoExploreTypes.GENERATED_PLAYLIST, 'chainID': this.videoService.generateChainID()}));
      }
      this.store$.dispatch(new actions.SaveAction({'exploreGeneratedPlaylistChange': {id: gp.id, 'chainID': this.videoService.generateChainID()}}));
      if (this.sessionMetadata.currentNavigation != VideoNavigationTypes.EXPLORE) {
        this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE, 'chainID': this.videoService.generateChainID()}));
      }
    } else if (filtersObj) {
      this.currentClip = null;
      this.filtersData = filtersObj['filtersData'];
      this.removeInvalidFilters();
      this.displayedFilters = {...this.displayedFilters, ...filtersObj['displayedFilters']};
      this.chainID = this.videoService.generateChainID();
      this.videoService.selectedTabSubject.next(VideoTabTypes.BROWSE_VIDEO);
      this.awaitingGeneratedPlaylistCreation = true;
      if (this.selectedGeneratedPlaylist?.clips?.length) {
        this.selectedGeneratedPlaylist.clips = null;
      }
      this.store$.dispatch(new actions.SaveAction({'navigationChange': VideoNavigationTypes.EXPLORE, 'chainID': this.videoService.generateChainID()}));
      this.store$.dispatch(new VideoStoreActions.SaveAction({'generatedPlaylistCreated': this.filtersData, 'chainID': this.chainID}));
    } else {
      this.isLoading = false;
      this.displayedClipsLoaded = true;
      this.videoService.selectedTabSubject.next(VideoTabTypes.BROWSE_VIDEO);
      this.playVideo();
    }
  }

  closeDialog() {
    this.isClosing = true;
    this.updatePlaylistQueryParam(null);
    this.onCloseDialog.emit();
  }

  blurPlaylistHokeyMenu($event, index, ignoreClickLocation = false) {
    if (($event.relatedTarget?.classList?.contains('playlist-options-list') || $event.relatedTarget?.classList?.contains('playlist-search') ||
      $event.relatedTarget?.classList?.contains('author-menu') && !ignoreClickLocation)
    ) {
      return;
    }
    setTimeout(() => {
      this['playlistMenu' + index + 'Open'] = false;
      this.playlistSearchValue = '';
      this.filteredPlaylists = _.cloneDeep(this.userPlaylists);
      this.cdr.markForCheck();
    }, 200);
  }

  switchPlaylistHotkey(index, playlist) {
    this['selectedPlaylist' + index] = playlist;
    this.sessionMetadata[`playlist${index}ID`] = playlist.id;
    this.store$.dispatch(new actions.SaveAction({[`playlist${index}Change`]: {'id': playlist.id}}));
    this.blurPlaylistHokeyMenu({}, index - 1, true);
    this.cdr.markForCheck();
  }

  switchToExplore() {
    this.sessionMetadata.currentNavigation = VideoNavigationTypes.EXPLORE;
    this.store$.dispatch(new actions.SaveAction({'navigationChange': this.sessionMetadata.currentNavigation}));
    this.setupSelectedPlaylists();
  }

  quickSaveVideoToPlaylist(playlistIndex) {
    const playlist = this['selectedPlaylist' + playlistIndex];
    if (!playlist) {
      return;
    }
    if (this.currentClip.playlists) {
      this.currentClip.playlists.push(playlist);
      this.confirmSaveVideo();
      this.snackBar.open('Video successfully added to the selected playlist', 'Close', {
        duration: 3000,
      });
    } else {
      this.currentClip.playlists = [playlist];
      this.confirmSaveVideo();
      this.snackBar.open('Video successfully added to the selected playlist', 'Close', {
        duration: 3000,
      });
    }
  }

  getSelectedPlaylistEditors() {
    return this.selectedPlaylist?.sharedUsers?.filter(user => user.isEditor);
  }

  getSelectedPlaylistViewers() {
    return this.selectedPlaylist?.sharedUsers?.filter(user => !user.isEditor && user.isViewer);
  }

  getSelectedPlaylistAdditionalEditors() {
    if ((this.getSelectedPlaylistEditors().length + this.getSelectedPlaylistViewers().length) > 6 && this.getSelectedPlaylistEditors().length > 4) {
      return this.getSelectedPlaylistEditors().length - 3
    }
    return 0;
  }

  getSelectedPlaylistAdditionalViewers() {
    if ((this.getSelectedPlaylistEditors().length + this.getSelectedPlaylistViewers().length) > 6 && this.getSelectedPlaylistViewers().length > 1) {
      return this.getSelectedPlaylistViewers().length - (this.getSelectedPlaylistEditors().length > 3 ? 1 : (5 - this.getSelectedPlaylistEditors().length));
    }
    return 0;
  }

  getSelectedDistributionTooltip() {
    let tooltip = '';
    let selectedPlaylistEditors = this.getSelectedPlaylistEditors();
    let selectedPlaylistViewers = this.getSelectedPlaylistViewers();
    if (selectedPlaylistEditors.length > 0) {
      tooltip += 'Editors: \n';
      selectedPlaylistEditors.forEach(user => {
        tooltip += `${user.name} \n`;
      });
      if (selectedPlaylistViewers.length > 0) {
        tooltip += '\n';
      }
    }
    if (selectedPlaylistViewers.length > 0) {
      tooltip += 'Viewers: \n';
      selectedPlaylistViewers.forEach(user => {
        tooltip += `${user.name} \n`;
      });
    }
    return tooltip;
  }

  isClipDownloadable(clip) {
    return clip.clipUrl;
  }

  areSelectedClipsDownloadable() {
    return this.selectedClips.length == 1 ? true : !this.selectedClips.map(selectedClip => this.isClipDownloadable(selectedClip) && selectedClip.videoSource == "CTG").includes(false);
  }

  areSelectedClipsDeletable() {
    return !this.selectedClips.map(selectedClip => selectedClip.author?.userID == this.user.id).includes(false);
  }

  getPlaycallName(generatedPlaylist) {
    if (generatedPlaylist?.filtersData?.filters?.length) {
      const playcallFilter = _.find(generatedPlaylist.filtersData.filters, ['name', 'playcall_name']);
      if (playcallFilter?.args?.length > 0) {
        return playcallFilter.args[0];
      }
    }
  }

  getActionGPPrefix(generatedPlaylist) {
    if (generatedPlaylist?.filtersData?.filters?.length) {
      const actionSplitByValueDescriptionsFilter = _.find(generatedPlaylist.filtersData.filters, ['name', 'action_split_by_values_description']);
      if (actionSplitByValueDescriptionsFilter?.args?.length > 0) {
        return actionSplitByValueDescriptionsFilter.args[0];
      }
    }
  }

  determineGPHeader(generatedPlaylist) {
    const playcallName = this.getPlaycallName(generatedPlaylist);
    const actionGPPrefix = this.getActionGPPrefix(generatedPlaylist);
    const header = `${videoSubjectToLabel(generatedPlaylist.filtersData.subject)}`;
    if (playcallName) {
      return `${playcallName} ${header}`;
    } else if (actionGPPrefix) {
      if (header === 'Minutes') {
        return actionGPPrefix;
      }
      return `${actionGPPrefix} ${header}`;
    } else if (generatedPlaylist.filters?.primaryEntity?.length) {
      return `${generatedPlaylist.filters.primaryEntity[0].name} ${header}`;
    } else if (generatedPlaylist.filters?.primaryEntity?.name) {
      return `${generatedPlaylist.filters.primaryEntity.name} ${header}`;
    } else {
      return header;
    }
  }

  getSelectedPlaylistName() {
    return this.folderPath ? `${this.folderPath} / ${this.selectedPlaylist.name}` : this.selectedPlaylist.name;
  }

  shuffle() {
    this.displayedClips = this.videoHelper.shuffle(this.displayedClips);
    this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}SortColumn`] = false;
    this.sessionMetadata[`${this.sessionMetadata['currentNavigation'].toLowerCase().replace('_', '')}SortDirection`] = false;
  }

  filterData(event) {
    const values = event.values;
    const key = event.key;
    let deleteKey = false;
    // We don't want the filter background color when the column doesn't have an active filter
    if ((_.isEmpty(values) && this.activeFilters.hasOwnProperty(key)) || values.clearValue) {
      delete this.activeFilters[key];
      deleteKey = true;
    } else if (!_.isEmpty(values) && !values.clearValue) {
      this.activeFilters[key] = values;
    }
    delete values.clearValue;

    this.filterProperties[key] = values;
    this.updateDisplayedClips();
    if (deleteKey) {
      delete this.filterProperties[key];
    }
  }

  toggleShuffle() {
    let isShuffled = false;
    if (this.selectedPlaylist) {
      this.selectedPlaylist.isShuffled = !this.selectedPlaylist.isShuffled;
      isShuffled = this.selectedPlaylist.isShuffled;
    } else {
      this.selectedGeneratedPlaylist.isShuffled = !this.selectedGeneratedPlaylist.isShuffled;
      isShuffled = this.selectedGeneratedPlaylist.isShuffled;
    }
    this.sortBy = null;
    this.sortDirection = null;
    if (this.videoClipTable) {
      this.videoClipTable.sortBy = null;
      this.videoClipTable.sortDirection = null;
    }
    this.store$.dispatch(new actions.SaveAction({'isShuffledChange': {'currentNavigation': this.sessionMetadata.currentNavigation, 'isShuffled': isShuffled}, 'chainID': this.chainID}));
    this.updateDisplayedClips();
  }

  isMoment(date) {
    return moment.isMoment(date);
  }

  roundNumber(num) {
    return Math.round(num);
  }

  getFilteredAttributes(currentClip: any, attributes: any[]): any[] {
    const hasAssister = attributes.some(attribute =>
      (!this.isSynergyClip && attribute.label === 'Assister' && currentClip[attribute.nbaKey]) ||
      (this.isSynergyClip && attribute.label === 'Assister' && currentClip[attribute.synergyKey])
    );

    return attributes.filter(attribute =>
      attribute.label !== 'Potential Assister' ||
      (attribute.label === 'Potential Assister' && !hasAssister)
    );
  }

  reorderClips(newClipMap: { [id: number]: number }) {
    const currentPositions = this.displayedClips.reduce((map: { [id: number]: number }, clip, index) => {
      map[clip.id] = index;
      return map;
    }, {});
    const allClipsWithNewPositions = this.displayedClips.map(clip => {
      const newPosition = newClipMap[clip.id];
      return {
        clip,
        position: newPosition !== undefined ? newPosition - 1 : currentPositions[clip.id],
      };
    });
    this.displayedClips = _.map(_.sortBy(allClipsWithNewPositions, 'position'), 'clip');
    const index = this.displayedClips.findIndex(clip => clip.id === this.currentVideoID);
    this.currentVideoIndex = index !== -1 ? index : 0;
    this.changeToClip(this.currentVideoIndex, false);
    this.capturedClipOrder = this.displayedClips;
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.videoService.selectedPlaylistSubject.next(null);
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}
